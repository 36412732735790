import { IServiceList } from '@rof/Api/cpServices/types';
import { IState } from '@rof/Reducers/types';
import { getUserServiceList } from '@rof/Thunks/getUserServices';
import IStore from '@Store/types';

import { useDispatch, useSelector } from 'react-redux';

const useGetUserServices = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector<IStore, IState<IServiceList>>((state) => state.serviceList);

  const init = (force = false) => {
    if (((isLoading === false || isLoading === undefined) && data === undefined) || force) {
      dispatch(getUserServiceList());
    }
  };

  return {
    init,
    data,
    isLoading,
    error,
  };
};

export default useGetUserServices;
