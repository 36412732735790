import ActionsGetFinancialDocumentDetail from '@rof/Actions/getFinancialDocumentDetail';
import { FinancialDocumentDetailResponse } from '@rof/Api/bff/financialDocuments/types';

import { IState, IStateArrayWrapper } from '../types';

const init: IState<FinancialDocumentDetailResponse> = {
  data: undefined,
  isLoading: false,
  error: undefined,
  lastFetched: undefined,
};

const getDashboardProductDetailReducer = (
  state: IStateArrayWrapper<FinancialDocumentDetailResponse> = {},
  action: ActionsGetFinancialDocumentDetail
) => {
  switch (action.type) {
    case '[BFF - getFinancialDocumentDetail] START':
      return {
        ...state,
        [action.documentId]: {
          ...init,
          isLoading: true,
        },
      };
    case '[BFF - getFinancialDocumentDetail] SUCCESS':
      return {
        ...state,
        [action.documentId]: {
          isLoading: false,
          data: action.data,
          lastFetched: Date.now(),
        },
      };
    case '[BFF - getFinancialDocumentDetail] ERROR':
      return {
        ...state,
        [action.documentId]: {
          isLoading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default getDashboardProductDetailReducer;
