import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { StrictMode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { initLangTexts } from '@rof/Utils/translations';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { initializeStore, getPersistor } from './store';

const store = initializeStore();

const renderInitError = (err: object | string): void => {
  render(
    <p>
      Při inicializaci došlo k vnitřní chybě aplikace:
      <br />
      {err.toString()}
    </p>,
    document.getElementById('page-root'),
  );
};

const renderApp = (): void => {
  render(
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={getPersistor()}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </PersistGate>
      </Provider>
    </StrictMode>,
    document.getElementById('root'),
  );
};

initLangTexts()
  .then(() => {
    renderApp();
  })
  .catch((err: object | string) => {
    console.error('Application failed to initialize: ', err);
    renderInitError(err);
  });
