/* eslint-disable max-len */
export default {
  tvsc: {
    cookieAcceptance: {
      descriptionHeader: 'Aby pro vás O2 fungovala jak má,',
      description: 'na vaše zařízení ukládáme a dále zpracováváme pouze údaje nezbytné k tomu, aby služba fungovala správně, spolehlivě a rychleji. Toto zpracování pro vás nepředstavuje nebezpečí. Zpracovávané údaje nelze spojit s vaší osobou nebo použít jako identifikaci pro zjištění vaší totožnosti.',
      accept: 'OK',
    },
    serviceDetail: {
      headingText: {
        withoutService: 'bez aktivního tarifu',
      },
      back: 'Zpět',
      serviceDescription: 'Popis služby',
      changeService: 'Změnit službu',
      serviceInformation: 'Informace o službě',
      paidUntil: 'Zaplaceno do',
      nextPayment: 'Další platba proběhne',
      recurrenceTerminated: 'Předplatné bylo zrušeno',
      servicePrice: 'Cena služby',
      nextPaymentDiscount: 'Sleva na následující platbu',
      amountOfFollowingPayment: 'Výše následující platby',
      price: '{{price}}',
      priceWithPeriod: '{{price}} na {{period}} dní',
      infoUnknown: 'Údaj neznámý',
      cancelSubscription: 'Zrušit předplatné na další měsíc',
      canceledSubscriptionTitle: 'Předplatné bylo zrušeno',
      havingCouponCode: 'Máte slevový kupón?',
      historyAndSetPayment: 'Historie nastavení plateb',
      settings: 'Nastavení',
      resetOrderPIN: 'Resetovat nákupní PIN',
      resetOrderPINDescription: 'Pokud neznáte svůj nákupní pin, zde ho můžete obnovit. Nový PIN je nastavený na 1111. Změníte ho v menu vaší O2 TV',
      resetParentCode: 'Resetovat rodičovský zámek',
      resetParentCodeDescription: 'Pokud neznáte svůj rodičovský zámek, zde ho můžete obnovit. Nový PIN je nastavený na 1111. Změníte ho v menu vaší O2 TV',
      funnel: 'Pomoc a podpora',
      funnelCreateTicket: 'Nahlásit poruchu on-line',
      historyOfIssues: 'Historie poruch',
      noServiceChoose: 'V tuto chvíli nemáte aktivní žádnou službu. Vyberte si některou z naší',
      tariffOfferLink: 'nabídky na o2.cz.',
      modalCancelSubscription: {
        title: 'Opravdu si přejete zrušit předplatné na další měsíc?',
        paidValidToDate: 'Po zrušení předplatného můžete sledovat Vaše programy do {{date}}',
        cancelSubscription: 'Zrušit předplatné',
        back: 'Zpět',
      },
    },
    applyVoucher: {
      back: 'Zpět',
      title: 'Uplatnit slevový kupón',
      description: 'Získejte slevu na další platbu použitím slevového kupónu.',
      voucherInputLabel: 'Zadejte kupón',
      submit: 'Uplatnit',
      success: {
        title: 'Slevový kupón byl úspěšně uplatněn!',
        linkLetsWatch: 'Přejít na sledování O2 TV',
        linkBackToSelfcare: 'Přejít do O2 TV samoobsluhy',
      },
    },
    unstandardSituation: {
      title: 'Vaše objednávka na aktivaci služby {{service}} stále probíhá. Čekáme na zpracování platby od vaší banky.',
      repeatPayment: 'Zopakovat platbu',
      description: 'Pokud se rozhodnete pro zopakování platby a předchozí platba bude realizována, peníze vám vrátime zpět',
      outOfLimitlTitle: 'Vaše objednávka na aktivaci služby {{service}} stále probíhá, prosím, počkejte na její dokončení. Čekáme na zpracování platby od vaší banky.',
      waitingForActivationServiceTitle: 'Vaše objednávka na aktivaci služby {{service}} stále probíhá. Čekáme na zpracování platby od vaší banky.',
      orYouCan: 'nebo můžete ',
      activeDifferent: 'Zrušit objednávku služby {{oldService}} a místo toho objednat novou službu.',
    },
    paymentConfirmation: {
      failedTitle: 'Nepodařilo se zaplatit',
      failedDescription: 'Zkuste zaplatit znovu nebo použijte jinou kartu.',
      payAgain: 'Zopakovat platbu',
      successTitle: 'Platba proběhla úspěšně.',
      successDescription: 'Vyčkejte, než službu aktivujeme, poté budete přesměrování na sledování televize...',
      waitingForActivationTitle: 'Prosíme o strpení, na aktivaci Vaší služby usilovně pracujeme...',
      isActivatedAndRedirectTitle: 'Vaše služba je aktivní, nyní budete přesměrováni na o2tv.cz pro sledování televize...',
      errorTitle: 'Něco se pokazilo.',
      errorDescription: 'Vaši službu se nám bohužel nepodařilo aktivovat. Peníze Vám budou vráceny na bankovní účet do {{date}}.',
      backToSelectService: 'Zpět na výběr služby',
      paymentProcessingTitle: 'Probíhá zpracování platby, vyčkejte prosím...',
    },
    payment: {
      back: 'Zpět',
      title: 'Platba',
      subtitle1: 'Platba proběhne automaticky, podle vámi zvoleného tarifu, každých 30 nebo 92 dní. Dokud ji nezrušíte.',
      subtitle2: 'To můžete udělat kdykoliv v Nastavení Vašeho účtu v sekci Moje služby.',
      item: 'Položka',
      priceWithWat: 'Cena s DPH',
      infoUnknown: 'Údaj neznámý',
      submitPayment: 'Zaplatit kartou {{payment}} kč',
      iAgreeRepeatableA: 'Souhlasím se založením opakované platby s parametry uvedenými výše.',
      iAgreeRepeatableB: 'Souhlasím s uložením platební karty pro pozdější použití k platbám za TV obsah',
      iHaveVoucher: 'Mám slevový kupón',
      price: 'cena {{price}}',
      priceWithPeriod: 'cena {{price}} na {{period}}',
      discountWithPeriod: '- {{price}} na {{period}}',
      discount: '- {{price}}',
      nextPayment: 'Příští platba: ',
      recurrency: 'Platba se bude opakovat každých 30 dní.',
      nonrecurrency: 'Uvedená částka Vám bude strhnuta pouze jedenkrát. Službu budete moci využívat do {{date}}',
      howYouWantPay: 'Jak budete platit?',
      payBySavedCard: 'Použít uloženou kartu',
      payByNewCard: 'Použít jinou kartu',
      payByNewCardDescription: 'Platba za Vámi objednanou službu je 0 Kč. Potřebujeme od Vás ale ověřit a na Vašem účtu uložit platební kartu. Ověření platební karty proběhne zaplacením 1 Kč a opětovným vrácením částky na Váš účet.',
      verifyCard: 'Ověřit platební kartu 1 Kč',
      iAgreeGoPayOnce: 'Souhlasím s uložením platebních údajů na straně platební brány GoPay a jejich použitím pro mé další platby.',
      iAgreeGoPayRepeatable: 'Souhlasím se založením opakované platby s parametry uvedenými výše, s uložením platebních údajů na straně platební brány GoPay a jejich použitím pro mé další platby.',
      apply: 'Aktivovat',
      applyVoucher: {
        title: 'Vložte slevový kupón',
      },
      discountVoucher: {
        title: 'Slevový kupón',
        delete: 'Odebrat',
        submit: 'Potvrdit',
      },
    },
    steps: {
      createAccount: 'Vytvoření účtu',
      payment: 'Platba',
    },
    header: {
      text: 'TV',
      menu: {
        dashboard: 'Moje služby',
        consumption: 'Čerpání kreditu',
        documents: 'Dokumenty',
        o2cz: 'O2.cz',
      },
      user: {
        dashboard: 'Moje služby',
        settings: 'Nastavení',
        logout: 'Odhlásit',
      },
    },
    footer: {
      careAndSupport: 'Péče a podpora',
      privacy: 'Soukromí',
      about: 'O nás',
      contacts: 'Kontakty',
      newsInMyO2: 'Novinky v Moje O2',
      downloadMyO2: 'Stáhnout aplikaci Moje O2',
      languageToSwitch: 'English',
      links: {
        careAndSupport: 'https://www.o2.cz/osobni/podpora',
        privacy: 'https://www.o2.cz/osobni/soukromi',
        about: 'https://www.o2.cz/spolecnost/',
        contacts: 'https://www.o2.cz/osobni/kontakty/',
        newsInMyO2: 'https://www.o2.cz/firmy-a-organizace/podpora/zmeny-v-moje-o2',
        downloadMyO2: 'https://www.o2knihovna.cz/aplikace/mojeo2#_il=mojeo2-footerlink',
      },
    },
    card: {
      validUntil: 'Platnost do',
    },
    migration: {
      title: 'Změna předplacené služby',
      paragraph1: 'Službu O2 TV již máte zakoupenou a sledovat ji můžete na webu <a style="text-decoration: underline; color: rgba(19,31,54,.9);" href="https://www.o2tv.cz/mig-legacy">o2tv.cz</a>, nebo v aplikaci O2 TV',
      paragraph2: 'Pokud si nyní zakoupíte nový předplacený TV tarif, získáte tím přístup do nové aplikace O2 TV 2.0',
      warning: 'Upozornění:',
      warningDescription: 'Zakoupením nového předplaceného TV tarifu bude Váš přístup do původní aplikace O2 TV ukončen, což znamená, že do ní ztratíte přístup a nebudete v ní moct slevovat obsah',
      backToLegacy: 'Sledovat O2 TV',
      newTV: 'Koupit nový tarif',
    },
    parentsLock: {
      back: 'Zpět',
      title: 'Obnova zapomenutého rodičovského zámku',
      description: 'Rodičovský zámek zabrání vašim dětem procházet a půjčovat si filmy určené pro dospělé. Potvrzením bude rodičovský zámek nastaven na výchozí hodnotu 1111.',
      changeToDefault: 'Změnit rodičovský zámek na výchozí hodnotu',
      modal: {
        title: 'Pro obnovení rodičovského zámku zadejte heslo, kterým se přihlašujete do Moje O2 nebo O2 TV samoobsluhy.',
        loginEmail: 'Přihlašovací email (O2 ID): ',
        submit: 'Potvrdit',
        password: 'Heslo',
      },
    },
    orderPin: {
      back: 'Zpět',
      title: 'Obnova objednávkového PIN kódu',
      description: 'Objednávkovým PIN kódem potvrdíte zapůjčení filmu a platbu za jeho vypůjčení. Potvrzením bude objednávkový PIN kód nastaven na výchozí hodnotu 1111.',
      changeOrderPinToDefault: 'Změnit objednávkový PIN na výchozí hodnotu',
      modal: {
        title: 'Pro obnovení PIN kódu zadejte heslo, kterým se přihlašujete do Moje O2 nebo O2 TV samoobsluhy.',
        loginEmail: 'Přihlašovací email (O2 ID): ',
        submit: 'Potvrdit',
        password: 'Heslo',
      },
    },
    changeService: {
      title: 'Změna služby',
      description: 'Službu O2 TV už máte zakoupenou. Její změnou dojde k okamžité deaktivaci služby stávající. Nová služba bude aktivována během několika minut po úspěšném provedení platby.',
      backToServiceSelection: 'Zpět na výběr služby',
      changeSubmit: 'Změnit službu',
      logout: 'Odhlásit se a zřídit další službu na jiný O2 účet',
      newService: 'Nová služba',
      newServicePrice: '{{price}} na {{period}}',
      actualService: 'Stávající služba',
      infoUnknown: 'Údaj neznámý',
      modal: {
        title: 'Pro potvrzení změny služby zadejte objednávkový PIN.',
        pinPlaceholder: 'Zadejte objednávkový PIN',
        submit: 'Potvrdit',
        validationError: 'Zadali jste nesprávný objednávkový PIN. Zkuste to, prosím, znovu.',
      },
    },
    paymentCardChange: {
      back: 'Zpět',
      title: 'Změna platební karty',
      descriptionOne: 'Uložení platební karty slouží pro jednorázové nákupy, např. filmů z videotéky nebo opakované platby při objednávce předplaceného tarifu O2TV.',
      descriptionTwo: 'Pro změnu platební karty bude provedena ověřovací platba 1 Kč, která Vám bude vrácena.',
      descriptionThree: 'Pro změnu platební karty bude provedena ověřovací platba 1 Kč, která Vám bude vrácena',
      agreeCreateGoPay: 'Souhlasím se založením opakované platby s parametry uvedenými výše, s uložením platebních údajů na straně platební brány GoPay a jejich použitím pro mé další platby.',
      agreeGoPay: 'Souhlasím s uložením platebních údajů na straně platební brány GoPay a jejich použitím pro mé další platby.',
      submitVerificationPayment: 'Provést ověřovací platbu 1 Kč',
      activeSubscription: 'Aktivní předplatné',
      service: 'Služba',
      price: 'Cena s DPH',
      priceInfo: 'cena {{price}}',
      priceInfoPeriod: 'cena {{price}} na {{period}} dní',
      infoUnknown: 'Údaj neznámý',
      discountTitle: 'Sleva na následující platbu',
      discountInfoPeriod: '- {{price}} na {{period}} dní',
      discountInfo: '- {{price}}',
      nextPayment: 'Příští platba: ',
    },
    paymentList: {
      payType: {
        service: 'Služba',
        date: 'Datum',
        variableSymbol: 'Variabilní symbol',
        amountPaid: 'Uhrazená částka',
        saveDocument: 'Stáhnout',
        chooseDocument: 'Vybrat doklad ke stažení',
        notFound: 'Nenalezeno',
        tooltip: {
          servicePrice: 'Cena služby',
          discount: 'Sleva',
          coupon: 'Kupón',
        },
        modal: {
          title: 'Protože se ke zvolené platbě váže více dokladů, vyberte ten, který chcete stáhnout.',
          column: {
            type: 'TYP',
            date: '	DATUM VYTVOŘENÍ',
            amountPaid: 'ČÁSTKA',
          },
          payType: {
            REFUND: 'Vrácení platby',
            ONE_TIME_PAY: 'Faktura',
            RECURRENT_PAY: 'Opakovaná platba',
          },
          close: 'Zavřít',
        },
      },
      refundType: {
        service: 'Služba',
        date: 'Datum',
        variableSymbol: 'Variabilní symbol',
        amountPaid: 'Uhrazená částka',
        saveDocument: 'Doklad',
      },
      showMore: 'Další',
    },
    payments: {
      back: 'Zpět',
      title: 'Platby',
      paymentCard: 'Platební karta',
      changePaymentCard: 'Změnit platební kartu',
      paymentHistory: 'Historie plateb',
      viewLimit: 'UPOZORNĚNÍ: Historii plateb zobrazujeme za posledních 12 měsíců.',
      applyVoucher: 'Uplatnit kupón na další platbu',
      cancelPaymentForNextMonth: 'Zrušit platbu na další měsíc',
      filter: {
        from: 'Od:',
        to: 'Do:',
      },
    },
    noService: {
      title: 'Momentálně nemáte aktivní žádnou službu.',
      goSelectService: 'Přejít na o2.cz k výběru služby',
    },
    redirectingForPayment: {
      title: 'Probíhá přesměrování do platební brány, vyčkejte',
      goPay: 'Přejít do platební brány, vyčkejte prosím...',
    },
    login: {
      title: 'Přihlášení',
      o2id: 'E-mail (O2 ID)',
      password: 'Heslo',
      showPassword: 'Zobrazit heslo',
      hidePassword: 'Skrýt heslo',
      submit: 'Přihlásit se',
      cantLogin: 'Nedaří se vám přihlásit?',
      createAccount: 'Vytvořit účet',
    },
  },
  global: {
    search: 'Vyhledat',
    period: {
      MONTH: '30 dní',
      THREE_MONTHS: '3 měsíce',
      YEAR: 'rok',
    },
    error: {
      passwordMandatory: 'Heslo musí být vyplněno',
      voucherNotValid: 'Vámi zadaný kupón není platný.',
      voucherInactive: 'Vámi zadaný kupón je neaktivní.',
      voucherExpired: 'Vámi zadanému kupónu vypršela platnost.',
      voucherAlreadyUsed: 'Vámi zadaný kupón byl již použitý.',
      voucherNotExist: 'Vámi zadaný kupón neexistuje.',
      voucherNotValidForTariff: 'Vámi zadaný kupón je k danému tarifu neplatný.',
      voucherIncompatibleWithTariff: 'Vámi zadaný kupón na tento produkt nelze uplatnit.',
      voucherCannotGetCurrentTariff: 'Nepodařilo se dohledat informace potřebné k tarifu k uplatnění kupónu.',
    },
  },
};
