import ActionsOrderDetail from '@rof/Actions/orderDetail';
import { GetOrderDetailResponse } from '@rof/Api/bls-payment/types';

import { IState } from '../types';

const init: IState<GetOrderDetailResponse> = {
  data: undefined,
  isLoading: false,
  error: undefined,
  lastFetched: undefined,
};

const orderDetailReducer = (state: IState<GetOrderDetailResponse> = init, action: ActionsOrderDetail) => {
  switch (action.type) {
    case '[BLS-PAYMENT - orderDetail] START':
      return {
        ...state,
        isLoading: true,
      };
    case '[BLS-PAYMENT - orderDetail] SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case '[BLS-PAYMENT - orderDetail] ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: return state;
  }
};

export default orderDetailReducer;
