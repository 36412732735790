import { createGlobalStyle } from 'styled-components';

import 'o2-theme-react/lib/o2-date-picker.css';
import 'o2-theme-react/lib/o2-select-ex.css';

const GlobalStyle = createGlobalStyle`
  .o2soc .Select.Select--subheader .Select-control {
    border: none;
    box-shadow: none;
  }

  .o2soc .Select.Select--subheader .Select-value-label {
    font-size: 20px;
  }
  
  .o2soc {
    h1.o2-typo--heading,
    .o2-typo--heading-h1 {
      font-weight: 800 !important;
    }
  }

  .o2soc .o2-list--no-last-border .o2-list__item:last-child {
    border-bottom: none;
  }
`;

export default GlobalStyle;
