import { FinancialDocumentsRequest } from '@rof/Api/bff/financialDocuments/types';
import { IServiceList } from '@rof/Api/cpServices/types';
import useCodelists from '@rof/Hooks/useCodeLists';
import { IState } from '@rof/Reducers/types';
import IStore from '@Store/types';

import { useSelector } from 'react-redux';

const useParamsBuilder = (issueDateFrom?: string, issueDateTo?: string): FinancialDocumentsRequest => {
  const { getItems } = useCodelists();
  const [ channel, customerMasterSource,forceCheck ] = getItems('bff.common.channel', 'bff.common.customerMasterSource', 'bff.common.forceCheck');
  const userServices = useSelector<IStore, IState<IServiceList>>((state) => state.serviceList);
  
  const financialDocumentsParams: FinancialDocumentsRequest = {
    channel,
    customerMasterSource,
    forceCheck,
    deviceId: userServices.data?.services?.[0].serviceId,
    issueDateFrom,
    issueDateTo,
  };

  return financialDocumentsParams;
};

export default useParamsBuilder;
