import { useState } from 'react';

import { getFinancialDocuments } from '@rof/Api/bff/financialDocuments';
import { FinancialDocumentsResponse } from '@rof/Api/bff/financialDocuments/types';
import IStore from '@Store/types';

import { useDispatch, useSelector } from 'react-redux';

import useParamsBuilder from './useParamsBuilder';

const useCpGetFinancialDocumentList = () => {
  const APIParams = useParamsBuilder();
  const [ financialDocuments, setFinancialDocuments ] = useState<FinancialDocumentsResponse | undefined>();
  const isLoadingOnStore = useSelector<IStore, boolean>(
    (state) => state.getFinancialDocuments?.isLoading
  ); 
  const [ error, setError ] = useState<string | undefined>();
  const dispatch = useDispatch();

  const fetchData = (issueDateFrom?: string, issueDateTo?: string) => {
    if (!isLoadingOnStore) {
      dispatch({ 
        type: '[BFF - getFinancialDocuments - ANY] START',
      });
      getFinancialDocuments({ ...APIParams, issueDateFrom, issueDateTo })
        .then(({ data }) => {
          setFinancialDocuments(data);
          dispatch({ 
            type: '[BFF - getFinancialDocuments - ANY] SUCCESS',
          });
        })
        .catch((err) => {
          setError(err);
          dispatch({ 
            type: '[BFF - getFinancialDocuments - ANY] ERROR',
            error: err,
          });
        });
    }
  };

  const filterGetFinancialDocumentListData = (dateFrom?: string, dateTo?: string) => {
    fetchData(dateFrom, dateTo);
  };

  return {
    init: () => fetchData(),
    filterGetFinancialDocumentListData,
    data: financialDocuments,
    isLoading: isLoadingOnStore,
    error,
  };
};

export default useCpGetFinancialDocumentList;
