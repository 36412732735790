import ActionSetKahlMessageId from '@rof/Actions/kahlInfo';
import IStore from '@Store/types';

import { ThunkDispatch } from 'redux-thunk';

export const setKahlMessageId = (kahlMessageId: string) => {
  return async (dispatch: ThunkDispatch<IStore, void, ActionSetKahlMessageId>) => {
    return dispatch({
      type: '[KAHL] SET_MESSAGE_ID',
      data: kahlMessageId,
    });
  };
};
