import React, { useState, useEffect } from 'react';

import { handleEnterSubmit } from '@rof/Utils/index';

import { O2Modal, O2ModalBody, O2Indent, O2TextField, O2Button } from 'o2-theme-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IProps } from './types';

const PinConfirmModal: React.FC<IProps> = ({ isOpen, onConfirm, onClose }: IProps) => {
  const { t } = useTranslation();
  const [ pin, setPin ] = useState('');
  const [ isError, setIsError ] = useState(false);
  const { formState, handleSubmit } = useForm();
  const onSubmit = () => {
    if (!pin.length) setIsError(true);
    else !isError && onConfirm(pin); 
  };

  useEffect(() => {
    setIsError(false);
    // eslint-disable-next-line
  }, [ pin ]);

  useEffect(() => {
    setPin('');
    setIsError(false);
    // eslint-disable-next-line
  }, [ isOpen ]);

  // input autofocus feature
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const input = document.getElementById('pin-autofocus');
        input?.focus();
      });
    }
  }, [ isOpen ]);

  return (
    <O2Modal isOpen={isOpen} toggle={onClose}>
      <O2ModalBody>
        <O2Indent>
          <h2>{t('tvsc.changeService.modal.title')}</h2>
        </O2Indent>
        <O2Indent standalone>
          <O2TextField
            onChange={(e: any) => setPin(e.target.value)}
            value={pin}
            mobileFull
            controlSize="medium"
            placeholder={t('tvsc.changeService.modal.pinPlaceholder')}
            validationType={isError && 'error'}
            validationText={isError ? t('tvsc.changeService.modal.validationError') : ''} 
            onKeyDown={(e: any) => handleEnterSubmit(handleSubmit(onSubmit), e, formState.isSubmitting)}
          />
        </O2Indent>
        <O2Indent>
          <O2Button 
            color="primary" 
            onClick={handleSubmit(onSubmit)}
            disabled={formState.isSubmitting}
          >
            {t('tvsc.changeService.modal.submit')}
          </O2Button>
        </O2Indent>
      </O2ModalBody>
    </O2Modal>
  );
};

export default PinConfirmModal;
