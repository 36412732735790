import React, { useState, useEffect } from 'react';

import useAnalytics from '@Hooks/useAnalytics';
import useDatePicker from '@rof/Hooks/useDatePicker';

import { isMatch, format } from 'date-fns';
import { 
  O2ControlSeparator, 
  O2DateField, 
  O2Button, 
} from 'o2-theme-react';
import { useTranslation } from 'react-i18next';

import IProps from './types';

const Filter: React.FC<IProps> = ({ maxMonths, onSearch, isLoading, maxFromBack }: IProps) => {
  const { AAEBillingSearchSubmit } = useAnalytics();
  const { t } = useTranslation();
  const { dateTo, dateFrom, setDate } = useDatePicker(maxMonths, maxFromBack);
  const [ dtFrom, setDtFrom ] = useState<string>();
  const [ dtTo, setDtTo ] = useState<string>();
  const [ isFocus, setIsFocus ] = useState<boolean>(false);
  const filterDateFormat = 'dd.MM.yyyy';

  const getFormattedDate = (dateFormat: string, date?: string) => {
    return date && format(new Date(date), dateFormat);
  };

  useEffect(() => {
    console.log('UseEffect dateFrom ' + dateFrom);
    
    setDtFrom(getFormattedDate(filterDateFormat, dateFrom));
    // eslint-disable-next-line
  }, [dateFrom])

  useEffect(() => {
    console.log('UseEffect dateTo ' + dateTo);
    setDtTo(getFormattedDate(filterDateFormat, dateTo));
    // eslint-disable-next-line
  }, [dateTo])

  const setDt = (key: string, value?: string) => {
    switch (key) {
      case 'dateFrom':
        setDtFrom(value !== undefined ? value : getFormattedDate(filterDateFormat, dateFrom));
        break;
      case 'dateTo':
        setDtTo(value !== undefined ? value : getFormattedDate(filterDateFormat, dateTo));
        break;
      default:
        console.log(`Unexpected key: ${key}`);
        break;
    }
  };

  const change = (e: any, key: string) => {
    if ((e.target.value).length === filterDateFormat.length && !isFocus) {
      setDate(e.target.value, key, filterDateFormat);
    }
    setDt(key, e.target.value);
  };

  const blur = (e: any, key: string) => {
    const parsedDate = e.target.value.split('.');

    for (let i = 0, length = parsedDate.length; i < length; i++) {
      if (parsedDate[i].length === 1) {
        parsedDate[i] = '0' + parsedDate[i];
      }
    }

    const stickDateBack = parsedDate.join('.');
    if ((stickDateBack.length === filterDateFormat.length && isMatch(stickDateBack, filterDateFormat)) || stickDateBack.length === 0) {
      setDate(stickDateBack, key, filterDateFormat);
    } else {
      setDt(key);
    }
    setIsFocus(false);
  };

  return (
    <>
      <O2DateField 
        value={dtFrom}
        onChange={(e: any) => change(e, 'dateFrom')}
        onFocus={() => setIsFocus(true)}
        onBlur={(e: any) => blur(e, 'dateFrom')}
        // placeholder={t('pwsc.common.date.from')} 
        inline 
        inlineVertical="bottom" 
      />
      <O2ControlSeparator />
      <O2DateField 
        value={dtTo}
        onChange={(e: any) => change(e, 'dateTo')}
        onFocus={() => setIsFocus(true)}
        onBlur={(e: any) => blur(e, 'dateTo')}
        // placeholder={t('pwsc.common.date.to')} 
        inline 
        inlineVertical="bottom" 
        indentRight 
      />
      <O2Button 
        disabled={isLoading}
        color={'primary'} 
        onClick={() => {
          onSearch(dateFrom, dateTo); AAEBillingSearchSubmit(); 
        }}
      >
        {t('global.search')}
      </O2Button>
    </>
  );
};

export default Filter;
