import React, { useState, useEffect } from 'react';

import { O2PopupMessage, O2PopupMessageItem } from 'o2-theme-react';

import IProps, { IFlashMessage } from './types';
import { newId } from './util';

// @types

const FlashMessage: React.FC<IProps> = ({ message }: IProps) => {
  const [ messages, setMessages ] = useState([]);

  const handleClose = (id: string) => {
    setMessages((prevState) => {
      return [
        ...prevState.filter((msg: IFlashMessage) => msg.id !== id),
      ] ; 
    });
  };

  const addMessage = (newMessage: IFlashMessage) => {
    if (newMessage.autoClose !== 0) {
      setTimeout(() => {
        handleClose(newMessage.id);
      }, newMessage.autoClose * 1000);
    }

    setMessages((prevState) => {
      const newMessages: any = [ ...prevState ];
      newMessages.push(newMessage);
      return newMessages;
    });
  };

  useEffect(() => {
    if (message !== null) {
      addMessage({ ...message, id: newId() });
    }
    // eslint-disable-next-line
  }, [ message ]);

  return (
    <O2PopupMessage >
      {messages &&
          messages.map((msg: IFlashMessage) => (
            <O2PopupMessageItem
              key={msg.id}
              color={msg.color}
              onClose={() => handleClose(msg.id)}
              closeBtn={msg.hasCloseBtn}
            >
              <div dangerouslySetInnerHTML={{ __html: msg.text }} />
            </O2PopupMessageItem>
          ))}
    </O2PopupMessage>
  );
};

export default FlashMessage;
