import { CODE_LIST_API_BASE } from '@rof/Constants/api';
import { sendGet } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { ICodeListProp } from './types';

export const getCodeList = (codeListName: string): AxiosPromise<ICodeListProp[]> => {
  return sendGet<ICodeListProp[]>(`${CODE_LIST_API_BASE}/${codeListName}`, undefined, undefined, undefined, undefined, false, true);
};
