import ActionsGetFinancialDocuments from '@rof/Actions/getFinancialDocuments';
import { FinancialDocumentsResponse } from '@rof/Api/bff/financialDocuments/types';

import { IState } from '../types';

const init: IState<FinancialDocumentsResponse> = {
  data: undefined,
  isLoading: false,
  error: undefined,
  lastFetched: undefined,
};

const getDashboardProductsReducer = (state: IState<FinancialDocumentsResponse> = init, action: ActionsGetFinancialDocuments) => {
  switch (action.type) {
    case '[BFF - getFinancialDocuments - ANY] START':
      return {
        ...state,
        isLoading: true,
      };
    case '[BFF - getFinancialDocuments - ANY] SUCCESS':
      return {
        ...state,
        isLoading: false,
        lastFetched: Date.now(),
      };
    case '[BFF - getFinancialDocuments - ANY] ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default getDashboardProductsReducer;
