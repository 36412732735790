import React from 'react';

import { O2Addon, O2AddonContent, O2AddonPane, O2Icon } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';

import IProps from './types';

const PaymentList = ({ payments, nextPaymentDate }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="o2-list o2-list--no-last-border">
      <ul className="o2-list__items">
        <li className="o2-list__item">
          <div className="o2-list__item-content">
            <O2Addon className="o2-typo--color-secondary" breakpoint="sm">
              <O2AddonContent>{t('tvsc.payment.item')}</O2AddonContent>
              <O2AddonPane>{t('tvsc.payment.priceWithWat')}</O2AddonPane>
            </O2Addon>
          </div>
        </li>
        {payments.map((payment, i) =>
          <li className="o2-list__item" key={i}>
            <div className="o2-list__item-content">
              <O2Addon className="o2-typo--weight-important" breakpoint="sm">
                <O2AddonContent>{payment.name}</O2AddonContent>
                <O2AddonPane className={payment.isDiscount ? 'o2-typo--color-success' : ''}>{payment.price}</O2AddonPane>
              </O2Addon>
            </div>
          </li>
        )}
        {nextPaymentDate &&
          <li className="o2-list__item">
            <div className="o2-list__item-content">
              <div className="o2-typo--align-right-md o2-typo--weight-important" style={{marginTop: '8px'}}>
                <O2Icon name="calendar" indentRight />
                {t('tvsc.paymentCardChange.nextPayment')}
                {nextPaymentDate}
              </div>
            </div>
          </li>
        }
      </ul>
    </div>
  );
};

export default PaymentList;
