import useLocalization from '@rof/Hooks/useLocalization';

const useMoney = () => {
  const { lang } = useLocalization();

  const round = (value: number, decimals: number): number => {
    return Number(Math.round(parseFloat(`${value}e${decimals}`)) + `e-${decimals}`);
  };

  const formatSuffix = (suffix: string) => {
    switch (suffix) {
      case 'CZK':
        return 'Kč';
      default: 
        return suffix;
    }
  };
  
  const moneyFormat = (value: number | string, decimals = 2, prefix?: string, suffix?: string): string => {
    let money = Number(value).toLocaleString(lang, { maximumFractionDigits: decimals, minimumFractionDigits: decimals });

    if (prefix) money = `${prefix} ${money}`;
    if (suffix) money = `${money} ${formatSuffix(suffix)}`;
      
    return money;
  };

  return {
    moneyFormat,
    round,
  };
};

export default useMoney;
