import React, { useEffect, useState } from 'react';

import PaymentConfirmation from '@Components/blocks/PaymentConfirmation';
import { IAAEPaymentResultType } from '@Hooks/types';
import useAnalytics from '@Hooks/useAnalytics';
import useOrderDetail from '@Modules/orderDetail/useOrderDetail';
import useCodelists from '@rof/Hooks/useCodeLists';

import { O2Layout, O2LayoutColumn, O2Section, O2Indent } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { IProps } from './types';
const PaymentResult: React.FC<IProps> = () => {
  const { AAEPageLoaded, AAEPaymentResult } = useAnalytics();
  const { t } = useTranslation();
  const history = useHistory();
  const { init, data } = useOrderDetail();
  const [ isFirstRequested, setIsFirstRequested ] = useState(false);
  const { getItems } = useCodelists();
  const [ homepage, checkOrderStatusInterval ] = getItems('redirect.kaltura.homepage', 'bsl.checkOrderStatusInterval');
  const o2Domain= window.location.hostname.split('.').slice(-2).join('.');
 
  useEffect(() => {
    init(true);
    setIsFirstRequested(true);
    const interval = setInterval(() => {
      init(true);
    }, parseInt(checkOrderStatusInterval));
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && isFirstRequested) {
      switch (data?.orderStatus) {
        case 'WAIT_FOR_PAYMENT':
          history.push('/payment-waiting');
          break;
        default: 
          console.log('no unstandard orderStatus');
      }
    }
    // eslint-disable-next-line
  }, [ data ]);

  useEffect(() => {
    if (data?.orderStatus === 'SUCCESS') {
      if (data?.serviceCode) {
        const returnUrl = window.localStorage.getItem(data?.serviceCode);
        window.localStorage.removeItem(data?.serviceCode);
        setTimeout(() => {
          if (returnUrl && returnUrl.includes(o2Domain)) {
            window.location.href = returnUrl;
          } else {
            window.location.href = homepage;
          } 
        }, 1500);
      } else {
        setTimeout(() => {
          window.location.href = homepage;
        }, 1500);
      }
    }
    // eslint-disable-next-line
  }, [data?.orderStatus]);

  useEffect(() => {
    AAEPageLoaded({ pageId: '2.1.2.1', pageTitle: t('tvsc.paymentConfirmation.title')});
  // eslint-disable-next-line
}, []);

  // eslint-disable-next-line max-len
  const AAPaymentResult = (resultType: IAAEPaymentResultType, message: string) => {
    data && AAEPaymentResult({resultType, message, totalAmount: data.price, transactionId: data.orderId}); 
  };

  const paymentConfirmation = () => {
    switch (data?.orderStatus) {
      case 'CREATED':
      case 'WAIT_FOR_PAYMENT':
        AAPaymentResult(IAAEPaymentResultType.processing, t('tvsc.paymentConfirmation.paymentProcessingTitle'));
        return (
          <PaymentConfirmation
            type="inProgress"
            textTitle={t('tvsc.paymentConfirmation.paymentProcessingTitle')} />
        );
      case 'IN_PROVISIONING':
        AAPaymentResult(IAAEPaymentResultType.processing, t('tvsc.paymentConfirmation.waitingForActivationTitle'));
        return (
          <PaymentConfirmation
            type="inProgress"
            textTitle={t('tvsc.paymentConfirmation.waitingForActivationTitle')} />
        );
      case 'SUCCESS':
        AAPaymentResult(IAAEPaymentResultType.success, t('tvsc.paymentConfirmation.isActivatedAndRedirectTitle'));
        return (
          <PaymentConfirmation
            type="success"
            textTitle={t('tvsc.paymentConfirmation.isActivatedAndRedirectTitle')} />
        );
      case 'FAILED_WITH_REFUND':
        AAPaymentResult(IAAEPaymentResultType.failed, t('tvsc.paymentConfirmation.errorTitle'));
        return (
          <PaymentConfirmation
            type="error"
            textTitle={t('tvsc.paymentConfirmation.errorTitle')}
            textDetail={t('tvsc.paymentConfirmation.errorDescription')}
            textCTA={t('tvsc.paymentConfirmation.backToSelectService')}
            onClickCTA={() => window.location.href = homepage} />
        );
      case 'FAILED_WITHOUT_REFUND':
      case 'CANCELED':
        AAPaymentResult(IAAEPaymentResultType.failed, t('tvsc.paymentConfirmation.failedTitle'));
        return (
          <PaymentConfirmation
            type="error"
            textTitle={t('tvsc.paymentConfirmation.failedTitle')}
            textDetail={t('tvsc.paymentConfirmation.failedDescription')}
            textCTA={t('tvsc.paymentConfirmation.payAgain')}
            onClickCTA={() => history.push(`/payment/${data.serviceCode}`)} />
        );
      default:
        return '';
    }
  };
  
  return (
    <>
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          <O2Section>
            <O2Indent>
              {paymentConfirmation()}
            </O2Indent>
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
    </>
  );
};

export default PaymentResult;
