import { useEffect, useState } from 'react';

import { 
  COMMON_FAIL,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_MAX_ATTEMPTS_EXCEEDED,
  CREATE_ORDER_ORDER_ALREADY_FINISHED,
  CREATE_ORDER_ORDER_CANCELED,
  CREATE_ORDER_ORDER_NOT_FOUND,
  CREATE_ORDER_OTHER_ORDER_EXISTS,
} from '@Constants/kahl';
import useAnalytics from '@Hooks/useAnalytics';
import useCheckProduct from '@Modules/checkProduct/useCheckProduct';
import useGetCardDetail from '@Modules/getCardDetail/useGetCardDetail';
import {putCreateOrder} from '@rof/Api/bls-payment/createOrder';
import { ValidateVoucherResponse } from '@rof/Api/bls-sc/types';
import { postValidateVoucher } from '@rof/Api/bls-sc/validateVoucher';
import useMoney from '@rof/Hooks/useMoney';
import useReduxLoggerActions from '@rof/Hooks/useReduxLoggerActions';
import { CreateOrderContext } from '@rof/Reducers/createOrderContext/types';
import { setKahlMessageId } from '@rof/Thunks/kahl';
import IStore from '@Store/types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IType, IValidation } from '../types';
import useCodeLists from '@rof/Hooks/useCodeLists';
const usePaymentData = (id: string, voucherCode?: string) => {
  const { AAEFormSubmit } = useAnalytics();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { moneyFormat } = useMoney();
  const history = useHistory();
  const { reduxLog } = useReduxLoggerActions();
  const [ voucher, setVoucher ] = useState<string | undefined>('');
  const [ voucherData, setVoucherData ] = useState<ValidateVoucherResponse>();
  const [ voucherValidation, setVoucherValidation ] = useState<IValidation>();
  const [ selectedCard, setSelectedCard ] = useState('');
  const { init: checkProductInit, data: checkProductData } = useCheckProduct();
  const { init: getCardDetailInit, isLoading: getCardDetailIsLoading, data: getCardDetailData } = useGetCardDetail();
  const createOrderContext = useSelector<IStore, CreateOrderContext>((state) => state.createOrderContext);
  const { getItems } = useCodeLists();
  const [ savedCardHidden ] = getItems('savedCardHidden');

  useEffect(() => {
    if (!getCardDetailData && !getCardDetailIsLoading) getCardDetailInit(true);
    if (!voucherCode && voucher === '') checkProductInit(false, id, voucherCode);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((voucher && voucher.length) || voucher === undefined || voucherValidation !== undefined) {
      checkProductInit(true, id, voucher);
    }
    // eslint-disable-next-line
  }, [ voucher, voucherValidation ]);

  const getFailureReason = (failureReason: any) => {
    let reason;
    switch (failureReason) {
      case 'VOUCHER_NOT_VALID':
        reason = t('global.error.voucherNotValid');
        break;
      case 'VOUCHER_INACTIVE':
        reason = t('global.error.voucherInactive');
        break;
      case 'VOUCHER_EXPIRED':
        reason = t('global.error.voucherExpired');
        break;
      case 'VOUCHER_ALREADY_USED':
        reason = t('global.error.voucherAlreadyUsed');
        break;
      case 'VOUCHER_NOT_EXIST':
        reason = t('global.error.voucherNotExist');
        break;
      case 'VOUCHER_NOT_VALID_FOR_TARIFF':
        reason = t('global.error.voucherNotValidForTariff');
        break;
      default:
        reason = t('global.error.voucherNotValid');
        break;
    }
    return reason;
  };

  const processVoucherValidation = (validationText: string) => {
    setVoucherValidation({
      text: validationText,
      type: 'error' as IType,
    });
    setVoucher(undefined);
  };

  useEffect(() => {
    if (checkProductData && voucher) {
      (async () => {
        try {
          reduxLog('postValidateVoucher START');
          const { data } = await postValidateVoucher({ voucherCode: voucher });
          reduxLog('postValidateVoucher SUCCESS');

          if (data.success) {
            if (checkProductData?.serviceDiscount) {
              setVoucherData(data);
            } else {
              reduxLog('postValidateVoucher VOUCHER NOT VALID FOR THIS TARIFF - NULL DISCOUNT');
              processVoucherValidation(getFailureReason('VOUCHER_NOT_VALID_FOR_TARIFF'));
            }
          } else {
            processVoucherValidation(getFailureReason(data.failureReason));
          }
        } catch (error) {
          reduxLog('postValidateVoucher FAIL');
          processVoucherValidation(t('global.error.voucherNotValid'));
        }
      })();
    }
    // eslint-disable-next-line
  }, [checkProductData]);

  const submitPayment = async () => {
    if (createOrderContext.productType) {
      try {
        const { data } = await putCreateOrder({
          productCode: id,
          ppvId: createOrderContext.ppvId,
          voucherCode: voucher?.length ? voucher : undefined,
          pin: createOrderContext.orderPin,
          productType: createOrderContext.productType,
          orderType: createOrderContext.hasActiveService ? 'SERVICE_CHANGE' : 'SERVICE_ACTIVATION', 
          usedStoredCard: (selectedCard === 'savedCard' && savedCardHidden !== 'true'),
          changeStored: (selectedCard === 'newCard' || selectedCard === ''),
        });
        if (data.success) {
          dispatch(setKahlMessageId(CREATE_ORDER_SUCCESS));
          reduxLog('putCreateOrder SUCCESS');
          if (selectedCard === 'savedCard') {
            if (data.orderInfo?.price === 0) {
              history.push('/payment-result');
            } else {
              history.push('/payment-waiting');
            }
          } else {
            history.push('/redirecting-for-payment');
          }
        } else {
          reduxLog('putCreateOrder FAIL');
          switch (data.failureReason) {
            case 'MAX_ATTEMPTS_EXCEEDED':
              dispatch(setKahlMessageId(CREATE_ORDER_MAX_ATTEMPTS_EXCEEDED));
              break;
            case 'ORDER_ALREADY_FINISHED':
              dispatch(setKahlMessageId(CREATE_ORDER_ORDER_ALREADY_FINISHED));
              break;
            case 'ORDER_CANCELED':
              dispatch(setKahlMessageId(CREATE_ORDER_ORDER_CANCELED));
              break;
            case 'ORDER_NOT_FOUND':
              dispatch(setKahlMessageId(CREATE_ORDER_ORDER_NOT_FOUND));
              break;
            case 'OTHER_ORDER_EXISTS':
              dispatch(setKahlMessageId(CREATE_ORDER_OTHER_ORDER_EXISTS));
              break;
            default:
              dispatch(setKahlMessageId(COMMON_FAIL));
              break;
          }
        }
      } catch (error) {
        reduxLog('putCreateOrder FAIL');
        dispatch(setKahlMessageId(CREATE_ORDER_FAIL));
      }
    } else console.log('productType is undefined');
    AAEFormSubmit({formId: '2.1.2_007'});
  };

  const submitVoucher = async (code: string) => {
    setVoucher(code); AAEFormSubmit({formId: '2.1.2_010'}); 
  };

  const paymentFormat = (name: string, isDiscount: boolean, price?: number, period?: string, suffif?: string) => {
    return {
      name: name,
      // eslint-disable-next-line eqeqeq
      price: price != null
        ? period 
          ? t(`tvsc.payment.${isDiscount ? 'discount' : 'price'}WithPeriod`, {
            price: moneyFormat(Math.floor(price), undefined, undefined, suffif),
            period,
          })
          : t(`tvsc.payment.${isDiscount ? 'discount' : 'price'}`, {
            price: moneyFormat(Math.floor(price), undefined, undefined, suffif),
          })
        : t('tvsc.payment.infoUnknown'),
      isDiscount,
    };
  };

  const payments = () => {
    const result = [];
    // eslint-disable-next-line eqeqeq
    if (checkProductData?.serviceName != null) {
      result.push(
        paymentFormat(
          checkProductData?.serviceName,
          false,
          checkProductData?.servicePrice, 
          checkProductData?.servicePeriod, 
          'CZK'
        ));
    }
    if (voucher && voucherData) {
      result.push(
        paymentFormat(
          voucherData?.voucherDescription ?? '',
          true, 
          checkProductData?.serviceDiscount,
          checkProductData?.serviceDiscountPeriod, 
          'CZK'
        ));
    }
    return result;
  };

  const totalPayment = () => {
    if (!checkProductData?.servicePrice && checkProductData?.servicePrice !== 0) return null;

    let result = checkProductData?.servicePrice - (voucher && checkProductData?.serviceDiscount ? checkProductData?.serviceDiscount : 0);

    if (result === 0 && selectedCard === 'newCard') result += 1;
    return result;
  };

  const clearVoucher = () => {
    setVoucher(undefined);
    setVoucherData(undefined);
  };

  return {
    data: { checkProductData, getCardDetailData },
    paymentsData: payments(),
    submitPayment,
    submitVoucher,
    voucher: {
      value: voucher,
      clear: clearVoucher,
      set: setVoucher,
      data: voucherData,
      setData: setVoucherData,
      validation: voucherValidation,
      setValidation: setVoucherValidation,
    },
    card: { selectedCard, setSelectedCard },
    totalPayment: totalPayment(),
    createOrderContext,
  };
};

export default usePaymentData;
