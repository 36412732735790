import { useEffect } from 'react';

import { DOWNLOAD_DOCUMENT_FAIL } from '@Constants/kahl';
import useCpGetFinancialDocumentList from '@Modules/cpGetFinancialDocumentList/useCpGetFinancialDocumentList';
import useGetBalanceHistory from '@Modules/getBalanceHistory/useGetBalanceHistory';
import useGetCardDetail from '@Modules/getCardDetail/useGetCardDetail';
import useParamsBuilder from '@Modules/getFinancialDocumentDetail/useParamsBuilder';
import useGetUserServices from '@Modules/getUserServices/useGetUserServices';
import { getSpecificFinancialDocuments } from '@rof/Api/bff/financialDocuments';
import useReduxLoggerActions from '@rof/Hooks/useReduxLoggerActions';
import { setKahlMessageId } from '@rof/Thunks/kahl';

import { useDispatch } from 'react-redux';

const usePaymentsData = () => {
  const dispatch = useDispatch();
  const { reduxLog } = useReduxLoggerActions();
  const specificFinancialDocumentParams = useParamsBuilder(); 
  const { init: getCardDetailInit, data: getCardDetailData } = useGetCardDetail();
  const { 
    init: cpGetFinancialDocumentListInit,
    data: cpGetFinancialDocumentListData,
    error: cpGetFinancialDocumentListError,
    isLoading: cpGetFinancialDocumentListLoading,
    filterGetFinancialDocumentListData, 
  } = useCpGetFinancialDocumentList();

  const { 
    init: getUserServicesInit, 
    data: getUserServicesData, 
    isLoading: getUserServicesIsLoading } = useGetUserServices();

  const { 
    init: getBalanceHistoryInit, 
    data: getBalanceHistoryData, 
    isLoading: getBalanceHistoryIsLoading,
    isPossibleFetchMore,
    fetchMoreGetBalanceHistoryData,
    filterGetBalanceHistoryData, 
  } = useGetBalanceHistory(1);

  useEffect(() => {
    getCardDetailInit(true);
    if (!getUserServicesData) getUserServicesInit(true);
    else {
      getBalanceHistoryInit();
      cpGetFinancialDocumentListInit();
    }
    // eslint-disable-next-line
  }, [ ]);

  useEffect(() => {
    if (getUserServicesData && !getUserServicesIsLoading) {
      getBalanceHistoryInit();
      cpGetFinancialDocumentListInit();
    }
    // eslint-disable-next-line
  }, [ getUserServicesData ]);

  const downloadDocument = (variableSymbol: string, documentId: string) => {
    const document = cpGetFinancialDocumentListData?.financialDocumentBasicList.find((item) => { 
      if (item.documentId === documentId && item.variableSymbol === variableSymbol) {
        return item;
      }
    });
    if (document?.documentId) {
      const { documentId, caRefNo} = document;

      reduxLog('getSpecificFinancialDocuments START');
      getSpecificFinancialDocuments({ ...specificFinancialDocumentParams, caRefNo, documentId }, documentId)
        .then(({ data }) => {
          reduxLog('getSpecificFinancialDocuments SUCCESS');
          // window.open(data?.financialDocument.dmsDocumentBasic?.url, '_blank');
          window.open(data?.dmsDocumentBasic?.url, '_blank');
        })
        .catch(() => {
          reduxLog('getSpecificFinancialDocuments FAIL');
          dispatch(setKahlMessageId(DOWNLOAD_DOCUMENT_FAIL));
        });
    }
  };

  return {
    data: { getCardDetailData, getUserServicesData, cpGetFinancialDocumentListData, getBalanceHistoryData },
    error: { cpGetFinancialDocumentListError },
    isLoading: { getUserServicesIsLoading, getBalanceHistoryIsLoading, cpGetFinancialDocumentListLoading },
    isPossibleFetchMore,
    fetchMoreGetBalanceHistoryData,
    filterGetBalanceHistoryData,
    filterGetFinancialDocumentListData,
    downloadDocument,
  };
};

export default usePaymentsData;
