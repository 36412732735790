import React from 'react';

import Table from '@Components/blocks/Table';
import Tooltip from '@Components/blocks/Tooltip';
import Filter from '@Components/sections/Filter';
import useCodelists from '@rof/Hooks/useCodeLists';
import useDateTime from '@rof/Hooks/useDateTime';
import useMoney from '@rof/Hooks/useMoney';

import { O2Icon, O2Indent, O2Button } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';

import IProps, { IPayment } from './types';

const PaymentList = ({ payments, onShowMore, onFilter, loading, onDownload, isPossibleFetchMore , documents }: IProps) => {
  const { t } = useTranslation();
  const { dateFormat } = useDateTime();
  const { moneyFormat } = useMoney();
  const { getItems } = useCodelists();
  const [ maxHistoryMonthInPast ] = getItems('bff.common.maxHistoryMonthInPast');

  const amountPaid = (payment: IPayment, modal = false) => {
    const price = moneyFormat(payment?.finalPrice ?? 0, undefined, undefined, payment.currency);

    if (!modal) {
      if ((payment.paymentType === 'ONE_TIME_PAY' || payment.paymentType === 'RECURRENT_PAY' || payment.paymentType === 'REQUEST') && payment.discount) {
        return (
          <Tooltip prefix={price}>
            <div>{`${t('tvsc.paymentList.payType.tooltip.servicePrice')} ${payment.originalPrice} ${payment.currencyLocale.attributeTextExt}`}</div>
            <div>{`${t('tvsc.paymentList.payType.tooltip.discount')} ${payment.discount} ${payment.currencyLocale.attributeTextExt}`}</div>
            {payment.voucherId && <div>{`${t('tvsc.paymentList.payType.tooltip.coupon')} ${payment.voucherId}`}</div>}
          </Tooltip>
        );
      }
    }

    return price;
  };

  const saveDocument = (payment: IPayment) => {
    let documentType: string | boolean = '';
    switch (payment.paymentType) {
      case 'RECURRENT_PAY':
      case 'ONE_TIME_PAY':
        documentType = 'DOC039';
        break;
      case 'REFUND':
        documentType = 'DOC038';
        break;
      case 'REQUEST':
        documentType = false;
        break;
      default:
    }

    const document = documents?.find((item) => {
      if (item.variableSymbol === payment.variableSymbol && item.documentType === documentType) {
        return item;
      }
    });

    return document?.documentId ? (
      <div
        key={shortid.generate()}
        onClick={() => payment.variableSymbol && onDownload(payment.variableSymbol, document?.documentId)}
        style={{ cursor: 'pointer' }}
        className="o2-typo--link"
      >
        <O2Icon name="download" indentRight />
        {t('tvsc.paymentList.payType.saveDocument')}
      </div>
    ) : undefined;
  };

  const data = () => {
    function groupArrayOfObjects(list: any, key: any) {
      return list.reduce((rv: any, x: any) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }

    ;

    const groupedPayments = groupArrayOfObjects(payments, 'variableSymbol');

    const data = [];

    for (const varSymbol of Object.keys(groupedPayments)) {
      if (groupedPayments[varSymbol].length === 1) {
        data.push({
          service: groupedPayments[varSymbol][0].productName,
          date: dateFormat(groupedPayments[varSymbol][0].eventCreateTime ?? ''),
          variableSymbol: groupedPayments[varSymbol][0].variableSymbol,
          amountPaid: amountPaid(groupedPayments[varSymbol][0]),
          saveDocument: saveDocument(groupedPayments[varSymbol][0]),
        });
      } else {
        const modalDataPerVarSymbol: any = [];

        groupedPayments[varSymbol].forEach((payment: any) => {
          modalDataPerVarSymbol.push({
            paymentType: payment.paymentType,
            date: dateFormat(payment.eventCreateTime ?? ''),
            amountPaid: amountPaid(payment, true),
            saveDocument: saveDocument(payment),
          });
        });

        data.push({
          service: groupedPayments[varSymbol][0].productName,
          date: '',
          variableSymbol: groupedPayments[varSymbol][0].variableSymbol,
          amountPaid: '',
          saveDocument: false,
          modalData: modalDataPerVarSymbol,
        });
      }
    }

    return data;
  };

  return (
    <>
      <O2Indent>
        <Filter
          onSearch={onFilter}
          maxFromBack={parseInt(maxHistoryMonthInPast)}
          isLoading={loading}
        />
      </O2Indent>
      <O2Indent>
        <Table
          columns={[
            t('tvsc.paymentList.payType.service'),
            t('tvsc.paymentList.payType.date'),
            t('tvsc.paymentList.payType.variableSymbol'),
            t('tvsc.paymentList.payType.amountPaid'),
            t('tvsc.paymentList.payType.saveDocument'),
          ]}
          error={t('tvsc.paymentList.payType.notFound')}
          isLoading={loading}
          data={data()}
        />
      </O2Indent>
      <O2Indent className="o2-typo--align-center">
        <O2Button onClick={onShowMore} disabled={!isPossibleFetchMore}>
          {t('tvsc.paymentList.showMore')}
        </O2Button>
      </O2Indent>
    </>
  );
};

export default PaymentList;
