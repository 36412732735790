import { getUserInfoProtected, getUserInfo } from '@rof/Api/auth';
import { IUserInfoStore } from '@rof/Reducers/user-info/types';
import { setUserInfo } from '@rof/Thunks/userInfo';
import getMsisdnWithoutPrefix from '@rof/Utils/formatters/getMsisdnWithoutPrefix';
import getMsisdnWithSpaces from '@rof/Utils/formatters/getMsisdnWithSpaces';
import IStore from '@Store/types';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';

const useAuth = () => {
  const {
    email,
    firstName,
    internal,
    koId,
    lastName,
    logged,
    msisdn,
    roles,
    subscriberType,
    username,
    isRequested,
    deviceId,
    userDisplayName,
  }: IUserInfoStore = useSelector<IStore, IUserInfoStore>(({ userInfo }) => userInfo, shallowEqual);

  const dispatch = useDispatch();

  const initUserData = (protectedMode = false, logOut? : boolean): void => {
    
    if (!logged || logOut) {
      const getUserData = (protectedMode ? getUserInfoProtected : getUserInfo);
      getUserData()
        .then((res) => {
          dispatch(setUserInfo(res.data));
        }).catch((err) => {
          console.log(`UserInfo is unavailable: ${err.toString()}`);
        });
    }
  };

  const isLoggedIn = (): boolean => {
    return logged;
  };

  const isInternal = (): boolean => {
    return internal ? internal : false;
  };

  const hasRoleByName = (roleName: string): boolean => {
    return roles ? roles?.includes(roleName) : false;
  };

  const hasRolesByRoleNamesArray = (roleNames: string[]): boolean => {
    if (!roles) {
      return false;
    }

    return roleNames.every(roles.includes);
  };

  const getFullName = (): string => {
    return `${firstName ? firstName + ' ': ''}${lastName ? lastName : ''}`;
  };

  //getMsisdnWithPrefix() = +420 777555222
  //getMsisdnWithPrefix('+421', getMsisdnWithSpaces()) = +421 777 555 222
  const getMsisdnWithPrefix = (prefix = '+420', phone = '') => {
    return `${prefix} ${phone ? phone : (msisdn ? msisdn : '')}`;
  };

  const isOTP = () => {
    return !firstName && !lastName && Boolean(deviceId);
  };

  return {
    isLoggedIn: isLoggedIn(),
    isInternal,
    hasRoleByName,
    hasRolesByRoleNamesArray,
    fullName: getFullName(),
    msisdnWithSpaces: getMsisdnWithSpaces(msisdn ?? ''),
    getMsisdnWithPrefix,
    initUserData,
    koId,
    email,
    firstName,
    lastName,
    msisdn,
    deviceId,
    userDisplayName,
    username,
    subscriberType,
    isRequested,
    isOTP: isOTP(),
    getMsisdnWithoutPrefix: () => getMsisdnWithoutPrefix(msisdn || ''),
  };
};

export default useAuth;
