import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import root from './root';
import IStore from './types';

let store: Store<IStore>;

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [ 'codeList' ],
};

const pReducer = persistReducer(persistConfig, root);

//This will inicialy store after calling
export const initializeStore = (): Store<IStore> => {
  store = createStore(pReducer, composeWithDevTools(applyMiddleware(thunk)));
  return store;
};

// store do store things
export const getStore = (): Store<IStore> => {
  return store;
};

export const getPersistor = () => {
  return persistStore(store);
};

export const getState = (): IStore => {
  return store.getState();
};
