import React from 'react';

import { O2Loader } from 'o2-theme-react';

import LoaderWrapper from './styles';

const AppLayout: React.FC = () => {
  return (
    <LoaderWrapper>
      <O2Loader />
    </LoaderWrapper>
  );
};

export default AppLayout;
