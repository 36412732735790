import React, { useState, useEffect } from 'react';

import PasswordReenterModal from '@Components/blocks/PasswordReenterModal';
import Subheader from '@Components/sections/Subheader';
import { ORDER_PIN_RESET_SUCCESS, ORDER_PIN_RESET_FAIL, ORDER_PIN_RESET_INVALID_PASSWORD, ORDER_PIN_RESET_USER_DOES_NOT_EXIST, ORDER_PIN_RESET_USER_WITH_NO_DOMAIN, ORDER_PIN_RESET_USER_SUSPENDED } from '@Constants/kahl';
import useAnalytics from '@Hooks/useAnalytics';
import { postResetPin } from '@rof/Api/bls-sc/resetPin';
import useAuth from '@rof/Hooks/useAuth';
import { setKahlMessageId } from '@rof/Thunks/kahl';

import { O2Layout, O2LayoutColumn, O2Section, O2Indent, O2Button } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IProps } from './types';

const OrderPinReset: React.FC<IProps> = () => {
  const { AAEPageLoaded, AAEModalOpen, AAEFormSubmit } = useAnalytics();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useAuth();
  const [ istPasswordReenterModalOpen, setIstPasswordReenterModalOpen ] = useState(false);

  const errorHandling = (reason?: string) => {
    switch (reason) {
      case 'INVALID_PASSWORD':
        dispatch(setKahlMessageId(ORDER_PIN_RESET_INVALID_PASSWORD));
        break;
      case 'USER_DOES_NOT_EXIST':
        dispatch(setKahlMessageId(ORDER_PIN_RESET_USER_DOES_NOT_EXIST));
        break;
      case 'USER_WITH_NO_DOMAIN':
        dispatch(setKahlMessageId(ORDER_PIN_RESET_USER_WITH_NO_DOMAIN));
        break;
      case 'USER_SUSPENDED' :
        dispatch(setKahlMessageId(ORDER_PIN_RESET_USER_SUSPENDED));
        break;
      default:
        dispatch(setKahlMessageId(ORDER_PIN_RESET_FAIL));
        break;
    } 
  };

  const onConfirm = async(password: string) => {
    try {
      setIstPasswordReenterModalOpen(false);
      const { data } = await postResetPin({ password });
      if (data.success) dispatch(setKahlMessageId(ORDER_PIN_RESET_SUCCESS));
      else errorHandling(data.failureReason);
    } catch (error) {
      errorHandling(error?.response?.data?.reason);
    };
    AAEFormSubmit({ formId: '1.1.2.3.3_009' });
  };

  useEffect(() => {
    AAEPageLoaded({ pageId: '1.1.2.3.3', pageTitle: t('tvsc.orderPin.title')});
  // eslint-disable-next-line
}, []);

  return (
    <>
      <Subheader
        headingText={t('tvsc.orderPin.title')}
        backlinkText={t('tvsc.orderPin.back')}
        pos="center" />
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          <O2Section>
            <O2Indent>
              {t('tvsc.orderPin.description')}
            </O2Indent>
            <O2Indent>
              <O2Button 
                color="primary" 
                onClick={() => {
                  setIstPasswordReenterModalOpen(true);
                  AAEModalOpen({
                    modalId: '1.1.2.3.3_004',
                    modalHeading: t('tvsc.orderPin.modal.title'),
                  });
                }}
              >
                {t('tvsc.orderPin.changeOrderPinToDefault')}
              </O2Button>
            </O2Indent>
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
      <PasswordReenterModal
        title={t('tvsc.orderPin.modal.title')}
        label={t('tvsc.orderPin.modal.loginEmail')}
        buttonTitle={t('tvsc.orderPin.modal.submit')}
        placeholder={t('tvsc.orderPin.modal.password')}
        email={email ?? ''}
        isOpen={istPasswordReenterModalOpen}
        onClose={() => setIstPasswordReenterModalOpen(false)}
        onConfirm={onConfirm} 
      />
    </>
  );
};

export default OrderPinReset;
