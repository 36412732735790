import ActionSetCodeList from '@rof/Redux/actions/codeList';
import { ICodeListProp } from '@rof/Redux/api/code-list/types';
import IStore from '@Store/types';

import { ThunkDispatch } from 'redux-thunk';

export const setCodeList = (codeListName: string, codeListData: ICodeListProp[]) => {
  return async (dispatch: ThunkDispatch<IStore, void, ActionSetCodeList>) => {
    return dispatch({
      type: '[CODE_LIST] SET_ONE',
      data: {
        codeListName,
        codeListData,
      },
    });
  };
};

