import { LOCALE_API_BASE } from '@rof/Constants/api';
import Languages from '@rof/Types/languages';
import { sendGet, sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

export const getLocale = (): AxiosPromise<Languages> => {
  return sendGet<Languages>(LOCALE_API_BASE, undefined, undefined, undefined, undefined, false, true);
};

export const setLocale = (locale: Languages): AxiosPromise<undefined> => {
  return sendPost(LOCALE_API_BASE,null, { locale }, undefined, undefined, true);
};
