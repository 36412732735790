import ActionUserInfo from '@rof/Actions/userInfo';
import { IUserInfo } from '@rof/Api/auth/types';
import IStore from '@Store/types';

import { ThunkDispatch } from 'redux-thunk';

export const setUserInfo = (userInfo: IUserInfo) => {
  return async (dispatch: ThunkDispatch<IStore, void, ActionUserInfo>) => {
    return dispatch({
      type: '[AUTH] SET_USER_INFO',
      data: userInfo,
    });
  };
};

