import React, { useEffect, useState } from 'react';

import PasswordInput from '@Blocks/PasswordInput';
import { IValidation } from '@Blocks/PasswordReenterModal/types';
import {SERVICE_DETAIL} from '@Constants/index';
import { LOGIN_BAD_CREDENTIALS } from '@Constants/kahl';
import useAnalytics from '@Hooks/useAnalytics';
import { login } from '@rof/Api/ncas';
import useAuth from '@rof/Hooks/useAuth';
import useCodeLists from '@rof/Hooks/useCodeLists';
import { setKahlMessageId } from '@rof/Thunks/kahl';
import { handleEnterSubmit } from '@rof/Utils/index';
import { decorateLinkByLeadId } from '@Utils/decorateLinkByLeadId';
import { storeLeadId } from '@Utils/storeLeadId';

import { O2Layout, O2LayoutColumn, O2Section, O2Indent, O2Pane, O2TextField, O2Button } from 'o2-theme-react';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { IProps } from './types';

const Login: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [ password, setPassword ] = useState<string>('');
  const [ username, setUsername ] = useState<string>('');
  const { initUserData, msisdn } = useAuth();
  const [ , setCookie ] = useCookies([ 'jwt' ]);
  const [ , setCookieRefresh ] = useCookies([ 'jwtRefreshToken' ]);
  const [ passwordValidation, setPasswordValidation ] = useState<IValidation>();
  const { returnTo } = queryString.parse(location.search);
  const history = useHistory();
  const dispatch = useDispatch();
  const { getItems } = useCodeLists();
  const [ linkCantLogin, linkOnboarding ] = getItems('redirect.mojeo2.cantlogin', 'redirect.ume.onboarding');
  const { formState, handleSubmit } = useForm();
  const user = useSelector<any, any>((state) => state.userInfo);
  const { AAEPageLoaded, AAEFormSubmit, AAELogin } = useAnalytics();
  const queryParams = queryString.parse(location.search);
  storeLeadId(queryParams);

  useEffect(() => {
    AAEPageLoaded({ pageId: '2.1.3', pageTitle: t('tvsc.login.title') });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (passwordValidation !== undefined) setPasswordValidation(undefined);
    // eslint-disable-next-line
  }, [ password ]);

  if (user.logged) {
    if (returnTo !== undefined && typeof returnTo === 'string') {
      history.push(returnTo);
    } else {
      history.push(SERVICE_DETAIL + `?${queryString.stringify(queryParams)}`);
    }
  }

  const submit = async () => {
    try {
      const res = await login(username, password);
      setCookie('jwt', res.data.jwt, { domain: '.' + window.location.hostname.split('.').slice(-2).join('.'), path: '/'});
      setCookieRefresh('jwtRefreshToken', res.data.refresh_token, { domain: '.' + window.location.hostname.split('.').slice(-2).join('.'), path: '/'});
      initUserData();
      if (returnTo !== undefined && typeof returnTo === 'string') {
        history.push(returnTo);
      } else {
        history.push(SERVICE_DETAIL);
      }
    } catch (error) {
      dispatch(setKahlMessageId(LOGIN_BAD_CREDENTIALS));
    }
    AAEFormSubmit({formId: '2.1.3_005'});
    AAELogin({ user: {userName: username, msisdn: msisdn ? msisdn : ''}});
  };

  return (
    <O2Layout>
      <O2LayoutColumn func="main" pos="narrow">
        <O2Section className="o2-typo--align-center">
          <O2Indent>
            <h1 className="o2-typo--heading">{t('tvsc.login.title')}</h1>
          </O2Indent>
        </O2Section>
        <O2Section>
          <O2Indent>
            <O2Pane>
              <O2Indent>
                <O2TextField
                  placeholder={t('tvsc.login.o2id')}
                  value={username}
                  onChange={(e) => setUsername(e.currentTarget.value)}
                  controlSize="full-width" 
                  onKeyDown={(e: any) => handleEnterSubmit(handleSubmit(submit), e, formState.isSubmitting)}
                />
              </O2Indent>
              <O2Indent>
                <PasswordInput 
                  placeholder={t('tvsc.login.password')}
                  validationText={passwordValidation?.text}
                  validationType={passwordValidation?.type}
                  password={password}
                  onChange={(value: string) => setPassword(value)}
                  onKeyDown={(e: any) => (handleEnterSubmit(handleSubmit(submit), e, formState.isSubmitting))}
                />
              </O2Indent>
              <O2Indent standalone>
                <O2Button color="primary" fullWidth onClick={handleSubmit(submit)} disabled={formState.isSubmitting}>{t('tvsc.login.submit')}</O2Button>
              </O2Indent>
              <O2Indent standalone className="o2-typo--align-center">
                <O2Indent>
                  <a href={decorateLinkByLeadId(linkCantLogin)}>{t('tvsc.login.cantLogin')}</a>
                </O2Indent>
                <O2Indent top="close">
                  <a href={decorateLinkByLeadId(linkOnboarding + encodeURIComponent(window.location.origin + '/no-service'))}>{t('tvsc.login.createAccount')}</a>
                </O2Indent>
              </O2Indent>
            </O2Pane>
          </O2Indent>
        </O2Section>
      </O2LayoutColumn>
    </O2Layout>
  );
};

export default Login;
