import React, { useEffect } from 'react';

import Loader from '@Blocks/Loader';
import Subheader from '@Components/sections/Subheader';
import useAnalytics from '@Hooks/useAnalytics';
import useCodelists from '@rof/Hooks/useCodeLists';
import useDateTime from '@rof/Hooks/useDateTime';
import useMoney from '@rof/Hooks/useMoney';

import {
  O2Layout,
  O2LayoutColumn,
  O2Section,
  O2Indent,
  O2Field,
  O2FieldContent,
  O2Button,
  O2Linkbox,
  O2Wrap,
  O2WrapItem,
  O2Icon,
  O2Pane,
} from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useServiceDetailData from './module/useServiceDetailData';
import { IProps } from './types';
import { periodStringsToDaysNumber } from '@Utils/utils';

const ServiceDetail: React.FC<IProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { dateFormat } = useDateTime();
  const { moneyFormat } = useMoney();
  const { getItems } = useCodelists();
  const [ prepaidO2TVTariffs ] = getItems('redirect.o2.prepaidO2TVTariffs');
  const { AAEPageLoaded } = useAnalytics();

  useEffect(() => {
    AAEPageLoaded({ pageId: '1.1.1.1', pageTitle: t('tvsc.serviceDetail.title')});
    // eslint-disable-next-line
  }, []);

  //const [ funnelHistory, createTicketById ] = getItems('redirect.funnel.history', 'redirect.funnel.createTicketById');
  const [ createTicketById ] = getItems('redirect.funnel.createTicketById');
  const {
    data,
    error,
    isCanceledSubscription,
    isWithoutService,
    arePrerequisitiesLoaded,
    service,
    errorUserServices,
  } = useServiceDetailData();

  useEffect(() => {
    if (isWithoutService || errorUserServices || error) {
      history.push('/no-service');
    }
    // eslint-disable-next-line
  }, [ isWithoutService, errorUserServices, error ]);

  const description = () => {
    return (
      data?.serviceDescription &&
        data?.serviceDescription?.split(',').map((item, i) => (
          <O2Indent top="close" key={i}>
            <O2Icon name="checkmark" indentRight />
            {item}
          </O2Indent>
        ))
    );
  };

  const priceFormat = (price?: number, period?: number, suffif?: string) => {
    return price !== undefined
      ? period
        ? t('tvsc.serviceDetail.priceWithPeriod', {
          price: moneyFormat(price, undefined, undefined, suffif),
          period: period,
        })
        : t('tvsc.serviceDetail.price', {
          price: moneyFormat(price, undefined, undefined, suffif),
        })
      : t('tvsc.serviceDetail.infoUnknown');
  };

  const tariffInfo = () => {
    if (data?.failureDetail === 'NO_TV_PRODUCT_FOUND' || data?.failureDetail === 'NO_TARIFF_DETAIL' || error !== undefined) {
      return (
        <O2Section>
          <O2Pane>
            <div className="o2-loader o2-loader--noresults">
              <span className="o2-loader__element"></span>
              <span className="o2-loader__text">{t('tvsc.serviceDetail.noServiceChoose')} <a href={prepaidO2TVTariffs}>{t('tvsc.serviceDetail.tariffOfferLink')}</a>.</span>
            </div>
          </O2Pane>
        </O2Section>
      );
    } else {
      return (
        <>
          <O2Section>
            <O2Indent>
              <h2 className="o2-typo--heading">{t('tvsc.serviceDetail.serviceDescription')}</h2>
            </O2Indent>
            <O2Indent>{description()}</O2Indent>
            <O2Indent>
              <O2Button onClick={() => window.location.href = prepaidO2TVTariffs} icon="pen-fill">
                {t('tvsc.serviceDetail.changeService')}
              </O2Button>
            </O2Indent>
          </O2Section>
          {/* service information */}
          <O2Section>
            <O2Indent>
              <h2 className="o2-typo--heading">{t('tvsc.serviceDetail.serviceInformation')}</h2>
            </O2Indent>
            <O2Indent>
              {!data?.recurrence && (
                <O2Field label={t('tvsc.serviceDetail.paidUntil')}>
                  <O2FieldContent>
                    {data?.paidValidToDate
                      ? dateFormat(data?.paidValidToDate)
                      : t('tvsc.serviceDetail.infoUnknown')}
                  </O2FieldContent>
                </O2Field>
              )}
              {data?.recurrenceTerminated && (
                <O2Field label={t('tvsc.serviceDetail.recurrenceTerminated')} />
              )}
              {!data?.recurrenceTerminated && (
                <>
                  {data?.recurrence && (
                    <O2Field label={t('tvsc.serviceDetail.nextPayment')}>
                      {(data?.canceledSubscriptionDescription || isCanceledSubscription) ? (
                        <O2FieldContent>
                          {t('tvsc.serviceDetail.canceledSubscriptionTitle')}
                        </O2FieldContent>
                      ) : (
                        <O2FieldContent>
                          {data?.nextPaymentDate
                            ? dateFormat(data?.nextPaymentDate)
                            : t('tvsc.serviceDetail.infoUnknown')}
                        </O2FieldContent>
                      )}
                    </O2Field>
                  )}
                </>
              )}
              <O2Field label={t('tvsc.serviceDetail.servicePrice')}>
                <O2FieldContent>
                  {priceFormat(
                    data?.servicePrice, 
                    data?.servicePeriod ? periodStringsToDaysNumber(data?.servicePeriod) : 0,
                    'CZK'
                  )}
                </O2FieldContent>
              </O2Field>
              {!data?.recurrenceTerminated && (
                <O2Field label={t('tvsc.serviceDetail.nextPaymentDiscount')}>
                  <O2FieldContent>
                    {priceFormat(data?.serviceDiscountNextPrice, undefined, 'CZK')}
                  </O2FieldContent>
                </O2Field>  
              )}
              {!data?.recurrenceTerminated && (
                <>
                  {data?.recurrence && (
                    <O2Field label={t('tvsc.serviceDetail.amountOfFollowingPayment')}>
                      <O2FieldContent>
                        {priceFormat(data?.nextPaymentPrice, undefined, 'CZK')}
                      </O2FieldContent>
                    </O2Field>
                  )}
                </>  
              )}
              
            </O2Indent>
          </O2Section>
        </>
      );
    }
  };

  const serviceName = () => {
    if (data?.serviceName) return data?.serviceName;
    return !arePrerequisitiesLoaded ? '' : `${t('tvsc.serviceDetail.headingText.withoutService')}`;
  };

  return (
    <>
      <Subheader
        headingText={serviceName()}
        backlinkText={t('tvsc.serviceDetail.back')}
        perex={service?.serviceId}
        pos="center"
      />
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          {!arePrerequisitiesLoaded && (<Loader />)}
          {arePrerequisitiesLoaded && !isWithoutService && (
            <>
              {tariffInfo()}
              <O2Section>
                <O2Indent>
                  <div className="o2-btn-group">
                    <O2Button onClick={() => history.push('/payments')} icon="coins" indentRight>
                      {t('tvsc.serviceDetail.historyAndSetPayment')}
                    </O2Button>
                  </div>
                </O2Indent>
              </O2Section>
              {/* settings */}
              <O2Section>
                <O2Indent>
                  <h2 className="o2-typo--heading">{t('tvsc.serviceDetail.settings')}</h2>
                </O2Indent>
                <O2Indent>
                  <O2Linkbox style={{ cursor: 'pointer' }} onClick={() => history.push('/order-pin-reset')} >
                    <O2Indent>
                      <b>{t('tvsc.serviceDetail.resetOrderPIN')}</b>
                    </O2Indent>
                    <O2Indent top="tight">
                      {t('tvsc.serviceDetail.resetOrderPINDescription')}
                    </O2Indent>
                  </O2Linkbox>
                  <O2Linkbox style={{ cursor: 'pointer' }} onClick={() => history.push('/parent-lock-reset')} >
                    <O2Indent>
                      <b>{t('tvsc.serviceDetail.resetParentCode')}</b>
                    </O2Indent>
                    <O2Indent top="tight">
                      {t('tvsc.serviceDetail.resetParentCodeDescription')}
                    </O2Indent>
                  </O2Linkbox>
                </O2Indent>
              </O2Section>
              <O2Section>
                <O2Indent>
                  <h2 className="o2-typo--heading">{t('tvsc.serviceDetail.funnel')}</h2>
                </O2Indent>
                <O2Indent>
                  <O2Wrap columns={2}>
                    <O2WrapItem>
                      <O2Linkbox href={createTicketById + service?.serviceId}>{t('tvsc.serviceDetail.funnelCreateTicket')}</O2Linkbox>
                    </O2WrapItem>
                    {/*<O2WrapItem>
                      <O2Linkbox href={funnelHistory}>{t('tvsc.serviceDetail.historyOfIssues')}</O2Linkbox>
                    </O2WrapItem>*/}
                  </O2Wrap>
                </O2Indent>
              </O2Section>
            </>
          )}
        </O2LayoutColumn>
      </O2Layout>
    </>
  );
};

export default ServiceDetail;
