import ActionsGetServiceDetail from '@rof/Actions/getServiceDetail';
import { GetServiceDetailResponse } from '@rof/Api/bls-sc/types';

import { IState } from '../types';

const init: IState<GetServiceDetailResponse> = {
  data: undefined,
  isLoading: false,
  error: undefined,
  lastFetched: undefined,
};

const getServiceDetailReducer = (state: IState<GetServiceDetailResponse> = init, action: ActionsGetServiceDetail) => {
  switch (action.type) {
    case '[BLS-SC - getServiceDetail] START':
      return {
        ...state,
        isLoading: true,
      };
    case '[BLS-SC - getServiceDetail] SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case '[BLS-SC - getServiceDetail] ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: return state;
  }
};

export default getServiceDetailReducer;
