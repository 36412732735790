import React, {useEffect} from 'react';

import useAnalytics from '@Hooks/useAnalytics';

import { O2Layout, O2LayoutColumn, O2Section, O2Pane, O2Addon, O2AddonContent, O2Indent, O2Button } from 'o2-theme-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import usePaymentWaitingData from './module/usePaymentWaitingData';
import BottomWrapper from './styles';
import { IProps } from './types';

const PaymentWaiting: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { data, retryPayment, onActiveDifferent, onRetryPayment } = usePaymentWaitingData();
  const { formState, handleSubmit } = useForm();
  const { AAEPageLoaded } = useAnalytics();

  useEffect(() => {
    AAEPageLoaded({ pageId: '2.1.6', pageTitle: t('tvsc.unstandardSituation.title')});
  // eslint-disable-next-line
}, []);

  if (retryPayment?.failureReason === 'MAX_ATTEMPTS_EXCEEDED') {
    return (
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          <O2Section>
            <O2Pane color="warning">
              <O2Addon reversed>
                <O2AddonContent>
                  <span className="o2-typo--heading-h3">
                    {t('tvsc.unstandardSituation.title', { service: data?.serviceName })}
                  </span>
                </O2AddonContent>
              </O2Addon>
            </O2Pane>
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
    ); 
  };

  return (
    <O2Layout>
      <O2LayoutColumn func="main" pos="center">
        <O2Section>
          <O2Pane color="warning">
            <O2Addon reversed>
              <O2AddonContent>
                <span className="o2-typo--heading-h3">
                  {t('tvsc.unstandardSituation.title', { service: data?.serviceName })}
                </span>
              </O2AddonContent>
            </O2Addon>
          </O2Pane>
        </O2Section>
        <O2Section className="o2-typo--align-center">
          <O2Indent>
            {t('tvsc.unstandardSituation.description')}
          </O2Indent>
          <O2Indent>
            <O2Button onClick={handleSubmit(onRetryPayment)} disabled={formState.isSubmitting}>{t('tvsc.unstandardSituation.repeatPayment')}</O2Button>
          </O2Indent>
          <O2Indent>
            <BottomWrapper>
              {t('tvsc.unstandardSituation.orYouCan')}
              <div 
                onClick={onActiveDifferent}
                style={{ cursor: 'pointer' }} 
                className="o2-typo--link"
              >
                {t('tvsc.unstandardSituation.activeDifferent', { oldService: data?.serviceName })}
              </div>
            </BottomWrapper>
          </O2Indent>
        </O2Section>
      </O2LayoutColumn>
    </O2Layout>
  ); 
};

export default PaymentWaiting;
