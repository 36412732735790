const isSlugDeeplink = (): boolean => {
  if (
    window.location.pathname.startsWith('/sc/acttv') 
    || window.location.pathname.startsWith('/sc/actmovie')
  ) {
    return true;
  }
  return false;
};

export default isSlugDeeplink;
