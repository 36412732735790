import React, {useEffect} from 'react';

import CreditCard from '@Components/blocks/CreditCard';
import PaymentsList from '@Components/sections/PaymentsList';
import Subheader from '@Components/sections/Subheader';
import useAnalytics from '@Hooks/useAnalytics';
import useCodelists from '@rof/Hooks/useCodeLists';
import useDateTime from '@rof/Hooks/useDateTime';

import { format } from 'date-fns';
import { O2Indent, O2Layout, O2Section, O2LayoutColumn } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useServiceDetailData from './../ServiceDetail/module/useServiceDetailData';
import ConfirmModal from './ConfirmModal';
import usePaymentsData from './module/usePaymentsData';
import { IProps } from './types';

const Payments: React.FC<IProps> = () => {
  const { AAEPageLoaded, AAEModalOpen } = useAnalytics();
  const { t } = useTranslation();
  const { getItems } = useCodelists();
  const { dateFormat } = useDateTime();
  const [ maxHistoryMonthInPast ] = getItems('bff.common.maxHistoryMonthInPast');
  const {
    data,
    isLoading,
    isPossibleFetchMore,
    fetchMoreGetBalanceHistoryData,
    filterGetBalanceHistoryData,
    filterGetFinancialDocumentListData,
    downloadDocument,
  } = usePaymentsData();

  const {
    data: dataDetail,
    isLoading: isServiceDetailLoading,
    isOpenconfirmModal,
    setIsOpenconfirmModal,
    onConfirmModal,
    isCanceledSubscriptionSuccess,
  } = useServiceDetailData();

  const dateWithoutTime = (dateTime?: string) => {
    if (!dateTime) return dateTime;
    return format(new Date(dateTime), 'yyyy-MM-dd');
  };

  const filterDocuments = (dateFrom?: string, dateTo?: string) => {
    if (dateFrom === undefined) {
      const helpDate = new Date();
      const date = new Date(helpDate.getTime() - (helpDate.getTimezoneOffset() * 60000));
      date.setMonth(date.getMonth()-maxHistoryMonthInPast);
      dateFrom = date.toISOString();
    }

    if (dateTo === undefined) {
      const helpDate = new Date();
      const date = new Date(helpDate.getTime() - (helpDate.getTimezoneOffset() * 60000));
      dateTo = date.toISOString();
    }

    filterGetBalanceHistoryData(dateFrom, dateTo);
    filterGetFinancialDocumentListData(dateWithoutTime(dateFrom), dateWithoutTime(dateTo));
  };

  const isCanceledSubscriptionBtn = () => {
    if (dataDetail?.recurrenceTerminated || isCanceledSubscriptionSuccess) return false;
    return true;
  };

  const isWithoutService = () => {
    if (
      (
        dataDetail?.failureDetail === 'NO_TV_PRODUCT_FOUND' || 
        dataDetail?.failureDetail === 'NO_TARIFF_DETAIL'
      ) && !isServiceDetailLoading
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    AAEPageLoaded({ pageId: '1.1.2.2', pageTitle: t('tvsc.payments.title')});
  // eslint-disable-next-line
}, []);

  return (
    <>
      <Subheader headingText={t('tvsc.payments.title')} backlinkText={t('tvsc.payments.back')} pos="center" />
      <O2Layout>
        <O2LayoutColumn pos="center">
          <O2Section>
            {data.getCardDetailData?.cardNumber && (
              <>
                <O2Indent>
                  <h2 className="o2-typo--heading">{t('tvsc.payments.paymentCard')}</h2>
                </O2Indent>
                <O2Indent>
                  <CreditCard 
                    type={data.getCardDetailData?.cardProvider ?? ''} 
                    number={data.getCardDetailData?.cardNumber} 
                    valid={data.getCardDetailData?.cardValidDate ?? ''} 
                  />
                </O2Indent>
              </>
            )}
            <O2Indent>
              <a href="/change-payment-card">{t('tvsc.payments.changePaymentCard')}</a>
            </O2Indent>
          </O2Section>
          <O2Section>
            <O2Indent>
              <h2 className="o2-typo--heading">{t('tvsc.payments.paymentHistory')}</h2>
            </O2Indent>
            {data.getBalanceHistoryData?.xtvPaymentEvents &&
              <PaymentsList 
                payments={data.getBalanceHistoryData?.xtvPaymentEvents ?? []}
                documents={data.cpGetFinancialDocumentListData?.financialDocumentBasicList ?? []}
                loading={isLoading.getBalanceHistoryIsLoading || isLoading.cpGetFinancialDocumentListLoading}
                isPossibleFetchMore={isPossibleFetchMore}
                onShowMore={fetchMoreGetBalanceHistoryData}
                onFilter={filterDocuments}
                onDownload={downloadDocument}
              />
            }
            <O2Indent>
              {t('tvsc.payments.viewLimit')}
            </O2Indent>
          </O2Section>
          {!isWithoutService() && (
            <>
              {isCanceledSubscriptionBtn() && (
                <O2Section>
                  <O2Indent><Link to="/submit-coupon">{t('tvsc.payments.applyVoucher')}</Link></O2Indent>
                  <O2Indent><span className="o2-typo--link" onClick={() => {
                    setIsOpenconfirmModal(true); AAEModalOpen({
                      modalId: '1.1.2.2_018',
                      modalHeading: t('tvsc.serviceDetail.modalCancelSubscription.title'),
                    });
                  }}>{t('tvsc.payments.cancelPaymentForNextMonth')}</span></O2Indent>
                </O2Section>
              )}
            </>
          )}
        </O2LayoutColumn>
      </O2Layout>
      <ConfirmModal
        paidValidToDate={
          dataDetail?.paidValidToDate ? dateFormat(dataDetail?.paidValidToDate) : t('tvsc.serviceDetail.infoUnknown')
        }
        isOpen={isOpenconfirmModal}
        onCancel={() => setIsOpenconfirmModal(false)}
        onConfirm={onConfirmModal}
      />
    </>
  );
};

export default Payments;
