import React, { useState } from 'react';

import Loader from '@Components/blocks/Loader';
import useAppInit from '@Hooks/useAppInit';
import RootLayout from '@rof/Layouts/Root';
import GlobalStyle from '@rof/Utils/globalStyle';

import Footer from './Footer';
import Header from './Header';
import IProps from './types';

const AppLayout: React.FC<IProps> = ({ children }: IProps) => {
  const { isReadyToRender } = useAppInit();
  const [ showLoader, setShowLoader ] = useState<boolean>(false); 
  const webview = window.localStorage.getItem('webview');

  if (!isReadyToRender || showLoader) return <Loader />;
  
  return (
    <RootLayout>
      <GlobalStyle />
      {webview !== 'true' &&
        <Header />
      }
      {children}
      {webview !== 'true' &&
        <Footer setLoader={setShowLoader} />
      }
    </RootLayout>
  );
};

export default AppLayout;
