import { sendPut } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { RetryPaymentRequest, RetryPaymentResponse } from '../types';

export const putRetryPayment = (
  params: RetryPaymentRequest
): AxiosPromise<RetryPaymentResponse> => {
  return sendPut<RetryPaymentResponse>(
    `/bsl-payment-api/public/v1/orders/retryPayment/${params.orderId}`,
  );
};
