import { useState, useEffect } from 'react';

import { CHANGE_SERVICE_FAIL } from '@Constants/kahl/index';
import useAnalytics from '@Hooks/useAnalytics';
import useCheckProduct from '@Modules/checkProduct/useCheckProduct';
import useGetServiceDetail from '@Modules/getServiceDetail/useGetServiceDetail';
import { postCheckOrderPin } from '@rof/Api/bls-sc/checkOrderPin';
import useReduxLoggerActions from '@rof/Hooks/useReduxLoggerActions';
import { updateCreateOrderContext } from '@rof/Thunks/createOrderContext';
import { setKahlMessageId } from '@rof/Thunks/kahl';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
const useChangeServiceData = (id: string, voucherCode?: string) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { reduxLog } = useReduxLoggerActions();
  const [ isPinConfirmModalOpen, setIsPinConfirmModalOpen ] = useState(false);
  const { 
    init: getServiceDetailInit, 
    data: getServiceDetailData, 
    isLoading: getServiceDetailIsLoading } = useGetServiceDetail();
  const { 
    init: checkProductInit, 
    data: checkProductData, 
    isLoading: checkProductIsLoading } = useCheckProduct();
  const { AAEFormSubmit } = useAnalytics();

  useEffect(() => {
    getServiceDetailInit(true);
    checkProductInit(true, id, voucherCode);
    // eslint-disable-next-line
  }, []);

  const submitChangeService = (pin: string) => {
    setIsPinConfirmModalOpen(false);

    reduxLog('postCheckOrderPin START');
    postCheckOrderPin({ pin })
      .then(({ data }) => {
        if (data.success) {
          dispatch(updateCreateOrderContext({ orderPin: pin }));
          history.push(`/payment/${id}` + (voucherCode ? `/${voucherCode}` : ''));
        } else dispatch(setKahlMessageId(CHANGE_SERVICE_FAIL));
      })
      .catch(() => {
        reduxLog('postCheckOrderPin FAIL');
        dispatch(setKahlMessageId(CHANGE_SERVICE_FAIL));
      });
    AAEFormSubmit({formId: '2.1.5_014'});
  };

  return {
    data: { getServiceDetailData, checkProductData },
    isLoading: { getServiceDetailIsLoading, checkProductIsLoading },
    submitChangeService,
    isPinConfirmModalOpen,
    setIsPinConfirmModalOpen,
  };
};

export default useChangeServiceData;
