import {
  CommonLocaleInfo,
  CustomerMasterSourceType,
  OrderChannelType,
  CommonUnitLocale,
} from '../types';

export interface BalanceHistoryResponse {
  balanceAdjustmentEvents: BalanceAdjustmentEvent[];
  chargingEvents: ChargingEvent[];
  chargingSums: ChargingSummary[];
  xtvPaymentEvents: XTvPaymentEvent[];
  totalItems: number;
  totalPages: number;
}

export interface BalanceHistoryRequest {
  customerMasterSource: CustomerMasterSourceType;
  eventTimeFrom?: string;
  eventTimeTo?: string;
  channel: OrderChannelType;
  msisdn?: string;
  deviceId?: string;
  page?: number;
  pageSize?: number;
  paymentType?: BalanceHistoryPaymentType;
  paymentStatus?: BalanceHistoryPaymentStatusType;
  requiredDataSetType?: BalanceHistoryDataSetType;
  sumAggregationType?: BalanceHistorySumAggregationType;
  suRefNo?: string;
  usageFilterType?: BalanceHistoryUsageFilterType;
}

export interface BalanceAdjustmentEvent {
  amount: string;
  bonusAmount?: string;
  currency: string;
  eventTime: string;
  eventType: string;
  topupMsisdn?: string;
  currencyLocale: CommonLocaleInfo;
  eventTypeLocale: CommonLocaleInfo;
  language: string;
  recordValidity: string;
}

export interface ChargingEvent {
  amount: string;
  currency: string;
  duration: string;
  durationCharged: string;
  eventTime: string;
  eventType: string;
  unit: ChargingEventDurationUnit;
  eventTypeLocale: CommonLocaleInfo;
  unitLocale: CommonUnitLocale;
}

export interface ChargingSummary {
  currency: string;
  eventPeriodFrom: string;
  eventPeriodTo: string;
  sumAmount: number;
  currencyLocale: CommonLocaleInfo;
  language: string;
  recordValidity: string;
}
export interface XTvPaymentEvent {
  eventCreateTime: string;
  eventCancelTime?: string;
  externalHouseholdId: string;
  productName: string;
  paymentType: string;
  productType: string;
  finalPrice?: number;
  originalPrice?: number;
  discount?: number;
  refundPrice?: number;
  paymentStatus: string;
  currency: string;
  paymentId?: string;
  refundPaymentId?: string;
  variableSymbol?: string;
  voucherId?: string;
  language: string;
  recordValidity: string;
  productCode: string;
  voucherCode?: string;
  currencyLocale: CommonLocaleInfo;
}

export enum BalanceHistoryPaymentStatusType {
  APPROVED='APPROVED',
  REFUSED='REFUSED',
  EXPIRED='EXPIRED',
  FAILED='FAILED',
  FINISHED='FINISHED',
  REQUEST='REQUEST',
}

export enum BalanceHistoryPaymentType {
  ONE_TIME_PAY='ONE_TIME_PAY',
  RECURRENT_PAY='RECURRENT_PAY',
  REFUND='REFUND',
  REQUEST='REQUEST',
}

export enum ChargingEventDurationUnit {
  SECOND='SECOND',
  EVENT='EVENT',
  KB='KB',
}

export enum BalanceHistoryUsageFilterType {
  ONNET_CALL='ONNET_CALL',
  OFFNET_CALL='OFFNET_CALL',
  SMS='SMS',
  MMS='MMS',
  DATA='DATA',
  VAS_PSMS='VAS_PSMS',
  ROAMING='ROAMING',
  INT_CALL='INT_CALL',
  FEES='FEES',
  EXPIRY='EXPIRY',
  ALL = 'ALL',
  OTHER = 'OTHER',
}

export enum BalanceHistorySumAggregationType {
  CALENDAR_MONTH='CALENDAR_MONTH',
}

export enum BalanceHistoryDataSetType {
  BALANCE_ADJUSTMENT_EVENTS = 'BALANCE_ADJUSTMENT_EVENTS',
  CHARGING_EVENTS = 'CHARGING_EVENTS',
  CHARGING_SUMMARY = 'CHARGING_SUMMARY',
  XTV_PAYMENT_EVENTS = 'XTV_PAYMENT_EVENTS',
}
