import { sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { ResetPinLockRequestBody, ResetPinLockResponse } from '../types';

export const postResetPin = (
  requestBody: ResetPinLockRequestBody
): AxiosPromise<ResetPinLockResponse> => {
  return sendPost<ResetPinLockResponse>(
    '/bsl-sc-api/public/v1/subscription/resetPin',
    null,
    requestBody
  );
};
