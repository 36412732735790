import {LOGIN_URL, PAYMENTS, SERVICE_DETAIL} from '@Constants/index';
import AppLayout from '@Layouts/App';
import ChangePaymentCard from '@Pages/ChangePaymentCard';
import ChangeService from '@Pages/ChangeService';
import Actmovie from '@Pages/Deeplink/Actmovie';
import Acttv from '@Pages/Deeplink/Acttv';
import PaymentsDeeplink from '@Pages/Deeplink/Payments';
import ServiceDetailDeeplink from '@Pages/Deeplink/ServiceDetail';
import Login from '@Pages/Login';
import NoService from '@Pages/NoService';
import OrderPinReset from '@Pages/OrderPinReset';
import ParentLockReset from '@Pages/ParentLockReset';
import Payment from '@Pages/Payment';
import PaymentResult from '@Pages/PaymentResult';
import Payments from '@Pages/Payments';
import PaymentWaiting from '@Pages/PaymentWaiting';
import RedirectToPaymentGate from '@Pages/RedirectToPaymentGate';
import ServiceDetail from '@Pages/ServiceDetail';
import SubmitCoupon from '@Pages/SubmitCoupon';
import Page404 from '@rof/Pages/404';
import Homepage from '@rof/Pages/Homepage';

type Route = {
  layout: React.FC<any>;
  subRoutes: SubRoute[];
};

type SubRoute = {
  exact: boolean;
  path: string;
  component: React.FC<any>;
};

const routes: Route[] = [
  {
    layout: AppLayout,
    subRoutes: [
      {
        exact: true,
        path: '/',
        component: Homepage,
      },
      {
        exact: true,
        path: '/404',
        component: Page404,
      },
      {
        exact: true,
        path: '/change-payment-card',
        component: ChangePaymentCard,
      },
      {
        exact: true,
        path: '/change-service/:id?/:voucherCode?',
        component: ChangeService,
      },
      {
        exact: true,
        path: '/order-pin-reset',
        component: OrderPinReset,
      },
      {
        exact: true,
        path: '/parent-lock-reset',
        component: ParentLockReset,
      },
      {
        exact: true,
        path: '/payment/:id/:voucherCode?',
        component: Payment,
      },
      {
        exact: true,
        path: '/payment-result',
        component: PaymentResult,
      },
      {
        exact: true,
        path: PAYMENTS,
        component: Payments,
      },
      {
        exact: true,
        path: '/payment-waiting',
        component: PaymentWaiting,
      },
      {
        exact: true,
        path: SERVICE_DETAIL,
        component: ServiceDetail,
      },
      {
        exact: true,
        path: '/submit-coupon',
        component: SubmitCoupon,
      },
      {
        exact: true,
        path: '/no-service',
        component: NoService,
      },
      {
        exact: true,
        path: '/redirecting-for-payment',
        component: RedirectToPaymentGate,
      },
      {
        exact: true,
        path: '/sc/acttv',
        component: Acttv,
      },
      {
        exact: true,
        path: '/sc/actmovie',
        component: Actmovie,
      },
      {
        exact: true,
        path: '/sc/service-detail',
        component: ServiceDetailDeeplink,
      },
      {
        exact: true,
        path: '/sc/payments',
        component: PaymentsDeeplink,
      },
      {
        exact: false,
        path: LOGIN_URL,
        component: Login,
      },
    ],
  },
];

export default routes;
