import React, { useEffect, useState } from 'react';

import useOrderDetail from '@Modules/orderDetail/useOrderDetail';
import { storeLeadId } from '@Utils/storeLeadId';

import { O2Loader } from 'o2-theme-react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

const withPaymentContext = <P extends object>(Component: React.ComponentType<P>, notForceOnSuccess = false) => (props: P) => { 
  const history = useHistory();
  const { init, data, error } = useOrderDetail();
  const [ isRequestedOrderDetailData, setIsRequestedOrderDetailData ] = useState(false);
  const [ isReadyToRender, setIsReadyToRender ] = useState(false);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  storeLeadId(queryParams);

  useEffect(() => {
    init(true);
    setIsRequestedOrderDetailData(true);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data && isRequestedOrderDetailData) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      checkOrderStatusRedirects();
    }
    // eslint-disable-next-line
  }, [ data ])

  useEffect(() => {
    if (isRequestedOrderDetailData) {
      if (error?.response?.status === 404 || error === 'No order') {
        setIsReadyToRender(true);
      } 
    }
    // eslint-disable-next-line
  }, [ error ])

  const checkOrderStatusRedirects = () => {
    switch (data?.orderStatus) {
      case 'WAIT_FOR_PAYMENT':
        history.push('/payment-waiting' + queryParams ? `?${queryString.stringify(queryParams)}` : '');
        break;
      case 'CREATED':
      case 'IN_PROVISIONING':
        history.push('/payment-result' + queryParams ? `?${queryString.stringify(queryParams)}` : '');
        break;
      case 'FAILED_WITHOUT_REFUND':
      case 'FAILED_WITH_REFUND':
      case 'SUCCESS':
        if (!notForceOnSuccess) {
          history.push('/payment-result' + queryParams ? `?${queryString.stringify(queryParams)}` : '');
        } else {
          setIsReadyToRender(true);
        }
        break;
      default:
        setIsReadyToRender(true);
        console.log('No orderStatus with need of redirect');
    }
  };

  return isReadyToRender ? (
    <Component {...(props as P)} />
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <O2Loader />
    </div>
  );
};

export default withPaymentContext;
