import { CMS_RESOURCE_API_BASE } from '@rof/Constants/api';
import { APP_ID } from '@rof/Constants/index';
import { sendGet } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { IResource } from './types';

export const getLangTexts = (): AxiosPromise<IResource> => {
  return sendGet<IResource>(`${CMS_RESOURCE_API_BASE}/LANGTEXT/${APP_ID}`, undefined, undefined, undefined, undefined, false, true);
};

export const getAllHTMLTemplates = (): AxiosPromise<IResource> => {
  return sendGet<IResource>(`${CMS_RESOURCE_API_BASE}/HTML_TEMPLATE/${APP_ID}`, undefined, undefined, undefined, undefined, false, true);
};

export const getHTMLTemplateByResourceKey = (resourceKey: string): AxiosPromise<IResource> => {
  return sendGet<IResource>(`${CMS_RESOURCE_API_BASE}/HTML_TEMPLATE/${APP_ID}${resourceKey}`, undefined, undefined, undefined, undefined, false, true);
};
