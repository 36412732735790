import React, {useEffect} from 'react';

import Subheader from '@Components/sections/Subheader';
import useAnalytics from '@Hooks/useAnalytics';

import { O2Layout, O2LayoutColumn} from 'o2-theme-react';
import { useTranslation } from 'react-i18next';

import InvalidVoucher from './InvalidVoucher';
import useSubmitCouponData from './module/useSubmitCouponData';
import { IProps } from './types';
import ValidVoucher from './ValidVoucher';

const SubmitCoupon: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { voucherData, hasValidVoucher, submitVoucher, setVoucherCode, setVoucherValidation, voucherValidation } = useSubmitCouponData();
  const { AAEPageLoaded } = useAnalytics();

  useEffect(() => {
    AAEPageLoaded({ pageId: '1.1.2.4', pageTitle: t('tvsc.applyVoucher.title') });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Subheader backlinkText={t('tvsc.applyVoucher.back')} headingText={t('tvsc.applyVoucher.title')} pos="center" />
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          {!hasValidVoucher ? (
            <>
              <InvalidVoucher 
                validationReason={voucherValidation?.type}
                validationDetail={voucherValidation?.text}
                setVoucherCode={(e: any) => { 
                  setVoucherCode(e.target.value);
                  setVoucherValidation(undefined);
                }}
                submitVoucher={submitVoucher}
              />
            </> 
          ) : (
            <ValidVoucher
              voucherDescription={voucherData?.voucherDescription}
            />
          )}
        </O2LayoutColumn>
      </O2Layout>
    </>
  );
};

export default SubmitCoupon;
