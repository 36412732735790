import { useEffect, useState } from 'react';

import { SUBMIT_COUPON_VOUCHER_FAIL ,SUBMIT_COUPON_VOUCHER_CODE_MISSING } from '@Constants/kahl';
import useAnalytics from '@Hooks/useAnalytics';
import { postApplyVoucher } from '@rof/Api/bls-sc/applyVoucher';
import { ApplyVoucherResponse } from '@rof/Api/bls-sc/types';
import { setKahlMessageId } from '@rof/Thunks/kahl';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IType, IValidation } from '../types';

const useSubmitCouponData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ voucherCode, setVoucherCode ] = useState('');
  const [ voucherData, setVoucherData ] = useState<ApplyVoucherResponse>();
  const [ voucherValidation, setVoucherValidation ] = useState<IValidation>();
  const [ hasValidVoucher, setHasValidVoucher ] = useState(false);
  const { /*updateTMPVoucherData,*/ AAEFormSubmit } = useAnalytics();

  useEffect(() => {
    if (voucherData !== undefined) setVoucherData(undefined);
    // eslint-disable-next-line
  }, [ voucherCode ]);

  const validateVoucher = () => {
    if (voucherCode.length) return true;
    return false;
  };

  const getFailureReason = (failureReason: any) => {
    let reason;
    switch (failureReason) {
      case 'VOUCHER_NOT_VALID':
        reason = t('global.error.voucherNotValid');
        break; 
      case 'VOUCHER_INACTIVE':
        reason = t('global.error.voucherInactive');
        break;
      case 'VOUCHER_EXPIRED':
        reason = t('global.error.voucherExpired');
        break;
      case 'VOUCHER_ALREADY_USED':
        reason = t('global.error.voucherAlreadyUsed');
        break;
      case 'VOUCHER_NOT_EXIST':
        reason = t('global.error.voucherNotExist');
        break;
      case 'CANNOT_GET_CURRENT_TARIFF':
        reason = t('global.error.voucherCannotGetCurrentTariff');
        break;
      case 'INCOMPATIBLE_WITH_TARIFF':
        reason = t('global.error.voucherIncompatibleWithTariff');
        break;
      default:
        reason = t('global.error.voucherNotValid');
        break;
    }
    return reason;
  };

  const submitVoucher = async () => {
    if (validateVoucher()) {
      try {
        const { data } = await postApplyVoucher({ voucherCode });
        if (data.success) {
          setHasValidVoucher(true);
          setVoucherData(data);
          /*updateTMPVoucherData({
            voucherCode: voucherCode,
            displayName: data.voucherDescription,
          });*/
        } else {
          setVoucherValidation({
            text: getFailureReason(data.failureReason),
            type: 'error' as IType,
          }); 
        } 
        setVoucherData(data);
      } catch (error) {
        dispatch(setKahlMessageId(SUBMIT_COUPON_VOUCHER_FAIL));
        setVoucherValidation({
          text: t('global.error.voucherNotValid'),
          type: 'error' as IType,
        }); 
      }
    } else dispatch(setKahlMessageId(SUBMIT_COUPON_VOUCHER_CODE_MISSING));
    AAEFormSubmit({formId: '1.1.2.4_006'});
  };

  return {
    voucherData,
    voucherCode,
    hasValidVoucher,
    submitVoucher,
    setVoucherCode,
    voucherValidation,
    setVoucherValidation,
  };
};

export default useSubmitCouponData;
