import ActionsValidateVoucher from '@rof/Actions/validateVoucher';
import { ValidateVoucherResponse } from '@rof/Api/bls-sc/types';

import { IState } from '../types';

const init: IState<ValidateVoucherResponse> = {
  data: undefined,
  isLoading: false,
  error: undefined,
  lastFetched: undefined,
};

const validateVoucherReducer = (state: IState<ValidateVoucherResponse> = init, action: ActionsValidateVoucher) => {
  switch (action.type) {
    case '[BLS-SC - validateVoucher] START':
      return {
        ...state,
        isLoading: true,
      };
    case '[BLS-SC - validateVoucher] SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case '[BLS-SC - validateVoucher] ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: return state;
  }
};

export default validateVoucherReducer;
