import React, { useState } from 'react';

import { O2TextField, O2Button } from 'o2-theme-react';

import { IProps } from './types';

const PasswordInput: React.FC<IProps> = ({ placeholder, password, validationText, validationType, onChange, id, onKeyDown }: IProps) => {
  const [ inputType, setInputType ] = useState('password');
  let passwordShowTimeout: number;

  const handlePasswordVisibility = () => {
    if (inputType === 'password') {
      passwordShowTimeout = setTimeout(setInputType, 30000, 'password'); 
      setInputType('text');
    } else {
      clearTimeout(passwordShowTimeout);
      setInputType('password');
    }
  };

  return (
    <O2TextField
      id={id}
      controlSize="full-width"
      type={inputType}
      placeholder={placeholder}
      validationText={validationText}
      validationType={validationType}
      onKeyDown={onKeyDown}
      value={password}
      onChange={(e: any) => onChange(e.target.value)}
    >
      <input/>
      <O2Button
        icon={inputType === 'text' ? 'eye-disabled-fill' : 'eye-fill'}
        color="transparent"
        form="over-inner"
        title={inputType === 'text' ? 'Skrýt heslo' : 'Zobrazit heslo'}
        onClick={handlePasswordVisibility} />
    </O2TextField>
  );
};

export default PasswordInput;
