import { sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { CheckProductRequest, CheckProductResponse } from '../types';

export const getCheckProduct = (
  requestBody: CheckProductRequest
): AxiosPromise<CheckProductResponse> => {
  return sendPost<CheckProductResponse>(
    '/bsl-sc-api/public/v1/subscription/checkProduct',
    null,
    requestBody
  );
};
