import { useState, useEffect } from 'react';

import { IAAEPopupMessageType } from '@Hooks/types';
import useAnalytics from '@Hooks/useAnalytics';
import { getKahlMessageByQueryParams } from '@rof/Api/kahl';
import { IKahlIdStore, IKahlQueryParams } from '@rof/Api/kahl/types';
import { IMessage } from '@rof/Blocks/FlashMessage/types';
import { APP_ID } from '@rof/Constants/index';
import IStore from '@Store/types';

import { format } from 'date-fns';
import { useSelector, shallowEqual } from 'react-redux';

import useLocalization from './useLocalization';

const useKahlMessages = () => {
  const { kahlMessageId, timestamp } = useSelector<IStore, IKahlIdStore>(({ kahl }) => kahl, shallowEqual); 
  const { lang } = useLocalization();
  const [ message, setMessage ] = useState<IMessage | null>(null);
  const { AAEPopupMessage } = useAnalytics();

  const getKahlColor = (severity: string, isInternal: boolean) => {
    if (isInternal) {
      return 'internal';
    } else {
      if (severity === '2' || severity === '3') {
        return 'success';
      } else if (severity === '3') {
        return 'warning';
      } else {
        return 'danger';
      }
    }
  };
  const AAEGetKahlMessage = (severity: string, isInternal: boolean): IAAEPopupMessageType => {
    if (isInternal || severity === '2' || severity === '3') {
      return IAAEPopupMessageType.success;
    } else {
      return IAAEPopupMessageType.error;
    }
  };

  useEffect(() => {
    if (kahlMessageId !== null) {
      const queryParams: IKahlQueryParams = {
        infoMessageIds: kahlMessageId,
        timestamp: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
        sourceApplication: APP_ID,
        locale: lang,
      };
  
      getKahlMessageByQueryParams(queryParams)
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            const kahlMessageData = res.data[i];
            AAEPopupMessage({messageType: AAEGetKahlMessage(kahlMessageData.errorType,
              kahlMessageData.isInternal), messageId: kahlMessageId, messageDescription: kahlMessageData.text});
            setMessage({
              text: kahlMessageData.text,
              color: getKahlColor(
                kahlMessageData.errorType,
                kahlMessageData.isInternal
              ),
              autoClose: kahlMessageData.errorType === '1' ? 10 : 5,
              hasCloseBtn: parseInt(kahlMessageData.errorType) <= 3,
            });
          }
        }).catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [ kahlMessageId, timestamp ]);

  return message;
};

export default useKahlMessages;
