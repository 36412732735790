import { Dispatch } from 'react';

import ActionsOrderDetail from '@rof/Actions/orderDetail';
import { getOrderDetail } from '@rof/Api/bls-payment/orderDetail';

export const orderDetail = () => {
  return async (dispatch: Dispatch<ActionsOrderDetail>) => {
    dispatch({ 
      type: '[BLS-PAYMENT - orderDetail] START',
    });
    getOrderDetail().then((response) => {
      if (response.status !== 204) {
        dispatch({
          type: '[BLS-PAYMENT - orderDetail] SUCCESS',
          data: response.data,
        });
      } else {
        dispatch({
          type: '[BLS-PAYMENT - orderDetail] ERROR',
          error: 'No order',
        });
      }
    }).catch((error) => {
      dispatch({ 
        type: '[BLS-PAYMENT - orderDetail] ERROR',
        error,
      });
    });
  };
};
