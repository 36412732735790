import React, {useEffect} from 'react';

import useAnalytics from '@Hooks/useAnalytics';
import useCodelists from '@rof/Hooks/useCodeLists';

import { O2Layout, O2LayoutColumn, O2Pane, O2Section } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';

import { IProps } from './types';

const NoService: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { getItems } = useCodelists();
  const [ prepaidO2TVTariffs ] = getItems('redirect.o2.prepaidO2TVTariffs');
  const { AAEPageLoaded } = useAnalytics();

  useEffect(() => {
    AAEPageLoaded({ pageId: '1.1.2.6', pageTitle: t('tvsc.noService.title')});
  // eslint-disable-next-line
}, []);

  return (
    <>
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          <O2Section>
            <O2Pane>
              <div className="o2-loader o2-loader--noresults">
                <span className="o2-loader__element"></span>
                <span className="o2-loader__text">{t('tvsc.noService.title')}
                  <span className="o2-typo--link" onClick={() => window.location.href = prepaidO2TVTariffs}> {t('tvsc.noService.goSelectService')}</span>
                </span>
              </div>
            </O2Pane>
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
    </>
  );
};

export default NoService;
