import { GetOrderDetailResponse } from '@rof/Api/bls-payment/types';
import { IState } from '@rof/Reducers/types';
import { orderDetail } from '@rof/Thunks/orderDetail';
import IStore from '@Store/types';

import { useDispatch, useSelector } from 'react-redux';

const useOrderDetail = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector<IStore, IState<GetOrderDetailResponse>>((state) => state.orderDetail);

  const init = (force = false) => {
    if (((isLoading === false || isLoading === undefined) && data === undefined) || force) {
      dispatch(orderDetail());
    }
  };

  return {
    init,
    data,
    isLoading,
    error,
  };
};

export default useOrderDetail;
