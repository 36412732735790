import { useState } from 'react';

import { LOGIN_URL } from '@Constants/index';
import { AUTH_INTERNAL_NOT_ALLOWED, AUTH_LOA0_ROLE_REQUIRED, AUTH_PREPAID_ONLY } from '@Constants/kahl';
import { APP_ID } from '@rof/Constants/index';
import useAuth from '@rof/Hooks/useAuth';
import useCodelists from '@rof/Hooks/useCodeLists';
import {
  redirectToStandaloneKahl,
} from '@rof/Utils/redirects';
import isSlugDeeplink from '@Utils/isSlugDeeplink';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { decorateLinkByLeadId } from '@Utils/decorateLinkByLeadId';

const useTvscAuth = () => {
  const [ isUserAuthenticated, setIsUserAuthenticated ] = useState<boolean>(false);
  const { getItems } = useCodelists();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ onboarding ] = getItems('redirect.ume.onboarding');
  const location = useLocation();

  const {
    isRequested,
    isLoggedIn,
    isInternal,
    subscriberType,
    hasRoleByName,
    koId,
    deviceId,
  } = useAuth();

  const checkTvscAuthorization = () => {
    if (isRequested) {
      if (!isLoggedIn) {
        if (isSlugDeeplink()) {
          window.location.href = decorateLinkByLeadId(onboarding + encodeURIComponent(window.location.href));
        } else {
          if (!window.location.href.includes(LOGIN_URL)) {
            if (!isSlugDeeplink()) {
              const queryParams = queryString.parse(location.search);
              queryParams.returnTo = window.location.pathname;
              window.location.href = LOGIN_URL + `?${queryString.stringify(queryParams)}`;
            }
          }
          return;
        }
      }
      
      if (isInternal()) {
        redirectToStandaloneKahl(AUTH_INTERNAL_NOT_ALLOWED, APP_ID);
      }
  
      if (subscriberType === 'O2PRP') {
        redirectToStandaloneKahl(AUTH_PREPAID_ONLY, APP_ID);
      }
  
      if ((hasRoleByName('LOA0') && deviceId)) {
        redirectToStandaloneKahl(AUTH_LOA0_ROLE_REQUIRED, APP_ID);
      };
      
      if (!koId) {
        console.log('Invalid user');
      }
  
      setIsUserAuthenticated(true);
    }
  };

  return {
    checkTvscAuthorization,
    isUserAuthenticated,
  };
};

export default useTvscAuth;
