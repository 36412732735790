import React, { useState } from 'react';

import useAuth from '@rof/Hooks/useAuth';

import {
  O2Header,
  O2HeaderControls,
  O2HeaderLink,
  O2HeaderLinks,
  O2HeaderLogo,
} from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import User from './User';
import useCodeLists from '@rof/Hooks/useCodeLists';

const Header: React.FC = () => {
  const { email } = useAuth();
  const { t } = useTranslation();
  const [ , setOpen ] = useState(false);
  const [ isUserOpen, setIsUserOpen ] = useState(false);
  const user = useSelector<any, any>((state) => state.userInfo);
  const { getItems } = useCodeLists();
  const [ homepage ] = getItems('redirect.kaltura.homepage');

  const logo = `${(window as any).g_apfThemeLocation}/images/header/logo-header-o2.png`;

  const handleUserClick = (e: any) => {
    // console.log('open: ', open);
    e.preventDefault();
    setIsUserOpen(!isUserOpen);
  };

  if (!user.logged) {
    return (
      <O2Header>
        <O2HeaderLinks>
          <O2HeaderLogo href={homepage} src={logo}>{t('tvsc.header.text')}</O2HeaderLogo>
        </O2HeaderLinks>
      </O2Header>
    );
  }

  return (
    <>
      <O2Header>
        <O2HeaderControls>
          <O2HeaderLink 
            user
            id="userElm"
            onClick={handleUserClick}
          >
            {email ? email : ''}
          </O2HeaderLink>
          <O2HeaderLink
            icon
            onClick={() => setOpen(true)}
            className="h-hide-md-up-inline"
          >
          </O2HeaderLink>
        </O2HeaderControls>
        <O2HeaderLinks>
          <O2HeaderLogo
            href={homepage}
            src={logo}
          >
            {t('tvsc.header.text')}
          </O2HeaderLogo>
        </O2HeaderLinks>
      </O2Header>
      <User 
        isOpen={isUserOpen} 
        setOpen={setIsUserOpen}
        user={email ? email : ''}
      />
    </>
  );
};

export default Header;
