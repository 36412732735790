import { sendGet, getServicesPath } from '@rof/Utils/requests';
import { getState } from '@Store/index';

import { AxiosPromise } from 'axios';

import {
  SpecificFinancialDocumentsRequest,
  FinancialDocumentsResponse,
  FinancialDocumentDetailResponse,
  FinancialDocumentsRequest,
} from './types';

export const getSpecificFinancialDocuments = (
  params: SpecificFinancialDocumentsRequest,
  documentId: string
): AxiosPromise<FinancialDocumentDetailResponse> => {
  const ENDPOINTS = getState().codeList?.['TVSC_ENDPOINTS'];
  return sendGet<FinancialDocumentDetailResponse>(
    getServicesPath(`${ENDPOINTS && ENDPOINTS['bff.endpoint.base']}/financial-documents/${documentId}`),
    params
  );
};

export const getFinancialDocuments = (
  params: FinancialDocumentsRequest
): AxiosPromise<FinancialDocumentsResponse> => {

  if (!params.issueDateFrom) {
    const today = new Date();

    const result = new Date(today.getTime() - (396 * 24 * 60 * 60 * 1000));

    const year = result.getFullYear();
    const month = (result.getMonth() + 1).toString().padStart(2, '0'); // Přidání nuly na začátek, pokud je měsíc jednociferný
    const day = result.getDate().toString().padStart(2, '0'); // Přidání nuly na začátek, pokud je den jednociferný

// Výpis výsledného data ve formátu "YYYY-MM-DD"
    params.issueDateFrom = year + '-' + month + '-' + day;
  }

  if (!params.issueDateTo) {
    const today = new Date();

    const result = new Date(today.getTime());

    const year = result.getFullYear();
    const month = (result.getMonth() + 1).toString().padStart(2, '0'); // Přidání nuly na začátek, pokud je měsíc jednociferný
    const day = result.getDate().toString().padStart(2, '0'); // Přidání nuly na začátek, pokud je den jednociferný

// Výpis výsledného data ve formátu "YYYY-MM-DD"
    params.issueDateTo = year + '-' + month + '-' + day;
  }

  const ENDPOINTS = getState().codeList?.['TVSC_ENDPOINTS'];
  return sendGet<FinancialDocumentsResponse>(
    getServicesPath(`${ENDPOINTS && ENDPOINTS['bff.endpoint.base']}/financial-documents`),
    params
  );
};
