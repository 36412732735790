import { sendPut } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { CreateServiceOrderRequestBody, CreateServiceOrderResponse } from '../types';

export const putCreateOrder = (
  requestBody: CreateServiceOrderRequestBody
): AxiosPromise<CreateServiceOrderResponse> => {
  return sendPut<CreateServiceOrderResponse>(
    '/bsl-payment-api/public/v1/orders/createOrder',
    null,
    requestBody
  );
};
