import React, { useState } from 'react';

import useAnalytics from '@Hooks/useAnalytics';

import { O2Indent, O2Modal, O2ModalBody, O2ModalFooter, O2ModalFooterButton, O2Table, O2TableBody, O2TableCell, O2TableHeader, O2TableHeaderCell, O2TableRow } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';

import IProps from './types';

const PaymentDialogMultiple = ({ open, setOpenCb, payments }: { open: boolean; setOpenCb: any; payments: any}) => {
  const { t } = useTranslation();

  return (
    <O2Modal
      isOpen={open}
      toggle={setOpenCb}
      size="lg"
      contentLeft>
      <O2ModalBody>
        <O2Indent>{t('tvsc.paymentList.payType.modal.title')}</O2Indent>
        <O2Indent>
          <PaymentTableMultiple>
            {payments.map((payment: any) =>
              <PaymentRowMultiple payment={payment} />
            )}
          </PaymentTableMultiple>
        </O2Indent>
      </O2ModalBody>
      <O2ModalFooter>
        <O2ModalFooterButton icon="block" color="neutral" onClick={setOpenCb}>
          {t('tvsc.paymentList.payType.modal.close')}
        </O2ModalFooterButton>
      </O2ModalFooter>
    </O2Modal>
  );
};

const PaymentRowMultiple = ({ payment }: { payment: any }) => {
  const { t } = useTranslation();

  return (
    <O2TableRow>
      <O2TableCell dominant>{t(`tvsc.paymentList.payType.modal.payType.${payment.paymentType}`)}</O2TableCell>
      <O2TableCell>{payment.date}</O2TableCell>
      <O2TableCell data-title="Částka">{payment.amountPaid}</O2TableCell>
      <O2TableCell className="h-text-right">
        {payment.saveDocument}
      </O2TableCell>
    </O2TableRow>
  );
};

const PaymentTableMultiple = (props: any) => {
  const { t } = useTranslation();

  return (
    <O2Table condensed>
      <O2TableHeader>
        <O2TableRow>
          <O2TableHeaderCell>{t('tvsc.paymentList.payType.modal.column.type')}</O2TableHeaderCell>
          <O2TableHeaderCell>{t('tvsc.paymentList.payType.modal.column.date')}</O2TableHeaderCell>
          <O2TableHeaderCell>{t('tvsc.paymentList.payType.modal.column.amountPaid')}</O2TableHeaderCell>
          <O2TableHeaderCell></O2TableHeaderCell>
        </O2TableRow>
      </O2TableHeader>
      <O2TableBody>
        { props.children }
      </O2TableBody>
    </O2Table>
  );
};

const RowWithModal: React.FC<IProps> = ({ payment }: IProps) => {
  const [ modalOpen, setModalOpen ] = useState<boolean>(false); 
  const { t } = useTranslation();
  const { AAEModalOpen } = useAnalytics();
  return (
    <>
      <O2TableRow key={shortid.generate()}>
        <O2TableCell key={shortid.generate()}>{payment.service}</O2TableCell>
        <O2TableCell key={shortid.generate()}>{payment.date}</O2TableCell>
        <O2TableCell key={shortid.generate()}>{payment.variableSymbol}</O2TableCell>
        <O2TableCell key={shortid.generate()}>{payment.amountPaid}</O2TableCell>
        <O2TableCell key={shortid.generate()}>{(payment.saveDocument === false ?
          <div
            className="o2-typo--link"
            onClick={() => {
              setModalOpen(!modalOpen);
              AAEModalOpen({
                modalId: '1.1.2.2_11.2.2',
                modalHeading: t('tvsc.paymentList.payType.modal.title'),
              });
            }}>
            {t('tvsc.paymentList.payType.chooseDocument')}
          </div>
          : payment.saveDocument)
        }</O2TableCell>
      </O2TableRow>
      {payment.modalData && 
        <PaymentDialogMultiple open={modalOpen} setOpenCb={() => setModalOpen(!modalOpen)} payments={payment.modalData} />
      }
      
    </>
  );

};

export default RowWithModal;
