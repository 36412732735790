import { sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { ResetParentalLockRequestBody, ResetParentalLockResponse } from '../types';

export const postResetParentalLock = (
  requestBody: ResetParentalLockRequestBody,
): AxiosPromise<ResetParentalLockResponse> => {
  return sendPost<ResetParentalLockResponse>(
    '/bsl-sc-api/public/v1/subscription/resetParentalLock',
    null,
    requestBody
  );
};
