import { AUTH_API_BASE } from '@rof/Constants/api';
import { sendGet } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { IUserInfo } from './types';

export const getUserInfo = (): AxiosPromise<IUserInfo> => {
  return sendGet<IUserInfo>(`${AUTH_API_BASE}/userInfo`, undefined, undefined, undefined, undefined, false, false);
};

export const getUserInfoProtected = (): AxiosPromise<IUserInfo> => {
  return sendGet<IUserInfo>(`${AUTH_API_BASE}/userInfoProtected`, undefined, undefined, undefined, undefined, false, false);
};
