import { sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { CancelOrderRequest, CancelOrderResponse } from '../types';

export const postCancelOrder = (
  params: CancelOrderRequest
): AxiosPromise<CancelOrderResponse> => {
  return sendPost<CancelOrderResponse>(
    `/bsl-payment-api/public/v1/orders/cancel/${params.orderId}`,
    params
  );
};
