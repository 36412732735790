import { useEffect, useState } from 'react';

import { CANCEL_SUBSCRIPTION_SUCCESS, CANCEL_SUBSCRIPTION_FAIL } from '@Constants/kahl';
import useGetServiceDetail from '@Modules/getServiceDetail/useGetServiceDetail';
import useGetUserServices from '@Modules/getUserServices/useGetUserServices';
import { postCancelSubscription } from '@rof/Api/bls-sc/cancelSubscription';
import useReduxLoggerActions from '@rof/Hooks/useReduxLoggerActions';
import { setKahlMessageId } from '@rof/Thunks/kahl';

import { useDispatch } from 'react-redux';

const useServiceDetailData = () => {
  const dispatch = useDispatch();
  const { reduxLog } = useReduxLoggerActions();
  const [service, setService] = useState<any>();
  const [isWithoutService, setIsWithoutService] = useState(false);
  const [arePrerequisitiesLoaded, setArePrerequisitiesLoaded] = useState(false);
  const [isOpenconfirmModal, setIsOpenconfirmModal] = useState(false);
  const [isCanceledSubscription, setIsCanceledSubscription] = useState(false);
  const [isCanceledSubscriptionSuccess, setIsCanceledSubscriptionSuccess] = useState(false);
  const { init, data, isLoading, error } = useGetServiceDetail();
  const {
    init: initUserServices,
    data: dataUserServices,
    isLoading: isLoadingUserServices,
    error: errorUserServices,
  } = useGetUserServices();

  useEffect(() => {
    init(true);
    initUserServices(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((data && !isLoading) && (dataUserServices && !isLoadingUserServices)) {
      setArePrerequisitiesLoaded(true);
      const { services } = dataUserServices;
      if (services.length === 0) {
        setIsWithoutService(true);
      } else {
        const service = services.filter((el) => el.serviceType === 'TV_PRP');
        setService(service[0]);
        if (service.length === 0) {
          setIsWithoutService(true);
        }
      }

    }
  }, [data, dataUserServices, isLoading, isLoadingUserServices]);

  const onConfirmModal = async () => {
    setIsOpenconfirmModal(false);
    setIsCanceledSubscription(true);
    try {
      reduxLog('postCancelSubscription START');
      const { data } = await postCancelSubscription();
      reduxLog('postCancelSubscription SUCCESS');
      if (data.success) {
        dispatch(setKahlMessageId(CANCEL_SUBSCRIPTION_SUCCESS));
        setIsCanceledSubscriptionSuccess(true);
      } else {
        setIsCanceledSubscription(false);
        dispatch(setKahlMessageId(CANCEL_SUBSCRIPTION_FAIL));
      }
    } catch (error) {
      reduxLog('postCancelSubscription FAIL');
      setIsCanceledSubscription(false);
      dispatch(setKahlMessageId(CANCEL_SUBSCRIPTION_FAIL));
    }
  };

  return {
    init,
    data,
    isLoading,
    error,
    isOpenconfirmModal,
    setIsOpenconfirmModal,
    isCanceledSubscription,
    isCanceledSubscriptionSuccess,
    onConfirmModal,
    isWithoutService,
    arePrerequisitiesLoaded,
    service,
    errorUserServices,
  };
};

export default useServiceDetailData;
