import React from 'react';

import RowWithModal from '@Blocks/RowWithModal';

import { O2Table, O2TableHeader, O2TableBody, O2TableRow, O2TableHeaderCell, O2Loader, O2Pane } from 'o2-theme-react';
import shortid from 'shortid';

import IProps from './types';

const Table: React.FC<IProps> = ({ data, isLoading, error, columns }: IProps) => {
  if (isLoading) return <O2Loader lazyTable />;
  if (!data || !data.length) return <O2Pane><O2Loader noResults>{error}</O2Loader></O2Pane>;
  
  return (
    <>
      <O2Table>
        <O2TableHeader>
          <O2TableRow>
            {columns?.map((column) => <O2TableHeaderCell key={shortid.generate()}>{column}</O2TableHeaderCell>)}
          </O2TableRow>
        </O2TableHeader>
        <O2TableBody>
          {data?.map((payment) => (
            <RowWithModal key={shortid.generate()} payment={payment} />
          ))}
        </O2TableBody>
      </O2Table>
    </>
  );
};

export default Table;
