import { KAHL_API_BASE } from '@rof/Constants/api';
import { sendGet, buildURLQuery } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { IKahlMessage, IKahlQueryParams } from './types';

export const getKahlMessageByQueryParams = (parameters: IKahlQueryParams): AxiosPromise<IKahlMessage[]> => {
  const queryParams = buildURLQuery(parameters);
  return sendGet<IKahlMessage[]>(`${KAHL_API_BASE}${queryParams}`, undefined, undefined, undefined, undefined, false, true);
};
