import { Dispatch } from 'react';

import ActionsCheckProduct from '@rof/Actions/checkProduct';
import { getCheckProduct } from '@rof/Api/bls-sc/checkProduct';
import { CheckProductRequest } from '@rof/Api/bls-sc/types';

export const checkProduct = (params: CheckProductRequest) => {
  return async (dispatch: Dispatch<ActionsCheckProduct>) => {
    dispatch({ 
      type: '[BLS-SC - checkProduct] START',
    });
    getCheckProduct(params).then((response) => {
      dispatch({ 
        type: '[BLS-SC - checkProduct] SUCCESS',
        data: response.data,
      });
    }).catch((error) => {
      dispatch({ 
        type: '[BLS-SC - checkProduct] ERROR',
        error,
      });
    });
  };
};
