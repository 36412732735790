import { sendGet } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { GetCardDetailResponse } from '../types';

export const getGetCardDetail = (): AxiosPromise<GetCardDetailResponse> => {
  return sendGet<GetCardDetailResponse>(
    '/bsl-sc-api/public/v1/service/getCardDetail',
  );
};
