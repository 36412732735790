import { LOGIN_URL_XTV } from '@Constants/api/index';
import { getServicesPath, sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

export const login = (username: string, password: string): AxiosPromise => {
  return sendPost(
    getServicesPath(LOGIN_URL_XTV),
    null,
    {
      username,
      password,
      service: window.location.origin,
    },
  );
};
