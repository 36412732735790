import { sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { ApplyVoucherRequest, ApplyVoucherResponse } from '../types';

export const postApplyVoucher = (
  params: ApplyVoucherRequest
): AxiosPromise<ApplyVoucherResponse> => {
  return sendPost<ApplyVoucherResponse>(
    '/bsl-sc-api/public/v1/subscription/applyVoucher',
    params,
  );
};
