import React, { useEffect } from 'react';

import useCodelists from '@rof/Hooks/useCodeLists';
import { updateCreateOrderContext, resetCreateOrderContext } from '@rof/Thunks/createOrderContext';

import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getParams } from '../index';
import { IProps } from '../types';
import { storeLeadId } from '@Utils/storeLeadId';

const Actmovie: React.FC<IProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const { getItems } = useCodelists();
  const [ prepaidO2TVTariffs ] = getItems('redirect.o2.prepaidO2TVTariffs');
  const dispatch = useDispatch();
  const params = getParams();
  const { movie, content, coupon, returnUrl } = params;
  const queryParams = queryString.parse(location.search);
  storeLeadId(queryParams);

  useEffect(() => {
    if (movie && content) {
      dispatch(resetCreateOrderContext());
      dispatch(updateCreateOrderContext({ productType: 'ASSET', ppvId: movie }));
      if (returnUrl) window.localStorage.setItem(movie, returnUrl);
      const queryParams = queryString.parse(location.search);
      history.push(`/payment/${content}` + (coupon ? `/${coupon}` : '') + `?${queryString.stringify(queryParams)}`);
    } else {
      window.location.href = prepaidO2TVTariffs;
    }
    // eslint-disable-next-line
  }, []);

  return null;
};

export default Actmovie;
