import { useEffect } from 'react';

import { CHANGE_PAYMENT_CARD_SUCCESS, CHANGE_PAYMENT_CARD_FAIL, CREATE_ORDER_OTHER_ORDER_EXISTS } from '@Constants/kahl';
import useAnalytics from '@Hooks/useAnalytics';
import useGetServiceDetail from '@Modules/getServiceDetail/useGetServiceDetail';
import { putValidateCard } from '@rof/Api/bls-payment/validateCard';
import useMoney from '@rof/Hooks/useMoney';
import useReduxLoggerActions from '@rof/Hooks/useReduxLoggerActions';
import { setKahlMessageId } from '@rof/Thunks/kahl';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { periodStringsToDaysNumber } from '@Utils/utils';

const useChangePaymentCardData = () => {
  const { t } = useTranslation();
  const { moneyFormat } = useMoney();
  const dispatch = useDispatch();
  const history = useHistory();
  const { reduxLog } = useReduxLoggerActions();
  const { init, data, isLoading, error } = useGetServiceDetail();
  const { AAEFormSubmit } = useAnalytics();

  useEffect(() => {
    if (!data && !isLoading) {
      init(true); 
    }
    // eslint-disable-next-line
  }, []);

  const paymentFormat = (name: string, isDiscount: boolean, price?: number, period?: number, suffif?: string) => {
    return {
      name: name,
      price: price
        ? period
          ? t(`tvsc.paymentCardChange.${isDiscount ? 'discount' : 'price'}InfoPeriod`, {
            price:  moneyFormat(price, undefined, undefined, suffif),
            period: period,
          })
          : t(`tvsc.paymentCardChange.${isDiscount ? 'discount' : 'price'}Info`, {
            price: moneyFormat(price, undefined, undefined, suffif),
          })
        : t('tvsc.paymentCardChange.infoUnknown'),
      isDiscount,
    };
  };

  const payments = () => {
    const result = [];

    if (data?.serviceName) {
      result.push(paymentFormat(
        data?.serviceName,
        false,
        data?.servicePrice,
        data?.servicePeriod ? periodStringsToDaysNumber(data?.servicePeriod) : 0, 
        'CZK'
      ));
    }
    if (data?.serviceDiscountNextPrice) {
      result.push(paymentFormat(
        t('tvsc.paymentCardChange.discountTitle'),
        true,
        data?.serviceDiscountNextPrice,
        undefined,
        'CZK'
      ));
    }

    return result;
  };

  const submitVerificationPayment = async () => {
    try {
      reduxLog('putValidateCard START');
      const { data } = await putValidateCard();
      if (data.success) {
        reduxLog('putValidateCard SUCCESS');
        dispatch(setKahlMessageId(CHANGE_PAYMENT_CARD_SUCCESS));
        history.push('/redirecting-for-payment');
      } else {
        if (data.failureReason === 'OTHER_ORDER_EXISTS') {
          dispatch(setKahlMessageId(CREATE_ORDER_OTHER_ORDER_EXISTS));
        } else {
          dispatch(setKahlMessageId(CHANGE_PAYMENT_CARD_FAIL));
        }
      }
    } catch (error) {
      reduxLog('putValidateCard FAIL');
      dispatch(setKahlMessageId(CHANGE_PAYMENT_CARD_FAIL));
    }
    AAEFormSubmit({formId: '1.1.2.2.1_006'});
  };

  return {
    init,
    data,
    isLoading,
    error,
    paymentsData: payments(),
    submitVerificationPayment,
  };
};

export default useChangePaymentCardData;
