import ActionsGetBalanceHistory from '@rof/Actions/getBalanceHistory';
import { BalanceHistoryResponse } from '@rof/Api/bff/balanceHistory/types';

import { IState } from '../types';

const init: IState<BalanceHistoryResponse> = {
  data: undefined,
  isLoading: false,
  error: undefined,
};

const getBalanceHistory = (state: IState<BalanceHistoryResponse> = init, action: ActionsGetBalanceHistory) => {
  switch (action.type) {
    case '[BFF - getBalanceHistory] START':
      return {
        ...state,
        isLoading: true,
      };
    case '[BFF - getBalanceHistory] SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case '[BFF - getBalanceHistory] ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: return state;
  }
};

export default getBalanceHistory;
