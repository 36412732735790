import { useState, useEffect } from 'react';

import { COMMON_FAIL } from '@Constants/kahl';
import useParamsBuilder from '@Modules/getBalanceHistory/useParamsBuilder';
import { getBalanceHistory } from '@rof/Api/bff/balanceHistory';
import {
  BalanceHistoryResponse,
  BalanceHistoryPaymentType,
  BalanceHistoryPaymentStatusType,
  XTvPaymentEvent,
} from '@rof/Api/bff/balanceHistory/types';
import { setKahlMessageId } from '@rof/Thunks/kahl';

import { useDispatch } from 'react-redux';

const useGetBalanceHistory = (
  pageInit: number,
  paymentType?: BalanceHistoryPaymentType,
  paymentStatus?: BalanceHistoryPaymentStatusType
) => {
  const APIParams = useParamsBuilder(pageInit, paymentType, paymentStatus);
  const [ balanceHistory, setBalanceHistory ] = useState<BalanceHistoryResponse | undefined>();
  const [ isDataLoading, setIsDataLoading ] = useState(false);
  const [ error, setError ] = useState<string | undefined>();
  const [ currentPage, setCurrentPage ] = useState(pageInit);
  const [ dateTimeFrom, setDateTimeFrom ] = useState<string | undefined>();
  const [ dateTimeTo, setDateTimeTo ] = useState<string | undefined>();
  const [ isPossibleFetchMore, setIsPossibleFetchMore ] = useState<boolean>(false);
  const dispatch = useDispatch();

  const setFilteredDataForView = (data: BalanceHistoryResponse | any) => {
    data.xtvPaymentEvents = data?.xtvPaymentEvents?.filter((paymentEvent: XTvPaymentEvent | any) => {
      if (
        paymentEvent.paymentType === BalanceHistoryPaymentType.ONE_TIME_PAY ||
        paymentEvent.paymentType === BalanceHistoryPaymentType.RECURRENT_PAY
      ) {
        if (paymentEvent.paymentStatus === BalanceHistoryPaymentStatusType.APPROVED) {
          return true;
        } else {
          return false;
        }
      }

      if (paymentEvent.paymentType === BalanceHistoryPaymentType.REFUND || paymentEvent.paymentType === BalanceHistoryPaymentType.REQUEST) {
        if (paymentEvent.paymentStatus === BalanceHistoryPaymentStatusType.FINISHED) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    }) ?? [];

    setBalanceHistory(data);
  };

  useEffect(() => {
    if (error) {
      dispatch(setKahlMessageId(COMMON_FAIL));
    }
    // eslint-disable-next-line
  }, [error]);

  const fetchData = (page: number, init: boolean, eventTimeFrom?: string, eventTimeTo?: string) => {
    setIsDataLoading(true);

    getBalanceHistory({ ...APIParams, page, eventTimeFrom, eventTimeTo })
      .then(({ data }) => {
        if (init) {
          setIsPossibleFetchMore(true);
          setFilteredDataForView(data);
        } else {
          if (balanceHistory) {
            setFilteredDataForView((prevState: any) => {
              return {
                ...data,
                xtvPaymentEvents: [ ...(prevState?.xtvPaymentEvents || []), ...(data.xtvPaymentEvents || []) ],
              };
            });
          }
        }
        setIsDataLoading(false);
        setIsPossibleFetchMore(!(page >= data.totalPages));
      })
      .catch((err) => {
        setError(err);
        setIsDataLoading(false);
      });
  };

  const fetchMoreGetBalanceHistoryData = () => {
    fetchData(currentPage + 1, false, dateTimeFrom, dateTimeTo);
    setCurrentPage(currentPage + 1);
  };

  const filterGetBalanceHistoryData = (dateFrom?: string, dateTo?: string) => {
    fetchData(pageInit, true, dateFrom, dateTo);
    setDateTimeFrom(dateFrom);
    setDateTimeTo(dateTo);
    setCurrentPage(1);
  };

  return {
    init: () => fetchData(pageInit, true, dateTimeFrom, dateTimeTo),
    fetchMoreGetBalanceHistoryData,
    filterGetBalanceHistoryData,
    isPossibleFetchMore,
    data: balanceHistory,
    isLoading: isDataLoading,
    error,
  };
};

export default useGetBalanceHistory;
