import ActionsCheckProduct from '@rof/Actions/checkProduct';
import { CheckProductResponse } from '@rof/Api/bls-sc/types';

import { IState } from '../types';

const init: IState<CheckProductResponse> = {
  data: undefined,
  isLoading: false,
  error: undefined,
  lastFetched: undefined,
};

const checkProductReducer = (state: IState<CheckProductResponse> = init, action: ActionsCheckProduct) => {
  switch (action.type) {
    case '[BLS-SC - checkProduct] START':
      return {
        ...state,
        isLoading: true,
      };
    case '[BLS-SC - checkProduct] SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case '[BLS-SC - checkProduct] ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: return state;
  }
};

export default checkProductReducer;
