import React, { useEffect } from 'react';

import useGetUserServices from '@Modules/getUserServices/useGetUserServices';
import useCodelists from '@rof/Hooks/useCodeLists';
import { updateCreateOrderContext, resetCreateOrderContext } from '@rof/Thunks/createOrderContext';
import { storeLeadId } from '@Utils/storeLeadId';

import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getParams } from '../index';
import { IProps } from '../types';

const Acttv: React.FC<IProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const params = getParams();
  const { getItems } = useCodelists();
  const [ prepaidO2TVTariffs ] = getItems('redirect.o2.prepaidO2TVTariffs');
  const { tariff, coupon, returnUrl } = params;
  const dispatch = useDispatch();
  const { init, data, error: servicesListError } = useGetUserServices();
  const queryParams = queryString.parse(location.search);
  storeLeadId(queryParams);

  useEffect(() => {
    if (tariff) {
      if (returnUrl) window.localStorage.setItem(tariff, returnUrl);
      init();
    } else {
      window.location.href = prepaidO2TVTariffs;
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data || servicesListError) {
      dispatch(resetCreateOrderContext());
      if (
        (
          data?.services?.[0].serviceId === undefined ||
          data?.services?.[0].serviceId === null
        ) ||
        data?.services?.[0].serviceName === null ||
        data?.services?.[0].serviceName === '' ||
        servicesListError === 'No service'
      ) {
        dispatch(updateCreateOrderContext({ productType: 'SUBSCRIPTION' }));
        history.push(`/payment/${tariff}` + (coupon ? `/${coupon}` : '') + `?${queryString.stringify(queryParams)}`);
      } else {
        dispatch(resetCreateOrderContext());
        dispatch(updateCreateOrderContext({ hasActiveService: true, productType: 'SUBSCRIPTION' }));
        history.push(`/change-service/${tariff}` + (coupon ? `/${coupon}` : '') + `?${queryString.stringify(queryParams)}`);
      }
    }
    //handle 204 status code user exists but haven't any service
    /*if (error === 'No service') {
      dispatch(resetCreateOrderContext());
      dispatch(updateCreateOrderContext({ productType: 'SUBSCRIPTION' }));
      history.push(`/payment/${tariff}` + (coupon ? `/${coupon}` : '') + `?${queryString.stringify(queryParams)}`);
    }*/
    // eslint-disable-next-line
  }, [ data, servicesListError ])

  /*useEffect(() => {
    if (error?.response?.status === 404) {
      dispatch(resetCreateOrderContext());
      dispatch(updateCreateOrderContext({ productType: 'SUBSCRIPTION' }));
      history.push(`/payment/${tariff}` + (coupon ? `/${coupon}` : '') + `?${queryString.stringify(queryParams)}`);
    }
    // eslint-disable-next-line
  }, [ error ])*/

  return null;
};

export default Acttv;
