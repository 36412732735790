import React from 'react';

import {LOGIN_URL} from '@Constants/index';
import useAnalytics from '@Hooks/useAnalytics';
import useAuth from '@rof/Hooks/useAuth';

import { O2Popover, O2PopoverBody, O2Pane, O2Indent } from 'o2-theme-react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import IProps from './types';

const User: React.FC<IProps> = ({ isOpen, user, setOpen }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [ , , removeCookie ] = useCookies([ 'jwt' ]);
  const [ , , removeCookieRefresh ] = useCookies([ 'jwtRefreshToken' ]);
  const { initUserData } = useAuth();
  const { AAELogout } = useAnalytics();

  const logout = (e: any) => {
    AAELogout();
    e.preventDefault();
    setTimeout(() => {
      setOpen(false);
      removeCookie('jwt', { domain: '.' + window.location.hostname.split('.').slice(-2).join('.'), path: '/'});
      removeCookieRefresh('jwtRefreshToken', { domain: '.' + window.location.hostname.split('.').slice(-2).join('.'), path: '/'});
      initUserData(false, true);
      localStorage.removeItem('wcmsLeadId');
      window.location.href = LOGIN_URL;
    }, 1500);
  };

  return (
    <O2Popover
      className="o2-popover--top o2-popover__user"
      size="complex"
      target="userElm"
      hideArrow={true}
      isOpen={isOpen}
    >
      <O2PopoverBody>
        <O2Pane noBorder>
          <O2Indent>
            <h3 className="o2-popover__user-name">
              {user}
            </h3>
          </O2Indent>
          <O2Indent standalone>
            <div className="o2-popover__user-group">
              <a
                href={'/'}
                className="o2-popover__user-action"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/dashboard');
                }}>
                <div className="o2-icon o2-icon--home o2-popover__user-action-icon"></div>
                <div className="o2-popover__user-action-text">
                  {t('tvsc.header.user.dashboard')}
                </div>
              </a>
              <a href="/" className="o2-popover__user-action"
                onClick={logout}>
                <div className="o2-icon o2-icon--exit o2-popover__user-action-icon"></div>
                <div className="o2-popover__user-action-text">
                  {t('tvsc.header.user.logout')}
                </div>
              </a>
            </div>
          </O2Indent>
        </O2Pane>
      </O2PopoverBody>
    </O2Popover>
  );
};

export default User;
