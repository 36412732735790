import React, { useEffect } from 'react';

import Page404 from '@rof/Pages/404';
// import ServiceDetail from '@Pages/ServiceDetail';

import { Switch, Route, useLocation } from 'react-router-dom';

import routes from './routes';

// @pages

const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ pathname ]);

  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          exact={route.subRoutes.some((r) => r.exact)}
          path={route.subRoutes.map((r) => r.path)}
        >
          <route.layout>
            {route.subRoutes.map((subRoute, x) => (
              <Route key={x} {...subRoute} />
            ))}
          </route.layout>
        </Route>
      ))}
      <Route component={Page404} />
    </Switch>
  );
};

export default Router;
