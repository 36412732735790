import React, { useState, useEffect } from 'react';

import PasswordReenterModal from '@Components/blocks/PasswordReenterModal';
import Subheader from '@Components/sections/Subheader';
import { PARENT_LOCK_RESET_SUCCESS, PARENT_LOCK_RESET_FAIL, PARENT_LOCK_RESET_INVALID_PASSWORD, PARENT_LOCK_RESET_USER_DOES_NOT_EXIST, PARENT_LOCK_RESET_USER_WITH_NO_DOMAIN, PARENT_LOCK_RESET_USER_SUSPENDED } from '@Constants/kahl';
import useAnalytics from '@Hooks/useAnalytics';
import { postResetParentalLock } from '@rof/Api/bls-sc/resetParentalLock';
import useAuth from '@rof/Hooks/useAuth';
import { setKahlMessageId } from '@rof/Thunks/kahl';

import { O2Layout, O2LayoutColumn, O2Section, O2Indent, O2Button } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IProps } from './types';
const ParentLockReset: React.FC<IProps> = () => {
  const { AAEPageLoaded, AAEModalOpen, AAEFormSubmit } = useAnalytics();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useAuth();
  const [ istPasswordReenterModalOpen, setIstPasswordReenterModalOpen ] = useState(false);

  const errorHandling = (reason?: string) => {
    switch (reason) {
      case 'INVALID_PASSWORD':
        dispatch(setKahlMessageId(PARENT_LOCK_RESET_INVALID_PASSWORD));
        break;
      case 'USER_DOES_NOT_EXIST':
        dispatch(setKahlMessageId(PARENT_LOCK_RESET_USER_DOES_NOT_EXIST));
        break;
      case 'USER_WITH_NO_DOMAIN':
        dispatch(setKahlMessageId(PARENT_LOCK_RESET_USER_WITH_NO_DOMAIN));
        break;
      case 'USER_SUSPENDED' :
        dispatch(setKahlMessageId(PARENT_LOCK_RESET_USER_SUSPENDED));
        break;
      default:
        dispatch(setKahlMessageId(PARENT_LOCK_RESET_FAIL));
        break;
    }
  };

  const onConfirm = async (password: string) => {
    try {
      setIstPasswordReenterModalOpen(false);
      const { data } = await postResetParentalLock({ password });
      if (data.success) dispatch(setKahlMessageId(PARENT_LOCK_RESET_SUCCESS));
      else errorHandling(data.failureReason);
    } catch (error) {
      errorHandling(error?.response?.data?.reason);
    }
    AAEFormSubmit({ formId: '1.1.2.3.1_009' });
  };

  useEffect(() => {
    AAEPageLoaded({ pageId: '1.1.2.3.1', pageTitle: t('tvsc.parentsLock.title')});
  // eslint-disable-next-line
}, []);

  return (
    <>
      <Subheader
        headingText={t('tvsc.parentsLock.title')}
        backlinkText={t('tvsc.parentsLock.back')}
        pos="center" />
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          <O2Section>
            <O2Indent>
              {t('tvsc.parentsLock.description')}
            </O2Indent>
            <O2Indent>
              <O2Button 
                color="primary" 
                onClick={() => {
                  setIstPasswordReenterModalOpen(true);
                  AAEModalOpen({
                    modalId: '1.1.2.3.1_004',
                    modalHeading: t('tvsc.parentsLock.modal.title'),
                  });
                }}
              >
                {t('tvsc.parentsLock.changeToDefault')}
              </O2Button>
            </O2Indent>
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
      <PasswordReenterModal
        title={t('tvsc.parentsLock.modal.title')}
        label={t('tvsc.parentsLock.modal.loginEmail')}
        buttonTitle={t('tvsc.parentsLock.modal.submit')}
        placeholder={t('tvsc.parentsLock.modal.password')}
        email={email ?? ''}
        isOpen={istPasswordReenterModalOpen}
        onClose={() => setIstPasswordReenterModalOpen(false)}
        onConfirm={onConfirm} 
      />
    </>
  );
};

export default ParentLockReset;
