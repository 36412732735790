import { REFRESH_JWT_XTV, LOGIN_URL_XTV } from '@Constants/api';

import axios, { AxiosPromise, AxiosResponse, AxiosRequestConfig, ResponseType } from 'axios';
import i18next from 'i18next';
import Cookies from 'js-cookie';

import { redirectToXtvLoginPage } from '../redirects';
import { IRequestHeaders } from './types';

let refreshJwtTokenPromise: Promise<any> | null = null;

export const addServicesBasePath = (endpoint: string): string => {
  return (window as any).g_apfServicesLocation + endpoint;
};

export const addCpServicesBasePath = (endpoint: string): string => {
  return (window as any).g_apfCpServicesLocation + endpoint;
};

export const addApiBasePath = (endpoint: string): string => {
  return (window as any).g_apfApiLocation + endpoint;
};

export const addCasApiBasePath = (endpoint: string): string => {
  return (window as any).g_apfCasLocation + endpoint;
};

export const getCasApiPath = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  } else {
    return addCasApiBasePath(`${url}`);
  }
};

export const getServicesPath = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  } else {
    return addServicesBasePath(`/api${url}`);
  }
};

export const getCpServicesPath = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  } else {
    return addCpServicesBasePath(`/api${url}`);
  }
};

export const getApiPath = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  } else {
    return addApiBasePath(`/api${url}`);
  }
};

const formatLocale = (lang: string) => {
  const langs: {[key: string]: string} = {
    en: 'en-US',
    cs: 'cs-CZ',
  };

  return langs[lang];
};

const prepareHeaders = (): IRequestHeaders => {
  if (Cookies.get('jwt') !== undefined) {
    return {
      'Content-Type': 'application/json',
      'If-None-Match': 'string',
      'Accept-Language': formatLocale(i18next.language),
      Authorization: `Bearer ${Cookies.get('jwt')}`,
    };
  } else {
    return {
      'Content-Type': 'application/json',
      'If-None-Match': 'string',
      'Accept-Language': formatLocale(i18next.language),
    };
  }
  
};

const callJwtRefresh = (
  authApiUrl: string,
): void => {
  refreshJwtTokenPromise = new Promise((resolve, reject) => {
    axios
      .request({
        method: 'get',
        url: authApiUrl,
        // eslint-disable-next-line @typescript-eslint/camelcase
        params: { refresh_token: Cookies.get('jwtRefreshToken'), jwt: Cookies.get('jwt') },
        withCredentials: true,
      })
      .then((res) => {
        refreshJwtTokenPromise = null;
        Cookies.set('jwt', res.data.jwt, { domain: '.' + window.location.hostname.split('.').slice(-2).join('.'), path: '/'});
        Cookies.set('jwtRefreshToken', res.data.refresh_token, { domain: '.' + window.location.hostname.split('.').slice(-2).join('.'), path: '/'});
        resolve();
      })
      .catch((err) => {
        refreshJwtTokenPromise = null;
        if (err.response && err.response.status === 401) {
          //redirectToLoginPageForced(getApiPath(LOGIN_URL));
          if (!window.location.href.includes('/login')) {
            redirectToXtvLoginPage();
          }
          reject('login_redirect');
        } else {
          reject('other');
        }
      });
  });
};

const refreshJwtToken = async <R> (
  authApiUrl: string,
  config: AxiosRequestConfig,
): Promise<AxiosResponse<R>> => {
  if (!refreshJwtTokenPromise) {
    callJwtRefresh(authApiUrl);
  }

  try {
    await refreshJwtTokenPromise;
  } catch (reason) {
    if (reason === 'login_redirect') {
      throw Error('Login redirect');
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return processRequest(config, false);
};

const processRequest = async <R> (
  config: AxiosRequestConfig,
  allowJwtRefresh = true,
): Promise<AxiosResponse<R>> => {
  const headers = prepareHeaders();
  return axios.request<R>({ ...config, headers: { ...config.headers, ...headers } }).catch(async (err) => {
    if (err.response && err.response.status === 401) {
      if (allowJwtRefresh && !err.config.url.includes(LOGIN_URL_XTV)) {
        const res = await refreshJwtToken<R>(getServicesPath(REFRESH_JWT_XTV), config);
        return res;
      }
      //redirectToLoginPageForced(LOGIN_URL);
      if (!window.location.href.includes('/login')) {
        redirectToXtvLoginPage();
      }
    }
    throw err;
  });
};

export const sendGet = <R = any> (
  url: string,
  params?: object | null,
  data?: any | null,
  headers?: any,
  responseType: ResponseType = 'json',
  casApiBase = false,
  servicesApiBase = false,
): AxiosPromise<R> => {
  return processRequest<R>({
    method: 'get',
    url: casApiBase ? getCasApiPath(url) : servicesApiBase ? getServicesPath(url) : getApiPath(url),
    withCredentials: true,
    params,
    data,
    responseType,
    headers,
  });
};

export const sendPost = <R = any> (
  url: string,
  params?: object | null,
  data?: any | null,
  headers?: any,
  responseType?: ResponseType,
  servicesApiBase = false,
): AxiosPromise<R> => {
  return processRequest<R>({
    method: 'post',
    url: servicesApiBase ? getServicesPath(url) : getApiPath(url),
    withCredentials: true,
    params,
    data,
    responseType,
    headers,
  });
};

export const sendPatch = <R = any> (
  url: string,
  params?: object | null,
  data?: any | null,
  headers?: any,
): AxiosPromise<R> => {
  return processRequest<R>({
    method: 'patch',
    url: getApiPath(url),
    withCredentials: true,
    params,
    data,
    headers,
  });
};

export const sendPut = <R = any> (url: string, params?: object | null, data?: any | null, headers?: any): AxiosPromise<R> => {
  return processRequest<R>({
    method: 'put',
    url: getApiPath(url),
    withCredentials: true,
    params,
    data,
    headers,
  });
};

export const sendDelete = <R = any> (url: string, params?: object | null, data?: any | null): AxiosPromise<R> => {
  return processRequest<R>({
    method: 'delete',
    url: getApiPath(url),
    withCredentials: true,
    params,
    data,
  });
};

export const buildURLQuery = (parameters: object): string => {
  return '?' + Object.entries(parameters)
    .map((parameter) => parameter.map(encodeURIComponent).join('='))
    .join('&');
};

if ((window as any).g_mockData === 'true') {
    import('./mock')
      .then((mock) => {
        mock.startMockData();
      });
}
