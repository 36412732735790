import React, { useEffect } from 'react';

import { SERVICE_DETAIL } from '@Constants/index';
import useLocalization from '@rof/Hooks/useLocalization';
import { storeLeadId } from '@Utils/storeLeadId';

import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { getParams } from '../index';
import { IProps } from '../types';

const ServiceDetail: React.FC<IProps> = () => {
  const history = useHistory();
  const params = getParams();
  const location = useLocation();
  const { setLang, lang } = useLocalization();
  const { webview, locale } = params;

  useEffect(() => {
    if (webview) window.localStorage.setItem('webview', webview);
    if (locale === 'cs' || locale === 'en') {
      if (lang !== locale) {
        setLang(locale, () => {
          document.location.reload();
        });
      }
    }
    const queryParams = queryString.parse(location.search);
    storeLeadId(queryParams);
    history.push(SERVICE_DETAIL + `?${queryString.stringify(queryParams)}`);
    // eslint-disable-next-line
  }, []);

  return null;
};

export default ServiceDetail;
