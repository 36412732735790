import React from 'react';

import {SERVICE_DETAIL} from '@Constants/index';
import { Redirect } from 'react-router-dom';

import IProps from './types';

const Homepage: React.FC<IProps> = () => {
  return (
    <Redirect to={SERVICE_DETAIL} />
  );
};

export default Homepage;
