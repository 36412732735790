import React from 'react';

import { O2Pane, O2G, O2GColumn, O2Indent, O2Radio } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';

import IProps from './types';

const PaymentType = ({ setSelectedCard, selectedCard, savedCard, totalPayment }: IProps) => {
  const { t } = useTranslation();

  return (
    <O2Pane>
      <O2G separateRows>
        <O2GColumn xs="1" md="2-5">
          <div className="o2-typo--weight-important">{t('tvsc.payment.howYouWantPay')}</div>
        </O2GColumn>

        <O2GColumn xs="1" md="3-5">
          <O2Indent>
            <O2Indent>
              <O2Radio 
                label={t('tvsc.payment.payBySavedCard')}
                defaultChecked={selectedCard === 'savedCard'}
                value="savedCard"
                onChange={(e: any) => setSelectedCard(e.target.value)} 
                name="o2-2-1-2-radio" 
                id="o2-2-1-2-026" 
              />
            </O2Indent>
            <O2Indent top="tight" className="o2-typo--indent-left-checkbox">
              {savedCard}
            </O2Indent>
          </O2Indent>
          <O2Indent>
            <O2Indent>
              <O2Radio 
                label={t('tvsc.payment.payByNewCard')}
                value="newCard"
                defaultChecked={selectedCard === 'newCard'}
                onChange={(e: any) => setSelectedCard(e.target.value)} 
                name="o2-2-1-2-radio" 
                id="o2-2-1-2-027" 
              />
            </O2Indent>
            {totalPayment === 0 ? (
              <O2Indent top="tight" className="o2-typo--color-secondary o2-typo--indent-left-checkbox">
                {t('tvsc.payment.payByNewCardDescription')}
              </O2Indent>
            ) : null}
          </O2Indent>
        </O2GColumn>
      </O2G>
    </O2Pane>
  );
};

export default PaymentType;
