import { Dispatch } from 'react';

import ActionsCreateOrderContext from '@rof/Actions/createOrderContext';
import { CreateOrderContext } from '@rof/Reducers/createOrderContext/types';

export const updateCreateOrderContext = (data: CreateOrderContext) => {
  return async (dispatch: Dispatch<ActionsCreateOrderContext>) => {
    dispatch({ 
      type: '[createOrderContext] UPDATE',
      data,
    });
  };
};

export const resetCreateOrderContext = () => {
  return async (dispatch: Dispatch<ActionsCreateOrderContext>) => {
    dispatch({ 
      type: '[createOrderContext] RESET',
    });
  };
};
