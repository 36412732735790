// returns if object is empty or not
export const isObjEmpty = (obj: any): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; 
    }
  }
  return true;
};

export const handleEnterSubmit = (submitAction: any, e: React.KeyboardEvent<HTMLDivElement>, isFormSubmitting: boolean) => {
  if (!isFormSubmitting && e.key === 'Enter') {
    submitAction();
  }
};
