import { sendGet } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { GetOrderDetailResponse } from '../types';

export const getOrderDetail = (): AxiosPromise<GetOrderDetailResponse> => {
  return sendGet<GetOrderDetailResponse>(
    '/bsl-payment-api/public/v1/orders/orderDetail',
  );
};
