import React from 'react';

import PaymentList from '@Components/sections/PaymentList';

import { O2Section, O2Indent, O2Checkbox, O2InfoMessage, O2Pane } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';

import IProps from './types';

const WithRecurrence: React.FC<IProps> = ({ isAgreeGoPay, onAgreeGoPay, payments, nextPaymentDate }: IProps) => {
  const { t } = useTranslation();

  return (
    <O2Section>
      <O2Indent>
        <h2 className="o2-typo--heading">{t('tvsc.paymentCardChange.activeSubscription')}</h2>
      </O2Indent>
      <O2Indent>
        <O2Pane color="white">
          <PaymentList 
            payments={payments} 
            nextPaymentDate={nextPaymentDate}
          />
        </O2Pane>
      </O2Indent>
      <O2Indent>
        <O2Checkbox
          label={t('tvsc.paymentCardChange.agreeCreateGoPay')}
          onChange={onAgreeGoPay}
          checked={isAgreeGoPay}
        />
      </O2Indent>
      <O2Indent>
        <O2InfoMessage color="neutral">
          {t('tvsc.paymentCardChange.descriptionThree')}
        </O2InfoMessage>
      </O2Indent>
    </O2Section>
  );
};

export default WithRecurrence;
