import React, { useState, useEffect } from 'react';

import { handleEnterSubmit } from '@rof/Utils/index';

import { O2Pane, O2Icon, O2TextField, O2Button, O2G, O2GColumn, O2Addon, O2AddonContent, O2AddonPane } from 'o2-theme-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IProps } from './types';

const Voucher: React.FC<IProps> = ({ code, onRemove, onSubmit, voucherValidation }: IProps) => {
  const { t } = useTranslation();
  const [ isOpen, setOpen ] = useState(false);
  const [ voucher, setVoucher ] = useState('');
  const [ validation, setValidation ] = useState(voucherValidation);
  const { formState, handleSubmit } = useForm();
  
  useEffect(() => {
    if (code) setOpen(true);
  }, [ code ]);

  useEffect(() => {
    setValidation(undefined);
    // eslint-disable-next-line
  }, [ voucher ]);

  useEffect(() => {
    setValidation(voucherValidation);
    // eslint-disable-next-line
  }, [ voucherValidation ]);

  return (
    <>
      {!isOpen ? (
        <div className="o2-typo--link" onClick={() => setOpen(true)}>
          <O2Icon name="price-tag" indentRight />
          {t('tvsc.payment.iHaveVoucher')}
        </div>
      ) : (
        <O2Pane>
          <O2G align="middle" separateRows>
            <O2GColumn xs="1" md="2-5">
              <div className="o2-typo--weight-important">
                {code ? t('tvsc.payment.discountVoucher.title') : t('tvsc.payment.applyVoucher.title')}
              </div>
            </O2GColumn>
            <O2GColumn xs="1" md="3-5">
              {!code ? (
                <div style={{ display: 'flex' }}>
                  <O2TextField 
                    controlSize="medium"
                    validationText={validation?.text}
                    validationType={validation?.type}
                    indentRight 
                    inline 
                    onChange={(e: any) => setVoucher(e.target.value)} 
                    onKeyDown={(e: any) => handleEnterSubmit(handleSubmit(() => onSubmit(voucher)), e, formState.isSubmitting)}
                  />
                  <div>
                    <O2Button
                      onClick={handleSubmit(() => onSubmit(voucher))}
                      disabled={voucher?.length === 0 || formState.isSubmitting}
                    >
                      {t('tvsc.payment.discountVoucher.submit')}
                    </O2Button>
                    <O2Button 
                      color={'transparent'} 
                      onClick={() => { 
                        setOpen(false); setValidation(undefined) ; 
                      }} 
                      icon={'cross'} 
                    />
                  </div>
                </div>
              ) : (
                <O2Addon>
                  <O2AddonContent>{ code }</O2AddonContent>
                  <O2AddonPane>
                    <div className="o2-typo--link" onClick={onRemove}>
                      <O2Icon name="trash-bin" indentRight />
                      {t('tvsc.payment.discountVoucher.delete')}
                    </div>
                  </O2AddonPane>
                </O2Addon>
              )}
            </O2GColumn>
          </O2G>
        </O2Pane>
      )}
    </>
  );
};

export default Voucher;
