import React, { useState, useEffect } from 'react';

import useAnalytics from '@Hooks/useAnalytics';
import useOrderDetail from '@Modules/orderDetail/useOrderDetail';
import useCodelists from '@rof/Hooks/useCodeLists';

import { O2Addon, O2AddonContent, O2AddonPane, O2Button, O2Indent, O2Layout, O2LayoutColumn, O2Pane, O2Section } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import shortid from 'shortid';

import { IProps } from './types';

const RedirectToPaymentGate: React.FC<IProps> = () => {
  const { AAEPageLoaded } = useAnalytics();
  const { t } = useTranslation();
  const history = useHistory();
  const [ isReadyToRedirect, setIsReadyToRedirect ] = useState(false);
  const { init, data } = useOrderDetail();
  const { getItems } = useCodelists();
  const [ checkOrderStatusInterval ] = getItems('bsl.checkOrderStatusInterval');
  const [ isFirstRequested, setIsFirstRequested ] = useState(false);

  const checkRedirectStatus = () => {
    data?.payments?.forEach((paymentInfo) => {
      if (paymentInfo.paymentStatus === 'NEW' && paymentInfo.linkToPay) {
        setIsReadyToRedirect(true);
        window.location.href = (window as any).g_apfPaymentLocation + paymentInfo.linkToPay;
      }
    });

    // UNEXPECTED STATE OF ORDER
    switch (data?.orderStatus) {
      case 'IN_PROVISIONING':
      case 'SUCCESS':
      case 'FAILED_WITH_REFUND':
      case 'FAILED_WITHOUT_REFUND':
      case 'CANCELED':
        history.push('/payment-result');
        break;
      default: 
        console.log('no unstandard orderStatus');
    }
  };
  
  useEffect(() => {
    if (data && isFirstRequested) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      checkRedirectStatus();
    }
    // eslint-disable-next-line
  }, [ data ]);

  useEffect(() => {
    init(true);
    setIsFirstRequested(true);
    const interval = setInterval(() => {
      init(true);
    }, parseInt(checkOrderStatusInterval));
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    AAEPageLoaded({ pageId: '2.1.2.2', pageTitle: t('tvsc.redirectingForPayment.title')});
    // eslint-disable-next-line
  }, []);

  const paymentRedirect = () => {
    if (isReadyToRedirect) {
      return (
        <O2Indent className="o2-typo--align-center">
          {data!.payments!.map(
            (paymentInfo) =>
              paymentInfo.paymentStatus === 'NEW' &&
              paymentInfo.linkToPay && (
                <O2Button
                  key={shortid.generate()}
                  color="transparent"
                  onClick={() => (window.location.href = (window as any).g_apfPaymentLocation + paymentInfo.linkToPay)}
                >
                  {t('tvsc.redirectingForPayment.goPay')}
                </O2Button>
              )
          )}
        </O2Indent>
      );    
    }
  };

  return (
    <>
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          <O2Section>
            <O2Indent>
              <O2Pane color="brand">
                <O2Addon reversed middle>
                  <O2AddonPane indent>
                    <div style={{ fontSize: '1rem', lineHeight: '2rem' }}>
                      <span className="o2-loader__element o2-loader__element--big"></span>
                    </div>
                  </O2AddonPane>
                  <O2AddonContent>
                    <h3 className="o2-typo--heading">{t('tvsc.redirectingForPayment.title')}</h3>
                  </O2AddonContent>
                </O2Addon>
              </O2Pane>
            </O2Indent>
            {paymentRedirect()}
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
    </>
  );
};

export default RedirectToPaymentGate;
