import { sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { CancelSubscriptionResponse } from '../types';

export const postCancelSubscription = (): AxiosPromise<CancelSubscriptionResponse> => {
  return sendPost<CancelSubscriptionResponse>(
    '/bsl-sc-api/public/v1/subscription/cancel'
  );
};
