
import { Dispatch } from 'react';

import ActionsGetUserServices from '@rof/Actions/getUserServices';
import { getUserServices } from '@rof/Api/cpServices';

export const getUserServiceList = () => {
  return async (dispatch: Dispatch<ActionsGetUserServices>) => {
    dispatch({ 
      type: '[CP-SERVICES - services] START',
    });
    getUserServices().then((response) => {
      if (response.status !== 204) {
        dispatch({ 
          type: '[CP-SERVICES - services] SUCCESS',
          data: response.data,
        });
      } else {
        dispatch({ 
          type: '[CP-SERVICES - services] ERROR',
          error: 'No service',
        });
      }
      
    }).catch((error) => {
      dispatch({ 
        type: '[CP-SERVICES - services] ERROR',
        error,
      });
    });
  };
};
