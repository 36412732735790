import ActionsGetUserServices from '@rof/Actions/getUserServices';
import { IServiceList } from '@rof/Api/cpServices/types';

import { IState } from '../types';

const init: IState<IServiceList> = {
  data: undefined,
  isLoading: false,
  error: undefined,
  lastFetched: undefined,
};

const getUserServicesReducer = (state: IState<IServiceList> = init, action: ActionsGetUserServices) => {
  switch (action.type) {
    case '[CP-SERVICES - services] START':
      return {
        ...state,
        isLoading: true,
      };
    case '[CP-SERVICES - services] SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case '[CP-SERVICES - services] ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: return state;
  }
};

export default getUserServicesReducer;
