import { GetCardDetailResponse } from '@rof/Api/bls-sc/types';
import { IState } from '@rof/Reducers/types';
import { getCardDetail } from '@rof/Thunks/getCardDetail';
import IStore from '@Store/types';

import { useDispatch, useSelector } from 'react-redux';

const useGetCardDetail = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector<IStore, IState<GetCardDetailResponse>>((state) => state.getCardDetail);

  const init = (force = false) => {
    if (((isLoading === false || isLoading === undefined) && data === undefined) || force) {
      dispatch(getCardDetail());
    }
  };

  return {
    init,
    data,
    isLoading,
    error,
  };
};

export default useGetCardDetail;
