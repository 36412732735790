import ActionsGetCardDetail from '@rof/Actions/getCardDetail';
import { GetCardDetailResponse } from '@rof/Api/bls-sc/types';

import { IState } from '../types';

const init: IState<GetCardDetailResponse> = {
  data: undefined,
  isLoading: false,
  error: undefined,
  lastFetched: undefined,
};

const getCardDetailReducer = (state: IState<GetCardDetailResponse> = init, action: ActionsGetCardDetail) => {
  switch (action.type) {
    case '[BLS-SC - getCardDetail] START':
      return {
        ...state,
        isLoading: true,
      };
    case '[BLS-SC - getCardDetail] SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case '[BLS-SC - getCardDetail] ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: return state;
  }
};

export default getCardDetailReducer;
