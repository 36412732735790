import { PERIOD_TO_DAYS } from "@Constants/index";

export const getCookie = (name: string) => {
  const b = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

export const setCookie = (name: string, value: string, domain?: string) => {
  document.cookie = `${name}=${value}; path=/; ${domain ? `domain=${domain}` : ''
  }`;
};

export const periodStringsToDaysNumber = (period: string) => {
  if (period === '10 dní') return 10;
  return PERIOD_TO_DAYS[period as keyof typeof PERIOD_TO_DAYS];
}