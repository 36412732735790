import { REGEX_ISO_STRING } from '@rof/Constants/index';
import useLocalization from '@rof/Hooks/useLocalization';

import { differenceInMonths as diffInMonths, parse, subMonths, addMonths, format, isBefore, addDays } from 'date-fns';

const useDateTime = () => {
  const { lang } = useLocalization();

  const isValidDate = (date: string): boolean => {
    return REGEX_ISO_STRING.test(date);
  };

  const dateFormat = (value: string, withTime = true): string => {
    if (isValidDate(value)) {
      const date = new Date(value);

      if (lang.includes('en')) {
        return format(date, withTime ? 'yyyy/M/d\' \'H:mm' : 'yyyy/M/d');
      }

      return format(date, withTime ? 'd. M. yyyy\' \'H:mm' : 'd. M. yyyy');
    }
     
    return value;
  };

  const beforeDay = (value: string): string => {
    if (isValidDate(value)) {
      const date = new Date(value);
      const bD = date.setDate(date.getDate() - 1);

      return new Date(bD).toISOString();
    }

    console.log('Invalid Date');
    return value;
  };

  const nextDay = (value: string): string => {
    if (isValidDate(value)) {
      const date = new Date(value);
      const nD = date.setDate(date.getDate() + 1);

      return new Date(nD).toISOString();
    }

    console.log('Invalid Date');
    return value;
  };

  const tomorrow = (): string => {
    return nextDay(new Date().toISOString());
  };

  const differenceInDays = (firstValue: string, secondValue: string): number => {
    if (isValidDate(firstValue) && isValidDate(secondValue)) {
      const firstDateTime = new Date(firstValue).getTime();
      const secondDateTime = new Date(secondValue).getTime();

      if (firstDateTime > secondDateTime) {
        return (firstDateTime - secondDateTime) / (1000 * 3600 * 24);
      }
      return (secondDateTime - firstDateTime) / (1000 * 3600 * 24);
    }

    console.log('Invalid Date');
    return -1;
  };

  const differenceInMonths = (firstDate: string, secondDate: string): number => {
    if (isValidDate(firstDate) && isValidDate(secondDate)) {
      return diffInMonths(new Date(firstDate), new Date(secondDate));
    }

    console.log('Invalid Date');
    return -1;
  };

  const parseToISOString = (date: string, dtFormat: string) => {
    try {
      const parsedDate = parse(date, dtFormat, new Date());
      const tzoffset = parsedDate.getTimezoneOffset() * 60000;
      return (new Date(parsedDate.getTime() - tzoffset)).toISOString();
    } catch (e) {
      return;
    }
  };

  return {
    dateFormat,
    tomorrow,
    beforeDay,
    nextDay,
    differenceInDays,
    differenceInMonths,
    parseToISOString,
    addMonths,
    subMonths,
    format,
    isBefore,
    addDays,
  };
};

export default useDateTime;
