import { getCpServicesPath, sendGet } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';
import { IServiceList } from './types';

export const getUserServices = (): AxiosPromise => {
  return sendGet<IServiceList>(
    getCpServicesPath('/core-server/public/v1/services'),
    {serviceTypes: 'TV_PRP'},
    'json'
  );
};
