import { useEffect } from 'react';

import { COMMON_FAIL } from '@Constants/kahl';
import { GetServiceDetailResponse } from '@rof/Api/bls-sc/types';
import { IState } from '@rof/Reducers/types';
import { getServiceDetail } from '@rof/Thunks/getServiceDetail';
import { setKahlMessageId } from '@rof/Thunks/kahl';
import IStore from '@Store/types';

import { useDispatch, useSelector } from 'react-redux';

const useGetServiceDetail = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector<IStore, IState<GetServiceDetailResponse>>((state) => state.getServiceDetail);

  useEffect(() => {
    if (error && error.response && (+error.response.status === 500)) {
      dispatch(setKahlMessageId(COMMON_FAIL));
    }
    // eslint-disable-next-line
  }, [ error ]);

  const init = (force = false) => {
    if (((isLoading === false || isLoading === undefined) && data === undefined) || force) {
      dispatch(getServiceDetail());
    }
  };

  return {
    init,
    data,
    isLoading,
    error,
  };
};

export default useGetServiceDetail;
