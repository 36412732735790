import { sendGet } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { GetServiceDetailResponse } from '../types';

export const getGetServiceDetail = (): AxiosPromise<GetServiceDetailResponse> => {
  return sendGet<GetServiceDetailResponse>(
    '/bsl-sc-api/public/v1/service/getServiceDetail',
  );
};
