import React from 'react';

import { O2Layout, O2LayoutColumn } from 'o2-theme-react';

import { IWithLayout } from './types';

const withLayout = <P extends object>(Component: React.ComponentType<P>) => ({pos, ...props}: P & IWithLayout) => { 
  return (
    <O2Layout>
      {pos ? (
        <O2LayoutColumn pos={pos}>
          <Component {...props as P} />
        </O2LayoutColumn>
      ) : (
        <>
          <Component {...props as P} />
        </>
      )}
    </O2Layout>
  );
};

export default withLayout;
