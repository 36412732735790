export const AUTH_API_BASE = '/auth';
export const CMS_RESOURCE_API_BASE = '/cms-resource/public/v1/resource';
export const CODE_LIST_API_BASE = '/codelist/public/v1/codelist';
export const CAS_TOP_UP_SECURE_REDIRECT = '/cas-external/v1/public/links/topup';
export const LOCALE_API_BASE = '/locale/public/v1/locale';
export const CUSTOMER_CONTEXT_API_BASE = '/customer-context/public/v1/customerContext';
export const KAHL_API_BASE = '/kahl/public/v1/kahl';
export const BFF_API_BASE = '/bff-mock';

export const LOGIN_URL = AUTH_API_BASE + '/casLogin';
export const LOGOUT_URL = AUTH_API_BASE + '/casLogout';
export const REFRESH_JWT_ENDPOINT = '/refreshJwt';
