import React, { useEffect } from 'react';

import { useSteps } from '@rof/Hooks/useSteps';

import shortid from 'shortid';

import Step from './Step';
import IProps from './types';

const Steps: React.FC<IProps> = ({ steps, stepsId, max, startStep }: IProps) => {
  const { step, setStep } = useSteps(stepsId, max);

  useEffect(() => {
    if (startStep) setStep(startStep);
    // eslint-disable-next-line
  }, [ startStep ]);
  
  return (
    <div className="o2-steps" style={{ display: 'inline-flex' }}>
      {steps.map((stepTitle, i) =>
        <Step
          key={shortid.generate()}
          isActive={(step === i)}
          number={i+1}
          title={stepTitle} />
      )}
    </div>
  );
};

export default Steps;
