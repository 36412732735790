import { Dispatch } from 'react';

import ActionsGetServiceDetail from '@rof/Actions/getServiceDetail';
import { getGetServiceDetail } from '@rof/Api/bls-sc/getServiceDetail';

export const getServiceDetail = () => {
  return async (dispatch: Dispatch<ActionsGetServiceDetail>) => {
    dispatch({ 
      type: '[BLS-SC - getServiceDetail] START',
    });
    getGetServiceDetail().then((response) => {
      if (response.status !== 204) {
        dispatch({
          type: '[BLS-SC - getServiceDetail] SUCCESS',
          data: response.data,
        });
      } else {
        dispatch({
          type: '[BLS-SC - getServiceDetail] ERROR',
          error: 'No service',
        });
      }
    }).catch((error) => {
      dispatch({ 
        type: '[BLS-SC - getServiceDetail] ERROR',
        error,
      });
    });
  };
};
