import { useDispatch } from 'react-redux';

const useReduxLoggerActions = () => {
  const dispatch = useDispatch();

  const reduxLog = (message: string) => {
    dispatch({ 
      type: `[LOG] ${message}`,
    });
  };

  return {
    reduxLog,
  };
};

export default useReduxLoggerActions;
