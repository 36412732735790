
import { BalanceHistoryRequest, BalanceHistoryDataSetType, BalanceHistoryPaymentType, BalanceHistoryPaymentStatusType } from '@rof/Api/bff/balanceHistory/types';
import { IServiceList } from '@rof/Api/cpServices/types';
import useCodelists from '@rof/Hooks/useCodeLists';
import { IState } from '@rof/Reducers/types';
import IStore from '@Store/types';

import { useSelector } from 'react-redux';

const useParamsBuilder = (
  page: number,
  paymentType?: BalanceHistoryPaymentType,
  paymentStatus?: BalanceHistoryPaymentStatusType,
  dateFrom?: string,
  dateTo?: string,
): BalanceHistoryRequest => {  
  const { getItems } = useCodelists();
  const [ channel, customerMasterSource, pageSize ] = getItems('bff.common.channel', 'bff.common.customerMasterSource', 'bff.common.pageSize');
  const userServices = useSelector<IStore, IState<IServiceList>>((state) => state.serviceList);

  const getBalanceHistoryParams: BalanceHistoryRequest = {
    channel,
    customerMasterSource,
    deviceId: userServices.data?.services?.[0].serviceId,
    requiredDataSetType: BalanceHistoryDataSetType.XTV_PAYMENT_EVENTS,
    paymentType,
    paymentStatus,
    eventTimeFrom: dateFrom,
    eventTimeTo: dateTo,
    page,
    pageSize,
  };

  return getBalanceHistoryParams;
};

export default useParamsBuilder;
