import ActionsCreateOrderContext from '@rof/Actions/createOrderContext';

import { CreateOrderContext } from './types';

const init: CreateOrderContext = {
  hasServiceSomeServiceButNotPropagatedYet: false,
  hasActiveService: undefined,
  orderPin: undefined,
  productType: undefined,
};

const createOrderContextReducer = (state: CreateOrderContext = init, action: ActionsCreateOrderContext) => {
  switch (action.type) {
    case '[createOrderContext] UPDATE':
      return {
        ...state,
        ...action.data,
      };
    case '[createOrderContext] RESET':
      return init;
    default: return state;
  }
};

export default createOrderContextReducer;
