export const PARENT_LOCK_RESET_SUCCESS = 'ID1442';
export const PARENT_LOCK_RESET_FAIL = 'ID1443';
export const PARENT_LOCK_RESET_INVALID_PASSWORD = 'ID1444';
export const PARENT_LOCK_RESET_USER_DOES_NOT_EXIST = 'ID1445';
export const PARENT_LOCK_RESET_USER_WITH_NO_DOMAIN = 'ID1446';
export const PARENT_LOCK_RESET_USER_SUSPENDED = 'ID1447';
export const SUBMIT_COUPON_VOUCHER_CODE_MISSING = 'ID1448';
export const SUBMIT_COUPON_VOUCHER_FAIL = 'ID1449';
export const ORDER_PIN_RESET_SUCCESS = 'ID1450';
export const ORDER_PIN_RESET_FAIL = 'ID1451';
export const ORDER_PIN_RESET_INVALID_PASSWORD = 'ID1452';
export const ORDER_PIN_RESET_USER_DOES_NOT_EXIST = 'ID1453';
export const ORDER_PIN_RESET_USER_WITH_NO_DOMAIN = 'ID1454';
export const ORDER_PIN_RESET_USER_SUSPENDED = 'ID1455';
export const LOGIN_BAD_CREDENTIALS = 'ID1456';
export const CHANGE_SERVICE_FAIL = 'ID1457';
export const DOWNLOAD_DOCUMENT_FAIL = 'ID1458';
export const CHANGE_PAYMENT_CARD_SUCCESS = 'ID1459';
export const CHANGE_PAYMENT_CARD_FAIL = 'ID1460';
export const RETRY_PAYMENT_SUCCESS = 'ID1461';
export const RETRY_PAYMENT_FAIL = 'ID1462';
export const CANCEL_ORDER_SUCCESS = 'ID1463';
export const CANCEL_ORDER_FAIL = 'ID1464';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'ID1465';
export const CANCEL_SUBSCRIPTION_FAIL = 'ID1466';
export const CREATE_ORDER_SUCCESS = 'ID1467';
export const CREATE_ORDER_FAIL = 'ID1468';
export const COMMON_FAIL = 'ID1469';
export const CHECK_PRODUCT_INVALID_PRODUCT_CODE = 'ID1470';
export const CHECK_PRODUCT_INVALID_VOUCHER_CODE = 'ID1471';
export const CREATE_ORDER_MAX_ATTEMPTS_EXCEEDED = 'ID1472';
export const CREATE_ORDER_ORDER_ALREADY_FINISHED = 'ID1473';
export const CREATE_ORDER_ORDER_CANCELED = 'ID1474';
export const CREATE_ORDER_ORDER_NOT_FOUND = 'ID1475';
export const CANCEL_ORDER_NOT_FOUND = 'ID1476';
export const CANCEL_ORDER_ALREADY_FINISHED = 'ID1477';
export const CANCEL_ORDER_ALREADY_CANCELED = 'ID1478';
export const RETRY_PAYMENT_MAX_ATTEMPTS_EXCEEDED = 'ID1479';
export const RETRY_PAYMENT_ORDER_ALREADY_FINISHED = 'ID1480';
export const CANCEL_ORDER_ORDER_CANCELED = 'ID1481';
export const CANCEL_ORDER_ORDER_NOT_FOUND = 'ID1482';
export const AUTH_INTERNAL_NOT_ALLOWED = 'ID1483';
export const AUTH_PREPAID_ONLY = 'ID1484';
export const AUTH_LOA0_ROLE_REQUIRED = 'ID1485';
export const CREATE_ORDER_OTHER_ORDER_EXISTS = 'ID1486';
