import React from 'react';

import IProps from './types';

const Steps: React.FC<IProps> = ({ isActive, number, title }: IProps) => {
  return (
    <div className={isActive ? 'o2-steps__item o2-steps__item--active' : 'o2-steps__item'}>
      <div className="o2-steps__step-inner">
        <div className="o2-steps__symbol">
          <div className="o2-steps__counter">
            <div className="o2-steps__counter-inner">{ number }.</div>
          </div>
        </div>
        <div className="o2-steps__title">{ title }</div>
      </div>
    </div>
  );
};

export default Steps;
