import ActionSetCodeList from '@rof/Actions/codeList';
import { ICodeList } from '@rof/Api/code-list/types';

const init: ICodeList | null = null;

const codeListReducer = (state: any = init, action: ActionSetCodeList) => {
  switch (action.type) {
    case '[CODE_LIST] SET_ONE':
      return {
        ...state,
        [action.data.codeListName]: action.data.codeListData,
      };
    default: return state;
  }
};

export default codeListReducer;
