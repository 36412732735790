import { useState } from 'react';

import { getCodeList } from '@rof/Api/code-list';
import { DEPENDENT_CODE_LIST } from '@rof/Constants/codeLists';
import { ICodeListProp } from '@rof/Redux/api/code-list/types';
import { setCodeList } from '@rof/Thunks/codelist';
import IStore from '@Store/types';

import { useSelector , useDispatch } from 'react-redux';

const useCodeLists = () => {
  const dispatch = useDispatch();
  const [ areCodelistsFetched, setCodelistsFetched ] = useState(false);
  const codeListValues = useSelector<IStore, any | undefined>(
    (state) => state.codeList
  );
  //get value from codelist for specified codelist and keys
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getSpecifiedItems = (codeList: string,...keys: string[]) => {
    if (!codeListValues) {
      return [];
    }
    const finalCodeListValues: string[] = [];
    //find key in specified codeList
    const actualCodeList = codeListValues[codeList];
    if (!!actualCodeList) {
      keys.map((key) => {
        const foundCodeListValue = actualCodeList[key];
        finalCodeListValues.push(foundCodeListValue);
        if (!foundCodeListValue) {
          console.warn('Key: "' + key +'" not found in CODELIST: "' + codeList + '".');
        }
        return finalCodeListValues;
      });
    }
    return finalCodeListValues;
  };
  //get value from any codelist for specified keys
  const getItems = (...keys: string[]) => {
    //if codelists are available
    if (!codeListValues) {
      return [];
    }
    let valueFound = false;
    const foundInCodeList: string[] = [], codeListValue: any[] = [];
    keys.map((key) => {
      valueFound = false;
      foundInCodeList.length = 0;
      
      //look for key in all codeLists
      DEPENDENT_CODE_LIST.map((codeListName) => {
        const actualCodeList = codeListValues[codeListName];
        if (!!actualCodeList) {
          const foundCodeListValue = actualCodeList[key];
          if (!!(foundCodeListValue) && valueFound) {
            foundInCodeList.push(codeListName);
          } else if (!!(foundCodeListValue)) {
            valueFound = true;
            codeListValue.push(foundCodeListValue);
            foundInCodeList.push(codeListName);
          }
          return foundInCodeList;
        }
        return [];
      });
      //return warning if key has been found in multiple codelists or hasnt been found at all
      if (foundInCodeList.length > 1) {
        const codeListNames = foundInCodeList.map((codeListName) => {
          return codeListName;
        });
        console.error('Found multiple keys for key: "' + key + '". Key found in: ' + codeListNames + '. Specify which CODELIST to use by calling function getSpecifiedItems with codeList parametr: getSpecifiedItems(codeList: string, ...key: string).');
      } else if (!valueFound) {
        const codeListNames = DEPENDENT_CODE_LIST.map((codeListName) => {
          return codeListName;
        });
        console.warn('Key: "' + key +'" not found in codelist(s): ' + codeListNames + '.');
      }
      return codeListValue;
    });
    return codeListValue;
  };

  const initCodelists = () => {
    Object.values(DEPENDENT_CODE_LIST).forEach((value: string, id) => {
      getCodeList(value)
        .then((res: { data: ICodeListProp[]}) => {
          dispatch(setCodeList(value, res.data));
          if (id+1 === DEPENDENT_CODE_LIST.length) {
            setCodelistsFetched(true);
          }
        })
        .catch((err) => console.log(err));
    });
    
  };
  return { initCodelists, areCodelistsFetched, getItems };
};

export default useCodeLists;
