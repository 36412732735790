import React, { useState, useEffect } from 'react';

import Steps from '@Components/blocks/Steps';
import Voucher from '@Components/blocks/Voucher';
import PaymentList from '@Components/sections/PaymentList';
import PaymentType from '@Components/sections/PaymentType';
import useAnalytics from '@Hooks/useAnalytics';
import useCodeLists from '@rof/Hooks/useCodeLists';
import useDateTime from '@rof/Hooks/useDateTime';
import { StepsProvider } from '@rof/Hooks/useSteps';
import { periodStringsToDaysNumber } from '@Utils/utils';

import {
  O2Layout,
  O2LayoutColumn,
  O2Section,
  O2Indent,
  O2Pane,
  O2Button,
  O2Addon,
  O2AddonContent,
  O2AddonPane,
  O2Checkbox,
  O2InfoMessage,
} from 'o2-theme-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import usePaymentData from './module/usePaymentData';
import { IProps } from './types';
import withPaymentContext from './withPaymentContext';

const Payment: React.FC<IProps> = ({ match }: IProps) => {
  const { AAEPageLoaded } = useAnalytics();
  const { t } = useTranslation();
  const history = useHistory();
  const { id, voucherCode } = match.params;
  const { dateFormat, addDays } = useDateTime();
  const [ isAgree, setIsAgree ] = useState(false);
  const { format } = useDateTime();
  const { 
    data,
    submitPayment,
    submitVoucher,
    paymentsData,
    voucher,
    card,
    totalPayment,
    createOrderContext,
  } = usePaymentData(id, voucherCode);
  const { formState, handleSubmit } = useForm();
  const { getItems } = useCodeLists();
  const [ savedCardHidden ] = getItems('savedCardHidden');

  useEffect(() => {
    if (voucherCode) submitVoucher(voucherCode);
    if (savedCardHidden === 'true') card.setSelectedCard('newCard');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsAgree(false);
    // eslint-disable-next-line
  }, [ card.selectedCard ]);

  useEffect(() => {
    AAEPageLoaded({ pageId: '2.1.2', pageTitle: t('tvsc.payment.title')});
  // eslint-disable-next-line
}, []);

  return (
    <StepsProvider>
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          <O2Section className="o2-typo--align-center">
            <Steps stepsId={'payments'} steps={[ t('tvsc.steps.createAccount'), t('tvsc.steps.payment') ]} startStep={1} />
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          <O2Section className="o2-typo--align-center">
            <O2Indent>
              <h1 className="o2-typo--heading">{t('tvsc.payment.title')}</h1>
            </O2Indent>
            <O2Indent className="o2-typo--heading-h3 o2-typo--color-secondary" dangerouslySetInnerHTML={{__html: t('tvsc.payment.subtitle1')}}>
            </O2Indent>
            <O2Indent className="o2-typo--heading-h3 o2-typo--color-secondary" dangerouslySetInnerHTML={{__html: t('tvsc.payment.subtitle2')}}>
            </O2Indent>
          </O2Section>
          <O2Section>
            <O2Indent>
              <O2Pane color="white">
                <PaymentList payments={paymentsData} />
                {data.checkProductData?.recurrency ? (
                  <></>
                ) : (
                  <O2InfoMessage color="neutral">
                    <O2Indent>
                      {t('tvsc.payment.nonrecurrency', {
                        date: dateFormat(
                          addDays(
                            new Date(),
                            data.checkProductData?.servicePeriod ? periodStringsToDaysNumber(data.checkProductData?.servicePeriod) : 0
                          ).toISOString(),
                          false
                        ),
                      })}
                    </O2Indent>
                  </O2InfoMessage>
                )}
              </O2Pane>
            </O2Indent>
            <O2Indent>
              <Voucher
                code={voucher.value}
                onRemove={() => voucher.clear()}
                onSubmit={submitVoucher}
                voucherValidation={voucher.validation}
              />
            </O2Indent>
            <O2Indent>
              {data.getCardDetailData?.cardNumber && savedCardHidden !== 'true' ? (
                <PaymentType
                  selectedCard={card.selectedCard}
                  setSelectedCard={card.setSelectedCard}
                  savedCard={`${data.getCardDetailData?.cardNumber} (${format(new Date(data.getCardDetailData?.cardValidDate ?? ''), 'MM/yy')})`}
                  totalPayment={
                    (data.checkProductData?.servicePrice ? data.checkProductData?.servicePrice : 0) -
                    (voucher && data.checkProductData?.serviceDiscount ? data.checkProductData?.serviceDiscount : 0)
                  }
                />
              ) : null}
            </O2Indent>
            {data.getCardDetailData?.cardNumber && card.selectedCard === 'savedCard' && (
              <O2Indent>
                <O2Checkbox
                  label={t(`tvsc.payment.iAgreeRepeatable${data.checkProductData?.recurrency ? 'A' : 'B'}`)}
                  onChange={() => setIsAgree(!isAgree)}
                  checked={isAgree}
                />
              </O2Indent>
            )}
            {!data.getCardDetailData?.cardNumber || card.selectedCard === 'newCard' || savedCardHidden === 'true' ? (
              !data.checkProductData?.recurrency ? (
                <O2Indent>
                  <O2Checkbox
                    label={t('tvsc.payment.iAgreeGoPayOnce')}
                    onChange={() => setIsAgree(!isAgree)}
                    checked={isAgree}
                  />
                </O2Indent>
              ) : (
                <O2Indent>
                  <O2Checkbox
                    label={t('tvsc.payment.iAgreeGoPayRepeatable')}
                    onChange={() => setIsAgree(!isAgree)}
                    checked={isAgree}
                  />
                </O2Indent>
              )
            ) : null}
          </O2Section>
          <O2Section>
            <O2Addon breakpoint="sm" reversed>
              <O2AddonContent className="o2-typo--align-right-md">
                {totalPayment ? (
                  <O2Button
                    onClick={handleSubmit(submitPayment)}
                    disabled={
                      !data.getCardDetailData?.cardNumber
                        ? !isAgree || formState.isSubmitting
                        : !(card.selectedCard && isAgree) || formState.isSubmitting
                    }
                    color="primary"
                  >
                    {t('tvsc.payment.submitPayment', { payment: Math.floor(totalPayment) })}
                  </O2Button>
                ) : null}
                {!data.getCardDetailData?.cardNumber && totalPayment === 0 && (
                  <O2Button
                    color={'primary'}
                    onClick={handleSubmit(submitPayment)}
                    disabled={(!isAgree || formState.isSubmitting)}
                  >
                    {t('tvsc.payment.verifyCard')}
                  </O2Button>
                )}
                {data.getCardDetailData?.cardNumber && card.selectedCard === 'savedCard' && totalPayment === 0 && (
                  <O2Button
                    color={'primary'}
                    onClick={handleSubmit(submitPayment)}
                    disabled={(!isAgree || formState.isSubmitting)}
                  >
                    {t('tvsc.payment.apply')}
                  </O2Button>
                )}
              </O2AddonContent>
              <O2AddonPane indent middle className="o2-typo--align-center">
                {createOrderContext.hasActiveService && createOrderContext.orderPin && (
                  <O2Button onClick={() => history.goBack()} color="transparent" icon="chevron-left" firstLink>
                    {t('tvsc.payment.back')}
                  </O2Button>
                )}
              </O2AddonPane>
            </O2Addon>
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
    </StepsProvider>
  );
};

export default withPaymentContext(Payment, true);
