import React, { useState, useEffect } from 'react';

import Subheader from '@Components/sections/Subheader';
import useAnalytics from '@Hooks/useAnalytics';
import useDateTime from '@rof/Hooks/useDateTime';
import { handleEnterSubmit } from '@rof/Utils/index';

import { O2Layout, O2LayoutColumn, O2Section, O2Button, O2Loader, O2AddonPane } from 'o2-theme-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useChangePaymentCardData from './module/useChangePaymentCardData';
import LoaderWrapper from './styles';
import { IProps } from './types';
import WithoutRecurrence from './WithoutRecurrence';
import WithRecurrence from './WithRecurrence';

const ChangePayment: React.FC<IProps> = () => {
  //AA prepare wf: 1.4.4, pageTitle: 
  const [ isAgreeGoPay, setIsAgreeGoPay ] = useState(false);
  const { t } = useTranslation();
  const { dateFormat } = useDateTime();
  const { data, paymentsData, submitVerificationPayment } = useChangePaymentCardData();
  const { formState, handleSubmit } = useForm();
  const { AAEPageLoaded } = useAnalytics();

  useEffect(() => {
    AAEPageLoaded({ pageId: '1.1.2.2.1', pageTitle: t('tvsc.paymentCardChange.title') });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Subheader headingText={t('tvsc.paymentCardChange.title')} backlinkText={t('tvsc.paymentCardChange.back')} pos="center" />
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          {data ? (
            data?.recurrence ? (
              <WithRecurrence
                isAgreeGoPay={isAgreeGoPay}
                onAgreeGoPay={() => setIsAgreeGoPay(!isAgreeGoPay)}
                payments={paymentsData}
                nextPaymentDate={dateFormat(data.nextPaymentDate ?? '', false)}
                onKeyDown={(e: any) => handleEnterSubmit(handleSubmit(submitVerificationPayment), e, formState.isSubmitting)}
              />
            ) : (
              <WithoutRecurrence isAgreeGoPay={isAgreeGoPay} onAgreeGoPay={() => setIsAgreeGoPay(!isAgreeGoPay)} />
            )
          ) : (<LoaderWrapper><O2Loader /></LoaderWrapper>)}
          <O2Section>
            <O2AddonPane indent right className="o2-typo--align-right">
              <O2Button onClick={handleSubmit(submitVerificationPayment)} disabled={!isAgreeGoPay || formState.isSubmitting} color="primary">
                {t('tvsc.paymentCardChange.submitVerificationPayment')}
              </O2Button>
            </O2AddonPane>
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
    </>
  );
};

export default ChangePayment;
