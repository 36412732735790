import { Dispatch } from 'react';

import ActionsGetCardDetail from '@rof/Actions/getCardDetail';
import { getGetCardDetail } from '@rof/Api/bls-sc/getCardDetail';

export const getCardDetail = () => {
  return async (dispatch: Dispatch<ActionsGetCardDetail>) => {
    dispatch({ 
      type: '[BLS-SC - getCardDetail] START',
    });
    getGetCardDetail().then((response) => {
      dispatch({ 
        type: '[BLS-SC - getCardDetail] SUCCESS',
        data: response.data,
      });
    }).catch((error) => {
      dispatch({ 
        type: '[BLS-SC - getCardDetail] ERROR',
        error,
      });
    });
  };
};
