import React, { useEffect } from 'react';

import {SERVICE_DETAIL} from '@Constants/index';

import { useHistory } from 'react-router-dom';

// @types
import IProps from './types';

const Page404: React.FC<IProps> = () => {
  const history = useHistory();

  useEffect(() => {
    history.push(SERVICE_DETAIL);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h1>Page not found!</h1>
    </>
  );
};

export default Page404;
