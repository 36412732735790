import {LOGIN_URL as XTV_LOGIN_URL} from '@Constants/index';
import { LOGIN_URL, KAHL_API_BASE } from '@rof/Constants/api';
import isSlugDeeplink from '@Utils/isSlugDeeplink';

import { getApiPath, buildURLQuery, getServicesPath } from '../requests';

export const redirectToLoginWithSettings = (settings: object, apiLocation = LOGIN_URL): void => {
  window.location.href = getApiPath(apiLocation + buildURLQuery(settings));
};

//another redirect like othres
export const redirectToXtvLoginPage = (): void => {
  if (!isSlugDeeplink()) {
    window.location.href = window.location.origin + XTV_LOGIN_URL + `?returnTo=${window.location.pathname}`;
  }
};

export const redirectToLoginPageForced = (
  authLocation = LOGIN_URL,
): void => {
  const loginSetting = {
    redirectTo: window.location.href,
    internalLogin: 'true',
    forceLogin: 'true',
  };

  redirectToLoginWithSettings(loginSetting, authLocation);
};

export const redirectToLoginPageExternalForced = (
  authLocation = LOGIN_URL,
): void => {
  const loginSetting = {
    redirectTo: window.location.href,
    internalLogin: 'false',
    forceLogin: 'true',
  };

  redirectToLoginWithSettings(loginSetting, authLocation);
};

export const redirectToLoginPageCheckOnly = (
  authLocation = LOGIN_URL,
): void => {
  const loginSetting = {
    redirectTo: window.location.href,
    internalLogin: 'false',
    checkOnly: 'true',
  };

  redirectToLoginWithSettings(loginSetting, authLocation);
};

export const redirectToStandaloneKahl = (id: string, appId: string) => {
  
  window.location.href = getServicesPath(`${KAHL_API_BASE}/standalone-message/?appId=${appId}&msgId=${id}`);
};
