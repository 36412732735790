import React, { useEffect, useState } from 'react';



import { O2Button, O2Indent, O2Layout, O2Pane, O2Section, O2LayoutColumn } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';
import { getCookie, setCookie } from '@Utils/utils';

const CookieAcceptance = () => {
  const { t } = useTranslation();
  const [ isAccepted, setIsAccepted ] = useState<boolean>(true);

  useEffect(() => {
    if (getCookie('cookie-agree') === 'true') {
      setIsAccepted(true);
    } else {
      setIsAccepted(false);
    }
  }, [ ]);

  if (isAccepted) return <></>;

  return (
    <O2Layout style={{
      left: 0,
      bottom: 0,
      width: '100%',
      zIndex: 9999999,
      position: 'fixed',
      margin: 0,
    }}>
      <O2LayoutColumn func="main" pos="center">
        <O2Pane style={{ backgroundColor: '#006', color: 'white' }}>
          <O2Section style={{ display: 'inline-flex'}}>
            <O2Indent>
              <b>{t('tvsc.cookieAcceptance.descriptionHeader')}</b><br />
              {t('tvsc.cookieAcceptance.description')}
            </O2Indent>
            <O2Indent>
              <O2Button
                color="internal-alt"
                style={{
                  color: '#006ed2',
                  backgroundColor: '#f4f9fd',
                  borderColor: '#f4f9fd',
                }}
                onClick={() => {
                  setIsAccepted(true);
                  setCookie('cookie-agree', 'true', (window as any).g_apfSessionCookieDomain);
                }}
              >
                {t('tvsc.cookieAcceptance.accept')}
              </O2Button>
            </O2Indent>
          </O2Section>
        </O2Pane>
      </O2LayoutColumn>
    </O2Layout>
  );
};

export default CookieAcceptance;
