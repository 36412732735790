import React from 'react';

import { O2Pane, O2Indent, O2Badge, O2Icon, O2Loader } from 'o2-theme-react';
import { EqualHeightElement } from 'react-equal-height';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';

import LoaderWrapper from './styles';
import { IProps, Color } from './types';

const TariffBox: React.FC<IProps> = ({ type, tariffName, description, paidDay, loading }: IProps) => {
  const { t } = useTranslation();
  
  const typesToColor = {
    old: 'danger',
    new: 'success',
  };

  if (loading) return <O2Pane color="white"><LoaderWrapper><O2Loader/></LoaderWrapper></O2Pane>;

  return (
    <O2Pane color="white">
      <O2Indent>
        <O2Badge color={typesToColor[type] as Color}>
          { type === 'old' ? t('tvsc.changeService.actualService') : t('tvsc.changeService.newService') }
        </O2Badge>
      </O2Indent>
      <O2Indent>
        <div className="o2-typo--heading-h3">{tariffName}</div>
      </O2Indent>
      {description &&
      <O2Indent top="close">
        <EqualHeightElement name={'box'}>
          {description.map((item) => {
            return item && 
              <O2Indent key={shortid.generate()} top="close">
                <O2Icon name="checkmark" indentRight />
                {item}
              </O2Indent>; 
          }
          )}
        </EqualHeightElement>
      </O2Indent>
      }
      <O2Indent>{paidDay}</O2Indent>
    </O2Pane>
  );
};

export default TariffBox;
