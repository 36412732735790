import styled from 'styled-components';

const CreditCardMain = styled.div`
  max-width: 290px;
`;

const CreditCardWrapper = styled.div`
  position: relative;
  padding-top: 62.8%;
  background: #ffffff;
  box-shadow: 0px 10px 15px #E7E9F2;
  border-radius: 10px;
`;

const CreditCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 16px;
  right: 16px;
  top: 16px;
  bottom: 16px;
`;

const CreditCardLogo = styled.div`
  flex: 0 0 auto;
  text-align: right;
`;

const CreditCardText = styled.div`
  flex: 0 0 auto;
`;

const CardName = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  font-size: 28px;
  line-height: 28px;
`;

export { CreditCardWrapper, CreditCardContent, CreditCardLogo, CreditCardText, CardName };
export default CreditCardMain;
