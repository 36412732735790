import ActionSetKahlMessageId from '@rof/Actions/kahlInfo';
import { IKahlIdStore } from '@rof/Api/kahl/types';

const init: IKahlIdStore = {
  kahlMessageId: null,
  timestamp: Date.now(),
};

const kahlReducer = (state: any = init, action: ActionSetKahlMessageId) => {
  switch (action.type) {
    case '[KAHL] SET_MESSAGE_ID':
      return {
        ...state,
        kahlMessageId: action.data,
        timestamp: Date.now(),
      };
    default: return state;
  }
};

export default kahlReducer;
