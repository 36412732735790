import React from 'react';

import { O2Section, O2Indent, O2Pane, O2Addon, O2AddonPane, O2Icon, O2AddonContent } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';

import IProps from './types';

const ValidVoucher: React.FC<IProps> = ({ voucherDescription }: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <O2Section>
        <O2Pane color="success">
          <O2Addon reversed>
            <O2AddonPane indent>
              <O2Icon
                name="checkmark-fill"
                color="success"
                size="xl"
                style={{
                  lineHeight: 0.75,
                }}
              />
            </O2AddonPane>
            <O2AddonContent>
              <O2Indent>
                <div className="o2-typo--heading-h2">{t('tvsc.applyVoucher.success.title')}</div>
              </O2Indent>
              <O2Indent top="close">
                {voucherDescription}
              </O2Indent>
            </O2AddonContent>
          </O2Addon>
        </O2Pane>
      </O2Section>
    </>
  );
};

export default ValidVoucher;
