import React from 'react';

import { O2Addon, O2AddonContent, O2AddonPane, O2Button, O2Icon, O2Indent, O2Pane } from 'o2-theme-react';
import { useForm } from 'react-hook-form';

import { IProps, Color } from './types';

const PaymentConfirmation: React.FC<IProps> = ({ type, textTitle, textDetail, textCTA, onClickCTA }: IProps) => {
  const typeToColor = {
    error: 'danger',
    inProgress: 'warning',
    success: 'success',
  };

  const typeToIcon = {
    error: 'exclamation-triangle',
    success: 'checkmark-fill',
  };

  const { formState, handleSubmit } = useForm();
  
  return (
    <O2Pane color={typeToColor[type] as Color}>
      <O2Addon reversed middle>
        <O2AddonPane indent>
          <div style={{ fontSize: '1rem', lineHeight: '2rem' }}>
            { type === 'inProgress'
              ? <span className="o2-loader__element o2-loader__element--big"></span>
              : <O2Icon name={typeToIcon[type]} size="xl" color={type === 'success' ? type : undefined} />
            }
          </div>
        </O2AddonPane>
        <O2AddonContent>
          <O2Indent>
            <h3 className="o2-typo--heading">{textTitle}</h3>
          </O2Indent>
          {textDetail &&
            <O2Indent top="close">{textDetail}</O2Indent>
          }
          {onClickCTA && textCTA &&
            <O2Indent>
              <O2Button color="primary" onClick={handleSubmit(onClickCTA)} disabled={formState.isSubmitting}>{textCTA}</O2Button>
            </O2Indent>
          }
        </O2AddonContent>
      </O2Addon>
    </O2Pane>
  );
};

export default PaymentConfirmation;
