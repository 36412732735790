import React from 'react';

import { O2Section, O2Indent, O2Icon } from 'o2-theme-react';
import { Link, useHistory } from 'react-router-dom';

import IProps from './types';
import withLayout from './withLayout';

const Subheader = ({ backlinkHref, backlinkText, headingText, perex }: IProps) => {
  const history = useHistory();
  const webview = window.localStorage.getItem('webview');

  const handleGoBack = (e: any) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <O2Section>
      {webview !== 'true' && 
        <O2Indent>
          <Link id={'link'} onClick={(e: any) => !backlinkHref && handleGoBack(e)} to={backlinkHref ?? '#'}>
            <O2Icon name="chevron-left" indentRight />
            {backlinkText}
          </Link>
        </O2Indent>
      }
      <O2Indent>
        <h1 className="o2-typo--heading">{headingText}</h1>
      </O2Indent>
      {perex &&
        <O2Indent top="close">{perex}</O2Indent>
      }
    </O2Section>
  );
};

export default withLayout(Subheader);
