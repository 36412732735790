import React from 'react';

import { O2Icon, O2UncontrolledTooltip } from 'o2-theme-react';
import shortid from 'shortid';

import IProps from './types';

const Tooltip: React.FC<IProps> = ({ prefix, sufix, children, position }: IProps) => {
  const id = `id${shortid.generate()}`;
  
  return (
    <>
      {prefix && `${prefix} `}
      <O2Icon id={id} name="info-fill" />
      <O2UncontrolledTooltip placement={position} target={id}>
        {children}
      </O2UncontrolledTooltip>
      {sufix && ` ${sufix}`}
    </>
  );
};

export default Tooltip;
