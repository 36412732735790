import { getLangTexts } from '@rof/Api/cms-resource';
import { IResource } from '@rof/Api/cms-resource/types';
import { APP_ID, DEFAULT_LANGUAGE } from '@rof/Constants/index';
import translationsCs from '@Translations/cs';
import translationsEn from '@Translations/en';

import { AxiosResponse } from 'axios';
import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

export const mergeTextTranslations = (serverTexts: object | any, targetTexts: object | any): Resource => {
  if (
    !serverTexts ||
    !serverTexts[APP_ID] ||
    !serverTexts[APP_ID]['<ANY>']
  ) {
    return targetTexts;
  }
  const remoteTranslations = serverTexts[APP_ID]['<ANY>'];

  Object.getOwnPropertyNames(remoteTranslations).forEach((lang: string) => {
    const langTexts: any = remoteTranslations[lang];

    let targetLangTexts = targetTexts[lang];
    
    if (!targetLangTexts) {
      targetLangTexts = targetTexts[lang] = {};
    }

    Object.getOwnPropertyNames(langTexts).forEach((key) => {
      let target = targetLangTexts;
      const keyAdapter = 'translation.' + key;
      const keyParts = keyAdapter.split('.');

      keyParts.forEach((keyPart, index) => {
        if (index === keyParts.length - 1) {
          target[keyPart] = langTexts[key];
        } else {
          if (!target[keyPart]) {
            target[keyPart] = {};
          }
          target = target[keyPart];
        }
      });
    });
  });

  return targetTexts;
};

const initI18n = (translations: Resource): void => {
  i18n
    .use(initReactI18next)
    .init({
      resources: translations,
      lng: DEFAULT_LANGUAGE,
      interpolation: {
        escapeValue: false,
      },
    });
};

export const initLangTexts = (): Promise<void> => {
  const appLocalTranslations: Resource = { 
    cs: {
      translation: translationsCs,
    },
    en: {
      translation: translationsEn,
    },
  };

  return getLangTexts()
    .then((res: AxiosResponse<IResource>): void => {
      const mergedTranslations = mergeTextTranslations(res.data, appLocalTranslations);
      const availableTranslations: any = {};

      Object.getOwnPropertyNames(mergedTranslations).forEach(
        (lang: string) => (availableTranslations[lang] = true),
      );

      initI18n(mergedTranslations);
    })
    .catch((err: object | string): void => {
      console.error('Failed to load lang texts: ', err);
      initI18n(appLocalTranslations);
    });
};
