import { sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { CheckOrderPinRequest, CheckOrderPinResponse } from '../types';

export const postCheckOrderPin = (
  requestBody: CheckOrderPinRequest
): AxiosPromise<CheckOrderPinResponse> => {
  return sendPost<CheckOrderPinResponse>(
    '/bsl-sc-api/public/v1/subscription/checkOrderPin',
    null,
    requestBody
  );
};
