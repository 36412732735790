import React from 'react';

import { O2Modal, O2ModalBody, O2ModalFooter, O2ModalFooterButton, O2ModalBodyMessage, O2ModalBodyNote } from 'o2-theme-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IProps } from './types';

const ConfirmModal: React.FC<IProps> = ({isOpen, onCancel, onConfirm, paidValidToDate}: IProps) => {
  const { t } = useTranslation();
  const { formState, handleSubmit } = useForm();
  
  return (
    <O2Modal isOpen={isOpen} toggle={onCancel}>
      <O2ModalBody>
        <O2ModalBodyMessage>{t('tvsc.serviceDetail.modalCancelSubscription.title')}</O2ModalBodyMessage>
        <O2ModalBodyNote>{t('tvsc.serviceDetail.modalCancelSubscription.paidValidToDate', { date: paidValidToDate })}</O2ModalBodyNote>
      </O2ModalBody>
      <O2ModalFooter>
        <O2ModalFooterButton onClick={handleSubmit(onConfirm)} disabled={formState.isSubmitting}>{t('tvsc.serviceDetail.modalCancelSubscription.cancelSubscription')}</O2ModalFooterButton>
        <O2ModalFooterButton onClick={onCancel}>{t('tvsc.serviceDetail.modalCancelSubscription.back')}</O2ModalFooterButton>
      </O2ModalFooter>
    </O2Modal>
  );
};

export default ConfirmModal;
