import React, { useState, useContext } from 'react';

import { IStepsProvider, IStepsContext, IStep } from './types';

const StepsContext = React.createContext<IStepsContext | undefined>(undefined);

const StepsProvider = ({ children }: IStepsProvider) => {
  const [ steps, setSteps ] = useState<IStep[]>([]);

  const updateSteps = (contextId: string, value: number) => {
    setSteps((prevState) =>
      prevState.map((item) => {
        if (item.id === contextId) return { ...item, step: value, previousStep: item.step };
        return item;
      })
    );
  };

  const newStep = (contextId: string, value: number, max: number) => {
    setSteps((prevState) => {
      prevState.push({ id: contextId, step: value, previousStep: -1, max });
      return prevState;
    });
  };

  return <StepsContext.Provider value={{ steps, updateSteps, newStep }}>{children}</StepsContext.Provider>;
};

const useSteps = (contextId: string, max?: number) => {
  const context = useContext(StepsContext);
  if (context === undefined) throw new Error('useSteps must be used within a StepsProvider');

  let stepContext = context.steps.filter((stpContext) => stpContext.id === contextId);
  if (!stepContext.length) {
    context.newStep(contextId, 0, max ?? Number.MAX_VALUE);
    stepContext = context.steps.filter((stpContext) => stpContext.id === contextId);
  }

  const step = () => stepContext.length ? stepContext[0].step : 0;

  const previousStep = () => stepContext.length ? stepContext[0].previousStep : -1;

  const setStep = (value: number) => {
    if (value < stepContext[0].max && value > -1) {
      context.updateSteps(contextId, value);
    }
  };

  return {
    step: step(),
    previousStep: previousStep(),
    setStep,
  };
};

export { StepsProvider, useSteps };
