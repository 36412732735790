/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { 
  RETRY_PAYMENT_SUCCESS, 
  RETRY_PAYMENT_FAIL, 
  CANCEL_ORDER_SUCCESS, 
  CANCEL_ORDER_FAIL, 
  CANCEL_ORDER_NOT_FOUND, 
  CANCEL_ORDER_ALREADY_FINISHED, 
  CANCEL_ORDER_ALREADY_CANCELED,
  RETRY_PAYMENT_MAX_ATTEMPTS_EXCEEDED, 
  RETRY_PAYMENT_ORDER_ALREADY_FINISHED, 
  CANCEL_ORDER_ORDER_CANCELED, 
  CANCEL_ORDER_ORDER_NOT_FOUND } from '@Constants/kahl';
import useOrderDetail from '@Modules/orderDetail/useOrderDetail';
import { postCancelOrder } from '@rof/Api/bls-payment/cancelOrder';
import { putRetryPayment } from '@rof/Api/bls-payment/retryPayment';
import { RetryPaymentResponse } from '@rof/Api/bls-payment/types';
import useCodelists from '@rof/Hooks/useCodeLists';
import useReduxLoggerActions from '@rof/Hooks/useReduxLoggerActions';
import { setKahlMessageId } from '@rof/Thunks/kahl';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const usePaymentWaitingData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { reduxLog } = useReduxLoggerActions();
  const [ retryPayment, setRetryPayment ] = useState<RetryPaymentResponse>();
  const [ isFirstRequested, setIsFirstRequested ] = useState(false);
  const { init, data, isLoading, error } = useOrderDetail();
  const { getItems } = useCodelists();
  const [ prepaidO2TVTariffs, checkOrderStatusInterval ] = getItems('redirect.o2.prepaidO2TVTariffs', 'bsl.checkOrderStatusInterval');

  useEffect(() => {
    init(true);
    setIsFirstRequested(true);
    const interval = setInterval(() => {
      init(true);
    }, parseInt(checkOrderStatusInterval));
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && isFirstRequested) {
      switch (data?.orderStatus) {
        case 'IN_PROVISIONING':
        case 'SUCCESS':
        case 'FAILED_WITH_REFUND':
        case 'FAILED_WITHOUT_REFUND':
        case 'CANCELED':
          history.push('/payment-result');
          break;
        default: 
          console.log('no unstandard orderStatus');
      }
    }
    // eslint-disable-next-line;
  }, [ data ]);

  const onActiveDifferent = () => {
    if (data?.orderId) {
      reduxLog('postCancelOrder START');
      postCancelOrder({ orderId: data?.orderId })
        .then(({data}) => {
          if (data.success) {
            reduxLog('postCancelOrder SUCCESS'); 
            dispatch(setKahlMessageId(CANCEL_ORDER_SUCCESS));
            window.location.href = prepaidO2TVTariffs;
          } else {
            switch (data.failureReason) {
              case 'ORDER_NOT_FOUND':
                dispatch(setKahlMessageId(CANCEL_ORDER_NOT_FOUND));
                break;
              case 'ORDER_ALREADY_FINISHED':
                dispatch(setKahlMessageId(CANCEL_ORDER_ALREADY_FINISHED));
                break;
              case 'ORDER_ALREADY_CANCELED':
                dispatch(setKahlMessageId(CANCEL_ORDER_ALREADY_CANCELED));
                break;
              default:
                dispatch(setKahlMessageId(CANCEL_ORDER_FAIL));
                break;
            }
          }
        })
        .catch(() => {
          reduxLog('postCancelOrder FAIL');
          dispatch(setKahlMessageId(CANCEL_ORDER_FAIL));
        }); 
    } 
  };

  const onRetryPayment = async () => {
    if (data?.payments?.some((paymentInfo) => paymentInfo.paymentStatus === 'NEW' && paymentInfo.linkToPay)) {
      if (data?.usedStoredCard) {
        history.push('/payment-waiting');
      } else {
        history.push('/redirecting-for-payment');
      }
    } else {
      if (data?.orderId) {
        try {
          reduxLog('putRetryPayment START');
          const result = await putRetryPayment({ orderId: data?.orderId });
          setRetryPayment(result.data);
          if (result.data.success) {
            reduxLog('putRetryPayment SUCCESS');
            dispatch(setKahlMessageId(RETRY_PAYMENT_SUCCESS));
            if (data?.usedStoredCard) {
              history.push('/payment-waiting');
            } else {
              history.push('/redirecting-for-payment');
            }
          } else {
            switch (result.data.failureReason) {
              case 'MAX_ATTEMPTS_EXCEEDED':
                dispatch(setKahlMessageId(RETRY_PAYMENT_MAX_ATTEMPTS_EXCEEDED));
                break;
              case 'ORDER_ALREADY_FINISHED':
                dispatch(setKahlMessageId(RETRY_PAYMENT_ORDER_ALREADY_FINISHED));
                break;
              case 'ORDER_CANCELED':
                dispatch(setKahlMessageId(CANCEL_ORDER_ORDER_CANCELED));
                break;
              case 'ORDER_NOT_FOUND':
                dispatch(setKahlMessageId(CANCEL_ORDER_ORDER_NOT_FOUND));
                break;
              default:
                dispatch(setKahlMessageId(RETRY_PAYMENT_FAIL));
                break;
            }
          } 
        } catch (error) {
          reduxLog('putRetryPayment FAIL');
          dispatch(setKahlMessageId(RETRY_PAYMENT_FAIL));
        }
      }
    }
  };

  return {
    init,
    data,
    isLoading,
    error,
    onActiveDifferent,
    onRetryPayment,
    retryPayment,
    setRetryPayment,
  };
};

export default usePaymentWaitingData;
