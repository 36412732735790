import React from 'react';

import useLocalization from '@rof/Hooks/useLocalization';

import { useTranslation } from 'react-i18next';

import IProps from './types';

const Footer: React.FC<IProps> = ({ setLoader }: IProps) => {
  const { t } = useTranslation();
  const { setLang, lang } = useLocalization();

  return (
    <div className="o2-footer">
      <div className="o2-footer__width">
        <ul className="o2-footer__menu">
          <li className="o2-footer__menu-item">
            <a href="/" className="o2-footer__link" onClick={(e) => {
              e.preventDefault();
              setLoader(true);
              setLang(lang === 'cs' ? 'en': 'cs', () => {
                document.location.reload();
              });
            }}>{t('tvsc.footer.languageToSwitch')}</a>
          </li>
          <li className="o2-footer__menu-item"><a href={t('tvsc.footer.links.careAndSupport')} className="o2-footer__link">{t('tvsc.footer.careAndSupport')}</a></li>
          <li className="o2-footer__menu-item"><a href={t('tvsc.footer.links.privacy')} className="o2-footer__link">{t('tvsc.footer.privacy')}</a></li>
          <li className="o2-footer__menu-item"><a href={t('tvsc.footer.links.about')} className="o2-footer__link">{t('tvsc.footer.about')}</a></li>
          <li className="o2-footer__menu-item"><a href={t('tvsc.footer.links.contacts')} className="o2-footer__link">{t('tvsc.footer.contacts')}</a></li>
          <li className="o2-footer__menu-item"><a href={t('tvsc.footer.links.newsInMyO2')} className="o2-footer__link">{t('tvsc.footer.newsInMyO2')}</a></li>
          <li className="o2-footer__menu-item"><a href={t('tvsc.footer.links.downloadMyO2')} className="o2-footer__link">{t('tvsc.footer.downloadMyO2')}</a></li>
        </ul>
        <div className="o2-footer__copy">&#169; O2 Czech Republic a.s.</div>
      </div>
    </div>
  );
};

export default Footer;
