export const getParams = () => {
  const params = new URLSearchParams(window.location.search);

  const movie = params.get('movie');
  const tariff = params.get('tariff');
  const content = params.get('content');
  const coupon = params.get('coupon');
  const hh = params.get('hh');
  const returnUrl = params.get('returnUrl');
  const webview = params.get('webview');
  const locale = params.get('locale');

  return {
    tariff,
    movie,
    content,
    coupon,
    hh,
    returnUrl,
    webview,
    locale,
  };
};
