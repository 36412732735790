import React from 'react';

import tagTracker from '@rof/Utils/tagTracker';

import { BrowserRouter } from 'react-router-dom';

import Router from './router/router';
import CookieAcceptance from '@Sections/CookieAcceptance';
import MigrateModal from '@Sections/MigrateModal';

//This will show release tag in console in browser
tagTracker();
const App = () => {
  return (
    <BrowserRouter>
      <Router />
      <MigrateModal />
      <CookieAcceptance />
    </BrowserRouter>
  );
};

export default App;
