import React, { FC, useState, useEffect } from 'react';

import useGetServiceDetail from '@Modules/getServiceDetail/useGetServiceDetail';
import useAuth from '@rof/Hooks/useAuth';

import { O2Modal, O2ModalBody, O2Indent, O2Button } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';

const MigrateModal: FC = () => {
  const { t } = useTranslation();
  const [ isOpen, setIsOpen ] = useState(false);
  const { init, data, isLoading } = useGetServiceDetail();
  const { koId, isLoggedIn } = useAuth();
  const migrationAsked = sessionStorage.getItem('migrationAsked' + koId);

  useEffect(() => {
    if (isLoggedIn) init();
  }, [ isLoggedIn, init ]);

  useEffect(() => {
    if (data && !isLoading && migrationAsked !== 'true' && data?.needsMigration) {
      setIsOpen(true);
      sessionStorage.setItem('migrationAsked' + koId, 'true');
    } 
   
  }, [ data, isLoading, migrationAsked, koId ]);

  return (
    <O2Modal contentLeft closeBtn={false} isOpen={isOpen} style={{width: '400px', lineHeight: '16px'}}>
      <O2ModalBody contentLeft style={{backgroundColor: 'white', borderRadius: '8px'}}>
        <O2Indent>
          <h2 className="o2-typo--heading" style={{fontSize: '21px', fontWeight: 700 }}>{t('tvsc.migration.title')}</h2>
        </O2Indent>
        <O2Indent>
          <p dangerouslySetInnerHTML={{__html: t('tvsc.migration.paragraph1')}}></p> <br />
          <p>{t('tvsc.migration.paragraph2')}</p>
        </O2Indent>
        <O2Indent>
          <p style={{fontSize: '12px'}}>{t('tvsc.migration.warning')} {t('tvsc.migration.warningDescription')}</p>
        </O2Indent>
        <O2Indent style={{ display: 'flex', justifyContent: 'space-between'}}>
          <O2Button
            color={'transparent'}
            fullWidth={true}
            style={{ marginRight: '5px' }}
            onClick={() => {
              window.location.href = 'https://www.o2tv.cz/mig-legacy'; 
            }}
          >
            <span style={{fontWeight: 600}}>
              {t('tvsc.migration.backToLegacy')}
            </span>
          </O2Button>
          <O2Button
            color={'primary'}
            fullWidth={true}
            style={{ marginLeft: '5px' }}
            onClick={() => setIsOpen(false)}
          >
            <span style={{fontWeight: 700}}>
              {t('tvsc.migration.newTV')}
            </span>
          </O2Button>
        </O2Indent>
      </O2ModalBody>
    </O2Modal>
  );
};

export default MigrateModal;
