import React, { useState, useEffect } from 'react';

import PasswordInput from '@Components/blocks/PasswordInput';
import useKeyPress from '@rof/Hooks/useKeyPress';
import { handleEnterSubmit } from '@rof/Utils/index';

import { O2Modal, O2ModalBody, O2ModalFooter, O2ModalFooterButton, O2Indent, O2G, O2GColumn } from 'o2-theme-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IProps, { IType, IValidation } from './types';

const PasswordReenterModal: React.FC<IProps> = ({ title, label, buttonTitle, placeholder, email, isOpen, onConfirm, onClose }: IProps) => {
  const { t } = useTranslation();
  const [ password, setPassword ] = useState('');
  const [ passwordValidation, setPasswordValidation ] = useState<IValidation>();
  const keyPressed = useKeyPress('Enter');
  const { formState, handleSubmit } = useForm();
  
  const onSubmit = () => {
    if (password.length) onConfirm(password);
    else {
      setPasswordValidation({
        text: t('global.error.passwordMandatory'),
        type: 'error' as IType,
      }); 
    };
  };

  useEffect(() => {
    if (passwordValidation !== undefined) setPasswordValidation(undefined); 
    // eslint-disable-next-line
  }, [ password ]);

  useEffect(() => {
    setPassword('');
    // eslint-disable-next-line
  }, [ isOpen ]);

  useEffect(() => {
    if (keyPressed && isOpen) onSubmit();
    // eslint-disable-next-line
  }, [ keyPressed ]);

  // input autofocus feature
  useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('password-autofocus');
      input?.focus();
    });
  }, [ isOpen ]);

  return (
    <O2Modal contentLeft isOpen={isOpen} toggle={onClose}>
      <O2ModalBody>
        <O2Indent>
          <h2 className="o2-typo--heading o2-typo--align-center">{title}</h2>
        </O2Indent>
        <O2Indent>
          <O2G center>
            <O2GColumn xs="1" sm="4-5">
              <O2Indent>
                <div className="o2-control__label">{label}</div>
              </O2Indent>
              <O2Indent top="tight">{email}</O2Indent>
              <O2Indent>
                <PasswordInput
                  id="password-autofocus"
                  placeholder={placeholder}
                  validationText={passwordValidation?.text}
                  validationType={passwordValidation?.type}
                  password={password}
                  onChange={(value: string) => setPassword(value)}
                  onKeyDown={(e: any) => handleEnterSubmit(handleSubmit(onSubmit), e, formState.isSubmitting)}
                />
              </O2Indent>
            </O2GColumn>
          </O2G>
        </O2Indent>
      </O2ModalBody>
      <O2ModalFooter>
        <O2ModalFooterButton
          onClick={handleSubmit(onSubmit)}
          icon="checkmark"
          color="success"
          disabled={formState.isSubmitting}
        >
          {buttonTitle}
        </O2ModalFooterButton>
      </O2ModalFooter>
    </O2Modal>
  );
};

export default PasswordReenterModal;
