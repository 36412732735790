import React from 'react';

import FlashMessage from '@rof/Blocks/FlashMessage';
import useKahlMessages from '@rof/Hooks/useKahlMessages';
import useLocalization from '@rof/Hooks/useLocalization';

import { Helmet } from 'react-helmet';

import IProps from './types';

const RootLayout: React.FC<IProps> = ({ children }: IProps) => {
  const { lang } = useLocalization();
  const kahlMessage = useKahlMessages();

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>O2 TV Samoobsluha</title>
        <meta name="description" content="React boilerplate for O2 frontend projects" />
      </Helmet>
      {children}
      <FlashMessage message={kahlMessage} />
    </>
  );
};

export default RootLayout;
