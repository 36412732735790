export const decorateLinkByLeadId = (link: string): string => { 
  const wcmsLeadId = localStorage.getItem('wcmsLeadId');
  if (wcmsLeadId) {
    const wcmsLeadIdParam = `wcmsLeadId=${wcmsLeadId}`;
    const hasParams = link.includes('?');
    return hasParams ? `${link}&${wcmsLeadIdParam}` : `${link}?${wcmsLeadIdParam}`;
  }

  return link;
};
