import { useEffect } from 'react';

import { COMMON_FAIL, CHECK_PRODUCT_INVALID_PRODUCT_CODE, CHECK_PRODUCT_INVALID_VOUCHER_CODE } from '@Constants/kahl/index';
//import useAnalytics from '@Hooks/useAnalytics';
import { CheckProductResponse } from '@rof/Api/bls-sc/types';
import { CreateOrderContext } from '@rof/Reducers/createOrderContext/types';
import { IState } from '@rof/Reducers/types';
import { checkProduct } from '@rof/Thunks/checkProduct';
import { setKahlMessageId } from '@rof/Thunks/kahl';
import IStore from '@Store/types';

import { useDispatch, useSelector } from 'react-redux';

const useCheckProduct = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error, lastFetched } = useSelector<IStore, IState<CheckProductResponse>>((state) => state.checkProduct);
  const createOrderContext = useSelector<IStore, CreateOrderContext>((state) => state.createOrderContext);
  //const { updateTMPVoucherData } = useAnalytics();

  const init = (force = false, productCode: string, voucherCode?: string) => {
    if ((!isLoading && !data && !error) || force) {
      if (createOrderContext.productType) {
        dispatch(checkProduct({ 
          productCode, 
          voucherCode, 
          productType: createOrderContext.productType, 
          ppvId: createOrderContext.ppvId, 
        }));
      } else console.log('productType is undefined');
    }
  };

  useEffect(() => {
    if (data?.failureReason) {
      switch (data?.failureReason) {
        case 'INVALID_PRODUCT_CODE':
          dispatch(setKahlMessageId(CHECK_PRODUCT_INVALID_PRODUCT_CODE));
          break;
        case 'INVALID_VOUCHER_CODE':
          dispatch(setKahlMessageId(CHECK_PRODUCT_INVALID_VOUCHER_CODE));
          break;
        default:
          dispatch(setKahlMessageId(COMMON_FAIL));
          break;
      }
    }
    /*if (data?.serviceDiscount) {
      updateTMPVoucherData({discount: data?.serviceDiscount});
    }*/
    // eslint-disable-next-line
  }, [ data ]);
  
  useEffect(() => {
    if (error) {
      dispatch(setKahlMessageId(COMMON_FAIL));
    }
    // eslint-disable-next-line
  }, [ error ]);
  
  return {
    init,
    data,
    isLoading,
    error,
    lastFetched,
  };
};

export default useCheckProduct;
