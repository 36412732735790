import ActionSetUserInfo from '@rof/Actions/userInfo';

import { IUserInfoStore } from './types';

const init: IUserInfoStore = {
  email: null,
  firstName: null,
  internal: false,
  koId: null,
  lastName: null,
  logged: false,
  msisdn: null,
  roles: [],
  subscriberType: null,
  username: null,
  isRequested: false,
  deviceId: null,
  userDisplayName: null,
};

const userInfoReducer = (state: any = init, action: ActionSetUserInfo) => {
  switch (action.type) {
    case '[AUTH] SET_USER_INFO':
      return {
        ...state,
        ...action.data,
        isRequested: true,
      };
    default: return state;
  }
};

export default userInfoReducer;
