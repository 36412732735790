import { sendPost } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { ValidateVoucherResponse, ValidateVoucherRequest } from '../types';

export const postValidateVoucher = (
  params: ValidateVoucherRequest
): AxiosPromise<ValidateVoucherResponse> => {
  return sendPost<ValidateVoucherResponse>(
    '/bsl-sc-api/public/v1/subscription/validateVoucher',
    params,
  );
};
