import { useState } from 'react';

import useDateTime from '@rof/Hooks/useDateTime';

const useDatePicker = (maxDiff?: number, maxFrom?: number) => {
  const { differenceInMonths, parseToISOString, addMonths, subMonths, isBefore, nextDay, beforeDay } = useDateTime();
  const [ maxDifference ] = useState<number>(maxDiff || Number.POSITIVE_INFINITY);
  const [ maxFromBackMonths ] = useState<number>(maxFrom || Number.POSITIVE_INFINITY);
  const [ dateFrom, setDateFrom ] = useState<string>();
  const [ dateTo, setDateTo ] = useState<string>();

  const setDate = (value: string, key: string, dateFormat: string) => {
    switch (key) {
      case 'dateFrom':
        const ISODataFrom = parseToISOString(value, dateFormat);

        if (dateTo && ISODataFrom) {
          if (isBefore(new Date(dateTo), new Date(ISODataFrom))) {
            setDateTo(nextDay(ISODataFrom));
          } else if (differenceInMonths(dateTo, ISODataFrom) > maxDifference) {
            const movedDate = addMonths(new Date(ISODataFrom), maxDifference);
            setDateTo(movedDate.toISOString());
          }
        }

        if (ISODataFrom && differenceInMonths(new Date().toISOString(), ISODataFrom) > maxFromBackMonths) {
          const movedDate = subMonths(new Date(), maxFromBackMonths);
          setDateFrom(movedDate.toISOString());
        } else setDateFrom(ISODataFrom);

        break;
        
      case 'dateTo':
        const ISODateTo = parseToISOString(value, dateFormat);

        if (dateFrom && ISODateTo) {
          if (isBefore(new Date(ISODateTo), new Date(dateFrom))) {
            setDateFrom(beforeDay(ISODateTo));
          } else if (differenceInMonths(ISODateTo, dateFrom) > maxDifference) {
            const movedDate = subMonths(new Date(ISODateTo), maxDifference);
            setDateFrom(movedDate.toISOString());
          } 
        }

        setDateTo(ISODateTo);
        break;

      default:
        console.log(`Unexpected key: ${key}`);
        break;
    }
  };

  return {
    dateFrom,
    dateTo,
    setDate,
  };
};

export default useDatePicker;
