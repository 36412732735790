import React from 'react';

import { handleEnterSubmit } from '@rof/Utils/index';

import { O2Section, O2Indent, O2TextField, O2Button} from 'o2-theme-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IProps from './types';

const InvalidVoucher: React.FC<IProps> = ({ validationReason, validationDetail, submitVoucher, setVoucherCode}: IProps) => {
  const { t } = useTranslation();
  const { formState, handleSubmit } = useForm();

  return (
    <>
      <O2Section>
        <O2Indent>{t('tvsc.applyVoucher.description')}</O2Indent>
        <O2Indent>
          <O2TextField 
            validationType={validationReason}
            validationText={validationDetail} 
            controlSize="medium"
            label={t('tvsc.applyVoucher.voucherInputLabel')}
            onChange={setVoucherCode}
            onKeyDown={(e: any) => handleEnterSubmit(handleSubmit(submitVoucher), e, formState.isSubmitting)}
          />
        </O2Indent>
      </O2Section>
      <O2Section>
        <O2Button color="primary" onClick={handleSubmit(submitVoucher)} disabled={formState.isSubmitting}>
          {t('tvsc.applyVoucher.submit')}
        </O2Button>
      </O2Section>
    </>
  );
};

export default InvalidVoucher;
