import { sendGet, getServicesPath } from '@rof/Utils/requests';
import { getState } from '@Store/index';

import { AxiosPromise } from 'axios';

import { BalanceHistoryResponse, BalanceHistoryRequest } from './types';

export const getBalanceHistory = (
  params: BalanceHistoryRequest
): AxiosPromise<BalanceHistoryResponse> => {

  if (!params.eventTimeFrom) {
    const dnes = new Date();

    params.eventTimeFrom = new Date(dnes.getTime() - (396 * 24 * 60 * 60 * 1000)).toISOString();
  }

  if (!params.eventTimeTo) {
    const dnes = new Date();

    params.eventTimeTo = new Date(dnes.getTime()).toISOString();
  }

  const ENDPOINTS = getState().codeList?.['TVSC_ENDPOINTS'];
  return sendGet<BalanceHistoryResponse>(
    getServicesPath(`${ENDPOINTS && ENDPOINTS['bff.endpoint.base']}/balance-history`),
    params
  );
};
