import { sendPut } from '@rof/Utils/requests';

import { AxiosPromise } from 'axios';

import { ValidateCardResponse } from '../types';

export const putValidateCard = (): AxiosPromise<ValidateCardResponse> => {
  return sendPut<ValidateCardResponse>(
    '/bsl-payment-api/public/v1/orders/validateCard'
  );
};
