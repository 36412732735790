import { GetOrderDetailResponse } from "@rof/Api/bls-payment/types";
import { CheckProductResponse } from "@rof/Api/bls-sc/types";

//IAA = Adobe Analytics
export type IAAUser = {
  userName: string;
  msisdn: string;
  //customerRefNo: string;  DD: MojeO2 cuRefNo zákazníka (pokud je k dispozici) = není k dispozici
}

export type IAAOrder = {
  wcmsLeadId: string;
  tariffCode: string;
  tariff: string;
  price: number;
  step: IAAOrderStep;
  paymentMethod: 'CARD';
  recurringPayment: boolean;
  vouchers: IAAVoucher[];
}

export enum IAAOrderStep {
  accountCreation = 'Vytvoření účtu',
  payment = 'Platba',
}

export type IAAVoucher = {
  voucherCode: string;
  displayName: string;
  discount: number;
  discountType: IAAVoucherDiscountType;
}

export enum IAAVoucherDiscountType {
  absolute = 'absolutní',
  relative = 'relativní',
}

export type IAABaseData = {
  pageId: string;
  pageTitle: string;
  language: IAABaseDataLanguage;
  httpStatusCode?: number;
  appName: string;
  appVersion: string;
  themeVersion: string;
  user?: IAAUser;
  checkProduct?: CheckProductResponse;
  orderDetail?: GetOrderDetailResponse;
  wcmsLeadId: string;
}

export enum IAABaseDataLanguage {
  cs = 'cs',
  en = 'en',
}

//IAAE = Adobe Analytics Event
export type IAAELogin = {
  user: IAAUser;
}

export type IAAEPopupMessage = {
  messageType: IAAEPopupMessageType;
  messageId: string;
  messageDescription: string;
}

export enum IAAEPopupMessageType {
  success = 'success',
  error = 'error',
}

export type IAAEPaymentResult = {
  resultType: IAAEPaymentResultType;
  message: string;
  totalAmount: number;
  transactionId: string;
}

export enum IAAEPaymentResultType {
  success = 'success',
  processing = 'processing',
  failed = 'failed',
}

export type IAAEFormSubmit = {
  formId: string;
}

export type IAAEModalOpen = {
  modalId: string;
  modalHeading: string;
}
