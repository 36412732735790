/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect } from 'react';

import useAuth from '@rof/Hooks/useAuth';
import useLocalization from '@rof/Hooks/useLocalization';
import IStore from '@Store/types';

import { shallowEqual, useSelector } from 'react-redux';

import { version, themeCSSVersion } from '../../../package.json';
import { IAABaseData, IAAEFormSubmit, IAAELogin, IAAEModalOpen, IAAEPaymentResult, IAAEPopupMessage, IAAUser } from './types';
import { GetOrderDetailResponse } from '@rof/Api/bls-payment/types';
import { CheckProductResponse } from '@rof/Api/bls-sc/types';

const useAnalytics = () => {
  const { username, msisdn, isLoggedIn } = useAuth();
  const { lang } = useLocalization();
  /*const [ voucherDataTMP, setVoucherDataTMP ] = useState<Partial<IAAVoucher>>({
    discountType: IAAVoucherDiscountType.absolute,
  });*/
  const [ isOrderDetailAvailable, orderDetail ] = useSelector<IStore, [boolean, GetOrderDetailResponse | undefined]>(
    (state) => [
      Boolean(state.orderDetail.data),
      state.orderDetail.data,
    ],
    shallowEqual
  );

  const [ isCheckProductAvailable, checkProduct ] = useSelector<IStore, [boolean, CheckProductResponse | undefined]>(
    (state) => [
      Boolean(state.checkProduct.data),
      state.checkProduct.data,
    ],
    shallowEqual
  );

  useEffect(() => {
    if (isOrderDetailAvailable && orderDetail && (window as any).adobeDataLayer[0]?.orderDetail === undefined) {
      if ((window as any).adobeDataLayer[(window as any).adobeDataLayer.length - 1]?.orderDetail === undefined) {
        updateBaseData({
          ...(window as any).adobeDataLayer[0],
          orderDetail,
        });
      }
    }
  }, [ isOrderDetailAvailable, orderDetail ])

  useEffect(() => {
    if (isCheckProductAvailable && checkProduct && (window as any).adobeDataLayer[0]?.checkProduct === undefined) {
      if ((window as any).adobeDataLayer[(window as any).adobeDataLayer.length - 1]?.checkProduct === undefined) {
        updateBaseData({
          ...(window as any).adobeDataLayer[0],
          checkProduct,
        });
      }
      
    }
  }, [ isCheckProductAvailable, checkProduct ])

  /*useEffect(() => {
    if (
      (window as any).adobeDataLayer[0]?.order?.vouchers.length === 0
      && voucherDataTMP.discount !== undefined
      && voucherDataTMP.discountType !== undefined
      && voucherDataTMP.voucherCode !== undefined
      && voucherDataTMP.displayName !== undefined
    ) AAUVoucherData(voucherDataTMP as IAAVoucher);
    // eslint-disable-next-line
  }, [ voucherDataTMP ]);*/

  //const updateTMPVoucherData = (voucherData: Partial<IAAVoucher>) => setVoucherDataTMP({ ...voucherDataTMP, ...voucherData });

  const pushEvent = (
    eventName: string,
    eventData: Partial<IAABaseData> | IAAELogin | IAAEPopupMessage | IAAEPaymentResult | IAAEFormSubmit | IAAEModalOpen | undefined = undefined
  ) => {
    (window as any).adobeDataLayer.push({
      event: eventName,
      eventData,
    });

    if (localStorage.getItem('testing') === 'true') console.log((window as any).adobeDataLayer);
  };

  const updateBaseData = (
    baseData: Partial<IAABaseData>
  ) => {
    (window as any).adobeDataLayer.push({
      ...baseData
    });
  };

  const getUserInfo = (): IAAUser => ({
    userName: username ?? '',
    msisdn: msisdn ?? '',
  });

  const getPageInfo = (pageId: string, pageTitle: string): IAABaseData => ({
    pageId,
    pageTitle,
    language: lang,
    appName: 'xTV Prepaid Selfcare',
    appVersion: version,
    themeVersion: themeCSSVersion,
    user: isLoggedIn ? getUserInfo() : undefined,
    checkProduct: isCheckProductAvailable ? checkProduct : undefined,
    orderDetail: isOrderDetailAvailable ? orderDetail : undefined,
    wcmsLeadId: localStorage.getItem('wcmsLeadId') !== null ? localStorage.getItem('wcmsLeadId') as string : '',
  });

  const initAnalyticsBaseData = (pageId: string, pageTitle: string) => (window as any).adobeDataLayer[0] = getPageInfo(pageId, pageTitle);

  useEffect(() => {
    localStorage.setItem('analyticsInitialized', 'false');
  }, []);

  const AAEPageLoaded = (eventData: Partial<IAABaseData> & { pageId: string; pageTitle: string }) => {
    if (localStorage.getItem('analyticsInitialized') === 'true') {
      pushEvent('pageLoaded', eventData ? eventData : undefined);
    } else {
      initAnalyticsBaseData(eventData.pageId, eventData.pageTitle);
      localStorage.setItem('analyticsInitialized', 'true');
      pushEvent('pageLoaded', eventData ? eventData : undefined);
    }
  };

 /* const AAUVoucherData = (voucher: IAAVoucher) => updateBaseData({
    ...(window as any).adobeDataLayer[0],
    order: {
      ...(window as any).adobeDataLayer[0].order,
      vouchers: [ voucher ],
    },
  });*/
  const AAELogin = (eventData: IAAELogin) => pushEvent('login', eventData);

  const AAELogout = () => pushEvent('logout');

  const AAEPopupMessage = (eventData: IAAEPopupMessage) => pushEvent('popupMessage', eventData);

  const AAEPaymentResult = (eventData: IAAEPaymentResult) => pushEvent('paymentResult', eventData);

  const AAEFormSubmit = (eventData: IAAEFormSubmit) => pushEvent('formSubmit', eventData);

  const AAEBillingSearchSubmit = () => pushEvent('billingSearchSubmit');

  const AAEModalOpen = (eventData: IAAEModalOpen) => pushEvent('modalOpen', eventData);

  return {
    //updateTMPVoucherData,
    AAEPageLoaded,
    AAELogin,
    AAELogout,
    AAEPopupMessage,
    AAEPaymentResult,
    AAEFormSubmit,
    AAEBillingSearchSubmit,
    AAEModalOpen,
  };
};

export default useAnalytics;
