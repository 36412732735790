import { IAABaseDataLanguage } from '@Hooks/types';
import { getLocale, setLocale } from '@rof/Api/locale';
import { DEFAULT_LANGUAGE } from '@rof/Constants/index';
import Languages from '@rof/Types/languages';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const useLocalization = () => {
  const { i18n } = useTranslation();

  const setLang = (language: Languages, afterAction?: any) => {
    setLocale(language).then(() => {
      if (afterAction) {
        afterAction();
      }
    });
    i18next.changeLanguage(language);
  };

  const initLocale = () => {
    getLocale().then(({ data }) => {
      i18next.changeLanguage(data);
    }).catch(() => setLocale(DEFAULT_LANGUAGE));
  };

  return {
    lang: i18n.language as IAABaseDataLanguage,
    setLang,
    initLocale,
  };
};

export default useLocalization;
