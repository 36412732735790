import React, { useState, useEffect } from 'react';

import useDateTime from '@rof/Hooks/useDateTime';

import { useTranslation } from 'react-i18next';

import CreditCardMain, { CreditCardWrapper, CreditCardContent, CreditCardLogo, CreditCardText, CardName } from './styles';
import IProps from './types';

const CreditCard: React.FC<IProps> = ({ type, number, valid}: IProps) => {
  const { t } = useTranslation();
  const [ logoSrc, setLogoSrc ] = useState();
  const { format } = useDateTime();

  useEffect(() => {
    let src;

    try {
      src = require(`./logos/${type.toLocaleLowerCase()}.svg`);
      setLogoSrc(src);
    } catch (error) { 
      setLogoSrc(undefined); 
    }
  }, [ type ]);

  return (
    <CreditCardMain>
      <CreditCardWrapper>
        <CreditCardContent>
          <CreditCardLogo>
            {logoSrc ? <img src={logoSrc} alt={type} /> : <CardName>{type}</CardName>}
          </CreditCardLogo>
          <CreditCardText>
            <div className="o2-typo--heading-h2">{number}</div>
            <div className="o2-typo--weight-minor">{t('tvsc.card.validUntil')}</div>
            <div>{format(new Date(valid), 'MM/yy')}</div>
          </CreditCardText>
        </CreditCardContent>
      </CreditCardWrapper>
    </CreditCardMain>
  );
};

export default CreditCard;
