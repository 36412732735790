import React from 'react';

import { O2Section, O2Indent, O2Checkbox, O2InfoMessage } from 'o2-theme-react';
import { useTranslation } from 'react-i18next';

import IProps from './types';

const WithoutRecurrence: React.FC<IProps> = ({ isAgreeGoPay, onAgreeGoPay }: IProps) => {
  const { t } = useTranslation();

  return (
    <O2Section>
      <O2Indent>
        <O2InfoMessage color="neutral">
          <O2Indent className="o2-typo--weight-important">{t('tvsc.paymentCardChange.descriptionOne')}</O2Indent>
          <O2Indent>{t('tvsc.paymentCardChange.descriptionTwo')}</O2Indent>
        </O2InfoMessage>
      </O2Indent>
      <O2Indent>
        <O2Checkbox
          label={t('tvsc.paymentCardChange.agreeGoPay')}
          onChange={onAgreeGoPay}
          checked={isAgreeGoPay}
        />
      </O2Indent>
    </O2Section>
  );
};

export default WithoutRecurrence;
