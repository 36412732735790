import React, { useEffect } from 'react';

import TariffBox from '@Components/blocks/TariffBox';
import useAnalytics from '@Hooks/useAnalytics';
import useCodelists from '@rof/Hooks/useCodeLists';
import useDateTime from '@rof/Hooks/useDateTime';
import useMoney from '@rof/Hooks/useMoney';

import { O2Layout, O2LayoutColumn, O2Section, O2Pane, O2Indent, O2G, O2GColumn, O2Button } from 'o2-theme-react';
import { EqualHeight } from 'react-equal-height';
import { useTranslation } from 'react-i18next';

import useChangeServiceData from './module/useChangeServiceData';
import PinConfirmModal from './PinConfirmModal';
import { IProps } from './types';

const ChangeService: React.FC<IProps> = ({ match }: IProps) => {
  const { t } = useTranslation();
  const { id, voucherCode } = match.params;
  const { dateFormat } = useDateTime();
  const { moneyFormat } = useMoney();
  const { getItems } = useCodelists();
  const [ prepaidO2TVTariffs ] = getItems('redirect.o2.prepaidO2TVTariffs');
  const { 
    data,
    isLoading,
    submitChangeService,
    isPinConfirmModalOpen,
    setIsPinConfirmModalOpen,
  } = useChangeServiceData(id, voucherCode);
  const { AAEPageLoaded, AAEModalOpen } = useAnalytics();

  useEffect(() => {
    AAEPageLoaded({ pageId: '2.1.5', pageTitle: t('tvsc.changeService.title') });
    // eslint-disable-next-line
  }, []);

  const paidDay = (date: string) => {
    if (date) return `${t('tvsc.serviceDetail.paidUntil')} ${dateFormat(date)}`;
    return t('tvsc.changeService.infoUnknown');
  };

  const priceFormat = (price?: number, period?: string, suffix?: string) => {
    return price
      ? period
        ? t('tvsc.changeService.newServicePrice', {
          price: moneyFormat(price, undefined, undefined, suffix),
          period: period,
        })
        : moneyFormat(price, undefined, undefined, suffix)
      : t('tvsc.changeService.infoUnknown');
  };

  return (
    <>
      <O2Layout>
        <O2LayoutColumn func="main" pos="center">
          <O2Section>
            <O2Indent>
              <h1 className="o2-typo--heading">{t('tvsc.changeService.title')}</h1>
            </O2Indent>
            <O2Indent>
              {t('tvsc.changeService.description')}
            </O2Indent>
            <O2Indent>
              <O2Pane color="white">
                <O2G indent="narrow" separateRows>
                  <EqualHeight>
                    <O2GColumn xs="1" md="1-2">
                      <TariffBox
                        type="old"
                        loading={isLoading.getServiceDetailIsLoading}
                        tariffName={data.getServiceDetailData?.serviceName ?? ''}
                        description={data.getServiceDetailData?.serviceDescription?.split(',') ?? []}
                        paidDay={
                          data.getServiceDetailData?.recurrence ? 
                            paidDay(dateFormat(data.getServiceDetailData?.nextPaymentDate ?? '')) :
                            paidDay(dateFormat(data.getServiceDetailData?.paidValidToDate ?? ''))
                        }
                      />
                    </O2GColumn>
                    <O2GColumn xs="1" md="1-2">
                      <TariffBox
                        type="new"
                        loading={isLoading.checkProductIsLoading}
                        tariffName={data.checkProductData?.serviceName ?? ''}
                        description={data.checkProductData?.serviceDescription?.split(',') ?? []}
                        paidDay={priceFormat(data.checkProductData?.servicePrice, data.checkProductData?.servicePeriod, 'Kč')}
                      />
                    </O2GColumn>
                  </EqualHeight>
                </O2G>
              </O2Pane>
            </O2Indent>
          </O2Section>
          <O2Section>
            <O2G separateRows align="middle">
              <O2GColumn xs="1" md="7-24">
                <O2Button onClick={() => window.location.href = prepaidO2TVTariffs} icon="chevron-left" color="transparent">
                  {t('tvsc.changeService.backToServiceSelection')}
                </O2Button>
              </O2GColumn>
              <O2GColumn xs="1" md="17-24">
                <div className="o2-btn-group o2-typo--align-right-md">
                  <O2Button color="primary" onClick={() => {
                    setIsPinConfirmModalOpen(true); AAEModalOpen({
                      modalId: '2.1.5_008',
                      modalHeading: t('tvsc.changeService.modal.title'),
                    }); 
                  }}>
                    {t('tvsc.changeService.changeSubmit')}
                  </O2Button>
                </div>
              </O2GColumn>
            </O2G>
          </O2Section>
        </O2LayoutColumn>
      </O2Layout>
      <PinConfirmModal
        isOpen={isPinConfirmModalOpen}
        onClose={() => setIsPinConfirmModalOpen(false)}
        onConfirm={submitChangeService}
      />
    </>
  );
};

export default ChangeService;
