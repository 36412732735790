/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import useAuth from '@rof/Hooks/useAuth';
import useCodeLists from '@rof/Hooks/useCodeLists';
import useLocalization from '@rof/Hooks/useLocalization';

// UNCOMMENT for control orderStatus in orderDetail during AppInit, now is controlled orderStatus only on Payment page
// import usePaymentContext from './usePaymentContext';
import { storeLeadId } from '@Utils/storeLeadId';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import useTvscAuth from './useTvscAuth';

const useAppInit = () => {
  const [ isReadyToRender, setIsReadyToRender ] = useState<boolean>(false);
  const { initUserData, isRequested, isLoggedIn } = useAuth();
  const { initLocale } = useLocalization();
  const { initCodelists, areCodelistsFetched } = useCodeLists();
  const { checkTvscAuthorization, isUserAuthenticated } = useTvscAuth();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  storeLeadId(queryParams);
  // const { checkOrderStatusRedirects } = usePaymentContext();

  //GET CODELISTS
  useEffect(() => {
    initCodelists();
  }, []);

  //GET INFO ABOUT USER
  useEffect(() => {
    if (areCodelistsFetched) {
      initLocale();
      initUserData();
    }
  }, [ areCodelistsFetched ]);

  //RESOLVE IF IS USER CAPABLE OF USING THIS APP
  useEffect(() => {
    if (isRequested) {
      checkTvscAuthorization();
    }
  }, [ isRequested, isLoggedIn ]);

  // useEffect(() => {
  //   checkOrderStatusRedirects();
  // }, [ isUserAuthenticated ]);

  useEffect(() => {
    if (isUserAuthenticated || window.location.href.includes('/login')) {
      setIsReadyToRender(true);
    }
  }, [ isUserAuthenticated ]);

  return {
    isReadyToRender,
  };
};

export default useAppInit;
