
// @reducers
import checkProduct from '@rof/Reducers/checkProduct';
import codeList from '@rof/Reducers/code-list';
import createOrderContext from '@rof/Reducers/createOrderContext';
import getBalanceHistory from '@rof/Reducers/getBalanceHistory';
import getCardDetail from '@rof/Reducers/getCardDetail';
import getFinancialDocumentDetail from '@rof/Reducers/getFinancialDocumentDetail';
import getFinancialDocuments from '@rof/Reducers/getFinancialDocuments';
import getServiceDetail from '@rof/Reducers/getServiceDetail';
import serviceList from '@rof/Reducers/getUserServices';
import kahl from '@rof/Reducers/kahl';
import orderDetail from '@rof/Reducers/orderDetail';
import userInfo from '@rof/Reducers/user-info';
import validateVoucher from '@rof/Reducers/validateVoucher';

import { combineReducers } from 'redux';

import IStore from './types';

const root = combineReducers<IStore>({
  userInfo,
  codeList,
  kahl,
  getBalanceHistory,
  getFinancialDocumentDetail,
  getFinancialDocuments,
  getServiceDetail,
  checkProduct,
  validateVoucher,
  getCardDetail,
  orderDetail,
  serviceList,
  createOrderContext,
});

export default root;
